import React, { useState, useEffect, useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from '@mui/material'
import { RppsAutocompleteByNameInput } from '@components/inputs/RppsAutocompleteByNameInput'
import './MedicalOrderForm.css'

import { findPatients } from '../../state/thunks/patientThunk'
import { debounce } from 'lodash'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import {
  CreateMedicalOrderDto,
  FileTypeEnum,
  Member,
  RppsDto,
  SexEnum,
  Site,
} from '../../services/api'
import { getExamsBySiteId } from '../../state/thunks/examThunk'
import {
  createStudy,
  updateStudy,
  uploadFile,
} from '../../state/thunks/studyThunk'
import { CreateMedicalOrderSchema } from '../../utils/schemas'
import { yupResolver } from '@hookform/resolvers/yup'
import { setIsCreateReportDialogOpen } from '../../state/reducers/studyReducer'
import { enqueueSnackbar } from '../../state/reducers/alertReducer'
import { setPatients } from '@state/reducers/patientReducer'
import moment from 'moment'

interface IFormInput {
  examDate: Date
  examTime: string
  accessionNumber: string
  requestingDoctor: RppsDto
  patientId?: number
  patientPID: string
  patientFirstName: string
  patientLastName: string
  patientBirthdate: Date
  patientSSN: string
  weight: number
  height: number
  examId: number
  sex: boolean
  siteId: number
  indication?: string
  patientCannotAnswerQuestions?: boolean
  cannotAnswerQuestionsReason?: string
}

const MedicalOrderForm: React.FC = () => {
  const editingMedicalOrder = useAppSelector((state) => state.study.selected)

  const [isLoading, setIsLoading] = useState(false)
  const [selectedFileType, setSelectedFileType] = useState<string>('')
  const [isPatientExists, setIsPatientExists] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [isSearchPatientOpen, setIsSearchPatientOpen] = useState(false)
  const [documents, setDocuments] = useState<
    { file: File; type: FileTypeEnum }[]
  >([])

  const [isFetching, setIsFetching] = useState(false)
  const patients = useAppSelector((state) => state.patients.patients)
  const member = useAppSelector((state) => state.auth.user) as any as Member
  const exams = useAppSelector((state) => state.exam.exams)
  const user = useAppSelector(({ auth }) => auth.user)

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(CreateMedicalOrderSchema),
    defaultValues: editingMedicalOrder
      ? {
        examDate: moment(editingMedicalOrder.plannedDate).format(
          'YYYY-MM-DD',
        ) as any,
        examTime: moment(editingMedicalOrder.plannedDate).format('HH:mm'),
        accessionNumber: editingMedicalOrder?.accessionNumber,
        requestingDoctor: {
          firstName: editingMedicalOrder?.attendingDoctor?.firstName,
          lastName: editingMedicalOrder?.attendingDoctor?.lastName,
          rppsNumber: editingMedicalOrder.attendingDoctor?.rppsNumber,
        },
        patientId: editingMedicalOrder?.patientId,
        patientPID: editingMedicalOrder.patient.external_id.length
          ? editingMedicalOrder.patient.external_id[0].value
          : '',
        patientFirstName: editingMedicalOrder.patient.firstName,
        patientLastName: editingMedicalOrder.patient.lastName,
        patientBirthdate: moment(
          editingMedicalOrder.patient.birthDate,
        ).format('YYYY-MM-DD') as any,
        patientSSN: editingMedicalOrder.patient.ssn,
        weight: editingMedicalOrder.patient.weight,
        height: editingMedicalOrder.patient.height,
        examId: editingMedicalOrder.examId,
        sex: editingMedicalOrder.patient.sex === SexEnum.M ? true : false,
        siteId: editingMedicalOrder.siteId,
        indication: editingMedicalOrder.indication,
        patientCannotAnswerQuestions:
            editingMedicalOrder.patientCannotAnswerQuestions || false,
      }
      : {},
  })

  const selectedPatient = watch('patientId')

  const dispatch = useAppDispatch()

  const onSubmit = async (data: IFormInput) => {
    const dto: CreateMedicalOrderDto = {
      examId: data.examId,
      accessionNumber: data.accessionNumber,
      date: moment(data.examDate) as any,
      time: data.examTime,
      doctorRpps: data.requestingDoctor.rppsNumber,
      patientId: data.patientId,
      patient: {
        firstName: data.patientFirstName,
        lastName: data.patientLastName,
        birthDate: data.patientBirthdate,
        ssn: data.patientSSN,
        pId: data.patientPID,
        weight: data.weight,
        height: data.height,
        sex: data.sex ? SexEnum.M : SexEnum.F,
      },
      indication: data.indication,
      siteId: data.siteId,
      patientCannotAnswerQuestions: data.patientCannotAnswerQuestions,
      cannotAnswerQuestionsReason: data.cannotAnswerQuestionsReason,
    }
    setIsLoading(true)
    if (editingMedicalOrder) {
      dispatch(
        updateStudy({
          id: editingMedicalOrder.id,
          dto,
        }),
      ).then(() => {
        setIsLoading(false)
        dispatch(setIsCreateReportDialogOpen(false))
        dispatch(
          enqueueSnackbar({
            message: 'Examen modifié avec succès',
            type: 'success',
          }),
        )
      })
    } else {
      dispatch(createStudy(dto))
        .unwrap()
        .then(async (res) => {
          setIsLoading(false)
          for (const d of documents) {
            console.log(d.file)
            await dispatch(
              uploadFile({ id: res.id, files: d.file, type: d.type }),
            ).unwrap()
          }

          dispatch(setIsCreateReportDialogOpen(false))
          dispatch(
            enqueueSnackbar({
              message: 'Examen créé avec succès',
              type: 'success',
            }),
          )
        })
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files
    if (fileList && fileList.length > 0) {
      setDocuments([
        ...documents,
        { file: fileList[0], type: FileTypeEnum.PRESCRIPTION },
      ])
    }
  }

  const cancelUpload = () => {
    setOpenDialog(false)
    setSelectedFileType('')
  }

  const currentPatient = useMemo(
    () => patients.find((p) => p.id === selectedPatient),
    [patients],
  )

  const fetchPatients = debounce((value: string) => {
    dispatch(findPatients(value))
      .unwrap()
      .then(() => {
        setIsFetching(false)
      })
  }, 400)

  const onSearchPatient = (value: string) => {
    setIsFetching(true)
    fetchPatients(value)
  }

  const siteId = watch('siteId')

  useEffect(() => {
    if (!siteId) {
      return
    }
    dispatch(getExamsBySiteId([siteId]))
  }, [siteId])

  const patientId = watch('patientId')
  const sex = watch('sex')

  useEffect(() => {
    if (patientId) {
      setIsPatientExists(true)

      const patient = patients.find((p) => p.id === patientId)
      if (!patient) {
        return
      }

      if (patient.external_id?.length) {
        setValue('patientPID', patient.external_id[0].value)
      }
      setValue('patientFirstName', patient.firstName)
      setValue('patientLastName', patient.lastName)
      setValue('patientBirthdate', patient.birthDate)
      setValue('patientSSN', patient.ssn)
      setValue('weight', patient.weight)
      setValue('height', patient.height)
      setValue('sex', patient.sex === SexEnum.M ? true : false)
    } else {
      setValue('patientPID', '')
      setValue('patientFirstName', '')
      setValue('patientLastName', '')
      setValue('patientBirthdate', new Date())
      setValue('patientSSN', '')
      setValue('weight', 0)
      setValue('height', 0)
    }
  }, [patientId])

  useEffect(() => {
    if (!isPatientExists && patientId) {
      setValue('patientId', undefined)
    }
  }, [isPatientExists, patientId])

  useEffect(() => {
    if (!editingMedicalOrder) {
      return
    }

    dispatch(
      setPatients([
        {
          ...editingMedicalOrder.patient,
        },
      ]),
    )

    setIsPatientExists(true)
    setValue('patientId', editingMedicalOrder?.patientId)
  }, [editingMedicalOrder])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        spacing={1}
        sx={{
          padding: '2rem',
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <h2>Register new exam</h2>
        </Grid>
        <Grid item xs={12}>
          <h3>Rendez-vous</h3>
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="examDate"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Exam Date"
                type="date"
                error={!!errors.examDate}
                helperText={errors.examDate?.message}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="examTime"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Exam Time"
                type="time"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors.examTime}
                helperText={errors.examTime?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="accessionNumber"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Accession Number"
                fullWidth
                error={!!errors.accessionNumber}
                helperText={errors.accessionNumber?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <h3>Docteur</h3>
        </Grid>
        <Grid item xs={12} md={6}>
          <RppsAutocompleteByNameInput
            control={control}
            errors={errors}
            name="requestingDoctor"
            setValue={setValue}
            defaultValue={
              editingMedicalOrder?.attendingDoctor
                ? {
                  firstName: editingMedicalOrder?.attendingDoctor?.firstName,
                  lastName: editingMedicalOrder?.attendingDoctor?.lastName,
                  rppsNumber: editingMedicalOrder.attendingDoctor?.rppsNumber,
                }
                : undefined
            }
          />
          <FormHelperText error={!!errors.requestingDoctor}>
            {(errors.requestingDoctor as any)?.message}
          </FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <h3>Site</h3>
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="siteId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                onChange={(e) => {
                  field.onChange(e)
                }}
                style={{ minWidth: '200px' }}
              >
                <MenuItem value="">Tous</MenuItem>
                {(user as any & { sites: Site[] })?.sites?.map((site: Site) => (
                  <MenuItem key={site.id} value={site.id}>
                    {site.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <h3>Patient</h3>
        </Grid>
        <Grid item xs={6}>
          {isPatientExists && (
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Autocomplete
                  sx={{
                    width: '300px',
                  }}
                  open={isSearchPatientOpen}
                  componentsProps={{
                    popper: {
                      style: {
                        width: 'fit-content',
                      },
                    },
                  }}
                  onOpen={() => {
                    setIsSearchPatientOpen(true)
                  }}
                  onClose={() => {
                    setIsSearchPatientOpen(false)
                  }}
                  isOptionEqualToValue={(option, value) => {
                    if (!value) {
                      return false
                    }
                    return option.id === value.id
                  }}
                  getOptionLabel={(option) =>
                    option.firstName + ' ' + option.lastName
                  }
                  options={patients}
                  onChange={(_, value) =>
                    setValue('patientId', value?.id || -1)
                  }
                  loading={isFetching}
                  value={currentPatient}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(ev) => {
                        onSearchPatient(ev.target.value)
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isFetching ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <FormHelperText error={!!errors.patientId}>
                  {errors.patientId?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Checkbox
                onChange={(_, checked) => setIsPatientExists(checked)}
                checked={isPatientExists}
              />
              Déjà enregistré?
            </div>
          </FormControl>
        </Grid>

        {(!isPatientExists || selectedPatient) && (
          <>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Sexe</FormLabel>
                <Controller
                  name="sex"
                  control={control}
                  defaultValue={true}
                  render={({ field }) => {
                    return (
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        {...field}
                        value={sex}
                      >
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Femme"
                        />
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Homme"
                        />
                      </RadioGroup>
                    )
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="patientPID"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="Patient PID" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="patientFirstName"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="First Name" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="patientLastName"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="Last Name" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="patientBirthdate"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Birthdate"
                    type="date"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="patientSSN"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Social Security Number"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="weight"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Weight (kg)"
                    fullWidth
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={!!errors.weight}
                    helperText={errors.weight?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="height"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Height (cm)"
                    type="number"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.height}
                    helperText={errors.height?.message}
                  />
                )}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <div>
            <Controller
              name="patientCannotAnswerQuestions"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} />}
                  label="Le patient ne peut pas répondre aux questions?"
                />
              )}
            />
          </div>
          {watch('patientCannotAnswerQuestions') && (
            <div>
              <Controller
                name="cannotAnswerQuestionsReason"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Raison"
                    fullWidth
                    multiline
                    rows={4}
                  />
                )}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          <h3>Examen</h3>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={exams}
              value={editingMedicalOrder?.exam}
              onChange={(_, value) => setValue('examId', value?.id || -1)}
              renderInput={(params) => <TextField {...params} label="Exam" />}
            />
            <FormHelperText error={!!errors.examId}>
              {errors.examId?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" component="label" className="button-main">
            Ajouter un document
            <input type="file" hidden onChange={handleFileChange} />
          </Button>

          {documents.map((d, i) => {
            return (
              <div
                key={i}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 10,
                }}
              >
                <span style={{ width: '200px', overflowX: 'hidden' }}>
                  {d.file.name}
                </span>

                <div
                  style={{
                    flex: 1,
                  }}
                >
                  <Select
                    style={{
                      width: '200px',
                      marginRight: 14,
                    }}
                    value={d.type}
                    onChange={(e) => {
                      const newDocuments = [...documents]
                      newDocuments[i].type = e.target.value as FileTypeEnum
                      setDocuments(newDocuments)
                    }}
                  >
                    {Object.values(FileTypeEnum).map((v) => (
                      <MenuItem key={v} value={v}>
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                  <i
                    className="fas fa-times"
                    style={{
                      fontSize: '24px',
                      color: 'red',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      const newDocuments = [...documents]
                      newDocuments.splice(i, 1)
                      setDocuments(newDocuments)
                    }}
                  />
                </div>
              </div>
            )
          })}

          <Dialog
            open={openDialog}
            onClose={cancelUpload}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>Select Document Type</DialogTitle>
            <DialogContent>
              <FormControl
                fullWidth
                sx={{
                  marginTop: '2rem ',
                }}
              >
                <InputLabel>Type of File</InputLabel>
                <Select
                  value={selectedFileType}
                  onChange={(e) => setSelectedFileType(e.target.value)}
                >
                  <MenuItem value="prescription">Prescription</MenuItem>
                  <MenuItem value="labResult">Lab Result</MenuItem>
                  <MenuItem value="oldReport">Old Report</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={cancelUpload}
                variant="contained"
                className="button-secondary"
              >
                Cancel
              </Button>
              <Button variant="contained" className="button-main">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Grid item xs={12}>
          <h3>Indication</h3>
          <Controller
            name="indication"
            control={control}
            render={({ field }) => {
              return <TextField {...field} fullWidth multiline rows={4} />
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            position: 'sticky',
            bottom: '0',
            left: '0',
            width: '100%',
            height: '100%',
            zIndex: 10,
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            className="button-secondary"
            onClick={() => {
              dispatch(setIsCreateReportDialogOpen(false))
            }}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoading}
            fullWidth
            className="button-main"
          >
            {isLoading ? <CircularProgress size={24} /> : 'Sauvegarder'}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default MedicalOrderForm
