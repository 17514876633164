import { useSelector } from '@state/store'
import { IntlProvider } from 'react-intl'

import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { SnackbarProvider } from 'notistack'

import ContactForm from '@components/forms/ContactForm'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './containers/app/Home'
import Profile from './containers/app/Profile'
import TermsOfService from './containers/app/TermsOfService'
import FirstConnexion from './containers/auth/FirstConnexion'
import ForgottenPassword from './containers/auth/ForgottenPassword'
import Login from './containers/auth/Login'
import NotFound from './containers/auth/NotFound'
import RegisterDoctor from './containers/auth/RegisterDoctor'
import RegisterGuest from './containers/auth/RegisterGuest'
import RegisterPatient from './containers/auth/RegisterPatient'
import ResetPassword from './containers/auth/ResetPassword'
import VerificationEmail from './containers/auth/VerificationEmail'
import Notifier from './containers/Notifier'
import AppLayoutBase from './layouts/AppLayoutBase'
import AuthLayout from './layouts/authLayout'
import PublicLayout from './layouts/publicLayout'
import { locales } from './locales'
import { LicenseInfo } from '@mui/x-license-pro'
import ReportTemplates from './components/reporting/ReportTemplate'
import ReportParagraphs from './components/reporting/ReportParagraphs'
import Messaging from './containers/app/messaging/Messaging'

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY || '')

export default function App() {
  const { language } = useSelector(({ auth }) => ({
    language: auth.language,
  }))

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <IntlProvider locale={language} messages={locales(language)}>
        <SnackbarProvider>
          <Notifier />
          <Router>
            <Routes>
              <Route path="public" element={<PublicLayout />}>
                <Route path="contact" element={<ContactForm />} />
                <Route path="terms" element={<TermsOfService />} />
                <Route path="privacy" element={<TermsOfService />} />
              </Route>

              <Route path="auth" element={<AuthLayout />}>
                <Route path="contact" element={<ContactForm />} />
                <Route path="login" element={<Login />} />
                <Route path="first-connexion" element={<FirstConnexion />} />
                <Route
                  path="forgotten-password"
                  element={<ForgottenPassword />}
                />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="register-doctor" element={<RegisterDoctor />} />
                <Route path="register-patient" element={<RegisterPatient />} />
                <Route
                  path="verification-mail"
                  element={<VerificationEmail />}
                />
                <Route path="register-guest" element={<RegisterGuest />} />
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route element={<AppLayoutBase />}>
                <Route path="contact" element={<ContactForm />} />
                <Route index element={<Home />} />
                <Route path="profile" element={<Profile />} />
                <Route path="messaging" element={<Messaging />} />
                <Route path="report-templates">
                  <Route path="" element={<ReportTemplates />} />
                  <Route path="paragraphs" element={<ReportParagraphs />} />
                </Route>
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </Router>
        </SnackbarProvider>
      </IntlProvider>
    </MuiPickersUtilsProvider>
  )
}
