import { Dialog, DialogContent } from '@material-ui/core'

import { useAppDispatch } from '../../hooks/reduxHooks'
import { setIsMedicalOrderTypeOpen } from '../../state/reducers/studyReducer'
import { useIntl } from 'react-intl'
import { DialogActions } from '@mui/material'
import { enqueueSnackbar } from '../../state/reducers/alertReducer'
import { Button } from '@components/buttons'
import { DialogTitle } from '@components/titles'
import theme from '@utils/theme'

const MedicalOrderTypeDialog = ({ open }) => {
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const handleConfirmClose = () => {
    dispatch(setIsMedicalOrderTypeOpen(false))
    dispatch(
      enqueueSnackbar({ message: 'Type modifié avec succès', type: 'success' }),
    )
  }

  const handleCancelClose = () => {
    dispatch(setIsMedicalOrderTypeOpen(false))
  }

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={open}
      onClose={() => {
        dispatch(setIsMedicalOrderTypeOpen(false))
      }}
      PaperProps={{
        style: {
          borderRadius: 20,
          width: '100%',
          background: '#E2E6F2',
        },
      }}
    >
      <DialogTitle
        title="Veulliez confirmer"
        style={{
          background: '#fff',
          color: theme.palette.primary.main,
        }}
      />
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          padding: 20,
        }}
      >
        {intl.formatMessage({
          id: 'confirm-mo-non-telemed-message',
        })}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancelClose}
          variant="contained"
          text="cancel"
          color="secondary"
        />
        <Button
          onClick={handleConfirmClose}
          variant="contained"
          text="confirm"
          color="primary"
        />
      </DialogActions>
    </Dialog>
  )
}

export default MedicalOrderTypeDialog
