import React from 'react'
import { Exam, MedicalOrderDto, Patient } from '../../services/api'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material'
import moment from 'moment'

interface Props {
  patient: Patient
  exam: Exam
  medicalOrder: MedicalOrderDto
}
const PatientSummary: React.FC<Props> = ({ patient, exam, medicalOrder }) => {
  // Style object for TableCell components to reduce height
  const cellStyle = {
    padding: '6px 16px',
    fontWeight: 700,
    marginRight: '10px',
    color: '#3f3f3f',
  }

  return (
    <TableContainer>
      <Table
        style={{
          borderRadius: '15px',
        }}
      >
        <TableBody>
          <TableRow>
            <TableCell style={cellStyle}>Prénom: {patient.firstName}</TableCell>
            <TableCell style={cellStyle}>Nom: {patient.lastName}</TableCell>
            <TableCell style={cellStyle}>
              Age: {moment().diff(patient.birthDate, 'years')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={cellStyle}>
              Sexe: {patient.sex === 'M' ? 'Masculin' : 'Féminin'}
            </TableCell>
            <TableCell style={cellStyle}>Adresse: {patient.address}</TableCell>
            <TableCell style={cellStyle}>
              Téléphone: {patient.mobileNumber || patient.phoneNumber}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={cellStyle}>SSN: {patient.ssn}</TableCell>
            <TableCell style={cellStyle}>Examen: {exam.label}</TableCell>
            <TableCell style={cellStyle}>
              Date:{' '}
              {moment(new Date(medicalOrder.plannedDate)).format(
                'DD-MM-YYYY HH:mm',
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PatientSummary
