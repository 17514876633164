import React, { useMemo, useState } from 'react'
import MedicalOrderTable from '../medical-order-table/MedicalOrderTable'
import { useFetchMedicalOrders } from '../../../hooks/useFetchMedicalOrders'
import { nuclMedicineColumns } from '../medical-order-table/columns'
import { ShareStudyDialog, FileUploadDialog, StudyDialog } from '../../dialogs'
import { useSelector } from '../../../state/store'
import { MedicalOrderDto, MedicalOrderStatusEnum } from '../../../services/api'
import { useIntl } from 'react-intl'
import {
  GridRenderCellParams,
  GridTreeNodeWithRender,
} from '@mui/x-data-grid-pro'
import {
  setSearchfilter,
  setSelected,
} from '../../../state/reducers/studyReducer'
import {
  StudyTooltip,
  FileUploadTooltip,
  ReportTooltipMemo,
  ImageTooltip,
  DownloadTooltip,
  ShareTooltip,
} from '../../tooltips'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { Tab, Tabs } from '@mui/material'
import { useAppSelector } from '../../../hooks/reduxHooks'

const useStyles = makeStyles({
  div: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  iconBtn: {
    padding: 0,
  },
  root: {
    '& .MuiDataGrid-row': {
      '&:nth-of-type(odd)': {
        backgroundColor: '#e8eeff',
      },
      '&:nth-of-type(even)': {
        backgroundColor: '#fff',
      },
    },
    boxShadow: '0px 1px 2px  rgba(0,0,0,0.3)',
  },
})

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface NucMedicineTableProps {}

const NucMedicineTable: React.FC<NucMedicineTableProps> = () => {
  const classes = useStyles()

  const { onSortingChange, studies } = useFetchMedicalOrders(true)
  const {
    account,
    viewerToken,
    study: selectedMedicalOrder,
    userConfiguration,
  } = useSelector(({ study, loading, auth }) => ({
    account: auth?.account,
    study: study.selected,
    loading: loading['study/findAll'],
    viewerToken: study.viewer.token,
    userConfiguration: auth.userConfiguration,
  }))
  const intl = useIntl()
  const dispatch = useDispatch()

  const searchFilter = useAppSelector((state) => state.study.searchFilter)

  const renderActions = (
    el: GridRenderCellParams<MedicalOrderDto, any, any, GridTreeNodeWithRender>,
  ): JSX.Element => {
    return (
      <div className={classes.div}>
        <StudyTooltip
          onClick={() => {
            openStudyDialog(el.row)
            setSelectedRow(el.row.id)
          }}
          study={el.row}
        />
        <FileUploadTooltip
          onClick={() => {
            openFileUploadDialog(el.row)
            setSelectedRow(el.row.id)
          }}
          study={el.row}
        />
        <div onClick={() => setSelectedRow(el.row.id)}>
          <ReportTooltipMemo study={el.row} />
        </div>
        <ImageTooltip
          viewerToken={viewerToken}
          study={el.row}
          account={account}
          setSelectedRow={setSelectedRow}
        />

        <DownloadTooltip study={el.row} account={account} />
        <ShareTooltip
          onClick={() => {
            onpenShareStudyDialog(el.row)
            setSelectedRow(el.row.id)
          }}
          study={el.row}
          account={account}
        />
      </div>
    )
  }

  const columns = useMemo(() => {
    const order = userConfiguration.tableState?.columns?.orderedFields
    const widths: { [key: string]: number } = {}

    if (userConfiguration.tableState?.columns?.dimensions) {
      Object.entries(userConfiguration.tableState.columns.dimensions).map(
        ([f, w]) => {
          widths[f] = w.width || 150
        },
      )
    }
    return nuclMedicineColumns(intl, renderActions, order, widths)
  }, [viewerToken, userConfiguration.tableState])

  const [studyOpen, setStudyOpen] = useState(false)
  const [shareOpen, setShareOpen] = useState(false)
  const [fileUploadOpen, setFileUploadOpen] = useState(false)
  const [, setSelectedRow] = useState<number | undefined>(undefined)

  const openStudyDialog = (study: MedicalOrderDto) => {
    dispatch(setSelected(study))
    setStudyOpen(true)
  }

  const openFileUploadDialog = (study: MedicalOrderDto) => {
    dispatch(setSelected(study))
    setFileUploadOpen(true)
  }
  const onpenShareStudyDialog = (study: MedicalOrderDto) => {
    dispatch(setSelected(study))
    setShareOpen(true)
  }

  const onFilterChange = (status: string) => {
    dispatch(
      setSearchfilter({
        ...searchFilter,
        filterStatus: status,
      }),
    )
  }

  return (
    <>
      <MedicalOrderTable
        density="compact"
        columns={columns}
        data={studies.datas}
        selectedRow={selectedMedicalOrder?.id.toString() || ''}
        pagination={{
          page: studies.page,
          size: studies.size,
          totalCount: studies.totalCount,
        }}
        onSortingChange={onSortingChange}
        tabs={
          <Tabs
            value={searchFilter.filterStatus}
            onChange={(_e, value) => {
              onFilterChange(value)
            }}
            sx={{
              display: 'inline-flex',
              backgroundColor: '#efefef',
              borderRadius: '8px 8px 0px 0px',
              '& .Mui-selected': {
                color: '#3f3f3f !important',

                fontStyle: 'normal',
                fontSize: '0.9rem',
                backgroundColor: '#fff',
              },
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: '#3f3f3f',
                height: '3px',
              },
            }}
          >
            <Tab
              value={MedicalOrderStatusEnum.completed}
              label={intl.formatMessage({ id: 'completedTab' })}
              sx={{
                boxShadow: '5px 0 2px -3px #3e3e3e50',
                fontSize: '0.8rem',
                color: '#3f3f3885',
                fontWeight: 'bold',
              }}
            />
            <Tab
              value={MedicalOrderStatusEnum.scheduled}
              label={intl.formatMessage({ id: 'scheduledTab' })}
              sx={{
                boxShadow: '5px 0 2px -3px #3e3e3e50',
                fontSize: '0.8rem',
                color: '#3f3f3885',
                fontWeight: 'bold',
              }}
            />
            <Tab
              value={MedicalOrderStatusEnum.canceled}
              label={intl.formatMessage({ id: 'canceledTab' })}
              sx={{
                boxShadow: '5px 0 2px -3px #3e3e3e50',
                fontSize: '0.8rem',
                color: '#3f3f3885',
                fontWeight: 'bold',
              }}
            />
          </Tabs>
        }
      />
      {selectedMedicalOrder && (
        <>
          <ShareStudyDialog
            open={shareOpen}
            setOpen={setShareOpen}
            study={selectedMedicalOrder}
          />
          <FileUploadDialog
            open={fileUploadOpen}
            setOpen={setFileUploadOpen}
            study={selectedMedicalOrder}
          />
          <StudyDialog
            open={studyOpen}
            setOpen={setStudyOpen}
            study={selectedMedicalOrder}
          />
        </>
      )}
    </>
  )
}

export default NucMedicineTable
