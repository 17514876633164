import { useMemo } from 'react'
import { AccessEnum } from '../services/api'
import { useSelector } from '../state/store'

export function usePermission() {
  const permissions = useSelector(({ auth }) => auth.permissions)
  const selectedSite = useSelector(({ study }) => study.searchFilter.siteIds)

  const currentPermissions = useMemo(() => {
    return permissions.reduce((acc, role) => {
      if (selectedSite?.length && !selectedSite.includes(role.siteId)) {
        return acc
      }

      acc = acc.concat(role.permissions.map((p) => p.permission))

      return acc
    }, new Array<AccessEnum>())
  }, [permissions, selectedSite])

  const hasPermission = (permission: AccessEnum) => {
    if (!currentPermissions.includes(permission)) {
      return false
    }

    return true
  }

  return { hasPermission }
}
