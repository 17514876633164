import React, { useEffect, useState } from 'react'
import { TooltipButton } from '../buttons'
import {
  AppFlagDto,
  CreateMedicalorderFlagDto,
  MedicalOrderDto,
} from '../../services/api'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  LinearProgress,
} from '@mui/material'
import { Button } from '@components/buttons'

import { DialogTitle } from '@components/titles'

import { useIntl } from 'react-intl'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import { setMedicalOrderFlags } from '../../state/thunks/studyThunk'
import { cleanStudy, setSelected } from '../../state/reducers/studyReducer'
import theme from '@utils/theme'
// import { has } from 'lodash'

interface Props {
  medicalOrder: MedicalOrderDto
}

export const FlagDialog: React.FC<Props> = ({ medicalOrder }) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const [isConfirmVisible, setIsConfirmVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const flags = useAppSelector((state) => state.study.flags)

  useEffect(() => {
    if (!isConfirmVisible) {
      dispatch(cleanStudy())
    }
  }, [isConfirmVisible])

  const onClick = () => {
    dispatch(setSelected(medicalOrder))
    setIsConfirmVisible(true)
  }

  const handleConfirmClose = () => {
    setIsConfirmVisible(false)
  }

  const onFlagCick = (f: AppFlagDto) => {
    if (!medicalOrder || !medicalOrder.flags) {
      return
    }

    let dto: CreateMedicalorderFlagDto[]

    const index = medicalOrder.flags?.findIndex((cf) => cf.flagName === f.name)
    if (index !== -1) {
      dto = medicalOrder.flags?.reduce((acc, _flag) => {
        if (_flag.flagName !== f.name) {
          acc.push({
            flag: _flag.flagName,
          })
        }
        return acc
      }, new Array<CreateMedicalorderFlagDto>())
    } else {
      dto = medicalOrder.flags.map((cf) => {
        return {
          flag: cf.flagName,
        }
      })
      dto.push({
        flag: f.name,
      })
    }
    setIsLoading(true)
    dispatch(
      setMedicalOrderFlags({
        id: medicalOrder?.id,
        dto,
      }),
    )
      .unwrap()
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <div style={{ position: 'relative' }}>
        <TooltipButton
          title="Flag"
          icon={<i className="fas fa-flag"></i>}
          onClick={onClick}
          disable={!medicalOrder?.radiologyStudy}
        />
      </div>

      <Dialog
        open={isConfirmVisible}
        onClose={handleConfirmClose}
        PaperProps={{
          style: {
            borderRadius: 20,
            width: '100%',
            background: '#E2E6F2',
          },
        }}
      >
        <DialogTitle
          // title={intl.formatMessage({
          //   id: 'discharge-confirm-title',
          // })}
          title="Marquer comme :"
          style={{
            background: '#fff',
            color: theme.palette.primary.main,
          }}
        />
        <DialogContent style={{ padding: 30 }}>
          {
            <LinearProgress
              color="secondary"
              style={{
                opacity: isLoading ? 1 : 0,
              }}
            />
          }
          <p>
            {flags.map((f) => {
              const hasFlag =
                medicalOrder?.flags?.findIndex(
                  (cf) => cf.flagName === f.name,
                ) !== -1
              return (
                <Grid item xs={12} key={f.name}>
                  <div
                    style={{
                      cursor: 'pointer',
                      padding: '12px 8px',
                      marginBottom: '8px',
                      borderRadius: '5px',
                      opacity: 0.6,
                      backgroundColor: hasFlag ? f.color : 'white',
                      border: hasFlag
                        ? `2px solid ${f.color}`
                        : '2px solid lightgray',
                      // add hover effect
                    }}
                    key={f.name}
                    onClick={() => onFlagCick(f)}
                  >
                    <span>{intl.formatMessage({ id: f.name })}</span>
                  </div>
                </Grid>
              )
            })}
          </p>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleConfirmClose}
            color="secondary"
            variant="contained"
            text="cancel"
          />
          <Button
            color="primary"
            variant="contained"
            onClick={handleConfirmClose}
            text="close"
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FlagDialog
