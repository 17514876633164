import { useAppSelector } from '@hooks/reduxHooks'
import { Tab, Tabs } from '@mui/material'
import { Document, FileTypeEnum } from '@services/api'
import StorageService from '@services/storage'
import { useEffect, useState } from 'react'

const PatientDocumentViewer = () => {
  const documents = useAppSelector(({ patients }) => patients.patientDocuments)

  const [token, setToken] = useState('')
  const [activeDocument, setActiveDocument] = useState<Document | null>(null)
  const [sortedDocuments, setsortedDocuments] = useState<Document[]>([])

  useEffect(() => {
    const reports: Document[] = []
    const prescriptions: Document[] = []
    const others: Document[] = []

    documents.forEach((doc) => {
      switch (doc.fileType) {
        case FileTypeEnum.REPORT:
          reports.push(doc)
          break
        case FileTypeEnum.PRESCRIPTION:
          prescriptions.push(doc)
          break
        default:
          others.push(doc)
          break
      }
    })

    const val = [...reports, ...prescriptions, ...others]

    setActiveDocument(val[0])
    setsortedDocuments(val)
  }, [documents])

  useEffect(() => {
    const token = StorageService.getAuthToken()
    if (!token) {
      return
    }

    setToken(token)
  }, [])

  const getFileType = (filename: string): 'image' | 'pdf' | 'doc' | 'docx' => {
    let ext = filename.split('.').pop()?.toLowerCase()
    if (ext) {
      const queryStringIndex = ext.indexOf('?')
      if (queryStringIndex > -1) {
        ext = ext.substring(0, queryStringIndex)
      }
    }

    console.log(ext)
    switch (ext) {
      case 'pdf':
        return 'pdf'
      case 'doc':
      case 'docx':
        return 'doc'
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return 'image'
      default:
        return 'pdf'
    }
  }
  const [data, setdata] = useState<Uint8Array | null>(null)
  useEffect(() => {
    if (activeDocument) {
      // download via url
      const url = `${process.env.REACT_APP_STATIC_FILES_URL}${activeDocument.url}?token=${token}`
      fetch(url)
        .then((res) => res.arrayBuffer())
        .then((ab) => {
          setdata(new Uint8Array(ab))
        })
    }
  }, [activeDocument])

  const renderActiveDocument = () => {
    if (!activeDocument) return <></>
    const fileType = getFileType(activeDocument.gedFile.filename)

    switch (fileType) {
      case 'pdf':
        return (
          <iframe
            frameBorder="0"
            width="100%"
            height="90%"
            src={`${process.env.REACT_APP_STATIC_FILES_URL}${activeDocument.url}?token=${token}#toolbar=0&zoom=75`}
          />
        )
      case 'image':
        return (
          <div>
            <img
              src={`${process.env.REACT_APP_STATIC_FILES_URL}${activeDocument.url}?token=${token}`}
              alt={activeDocument.gedFile.filename}
              width="100%"
              height="100%"
            />
          </div>
        )
    }
  }
  return token && activeDocument ? (
    <div style={{ height: '100%' }}>
      <Tabs
        variant="scrollable"
        value={activeDocument.id}
        onChange={(_, newValue) => {
          const doc = sortedDocuments.find((d) => d.id === newValue)
          if (doc) {
            setActiveDocument(doc)
          }
        }}
        style={{
          backgroundColor: 'white',
          borderTop: '2px solid #ccc',
          borderLeft: '2px solid #ccc',
          borderRight: '2px solid #ccc',
          borderRadius: '10px 10px 0 0',
        }}
      >
        {sortedDocuments.map((doc) => (
          <Tab
            key={doc.id}
            value={doc.id}
            label={doc.gedFile.filename}
            style={{ fontSize: '12px' }}
          />
        ))}
      </Tabs>
      {activeDocument && data && (
        <div style={{ height: '100%', width: '100%' }}>
          {renderActiveDocument()}
        </div>
      )}
    </div>
  ) : (
    <>Loading...</>
  )
}

export default PatientDocumentViewer
