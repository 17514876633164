import { useSelector } from '@state/store'

import { Container, makeStyles } from '@material-ui/core'

import { AccountRoleEnum } from '@services/api'
import HomeDoctor from './doctor/HomeDoctor'
import HomePatient from './patient/HomePatient'
import HomeGuest from './guest/HomeGuest'
import HomeMember from './member/HomeMember'
import HomeTelemedUser from './telemd_user/HomeTelemedUser'
import HomeTelemedDoctor from './telemed_doctor/HomeTelemedDoctor'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    padding: 0,
  },
}))

const getHomeComponent = (role: AccountRoleEnum) => {
  switch (role) {
    case AccountRoleEnum.doctor:
      return <HomeDoctor />
    case AccountRoleEnum.member:
      return <HomeMember />
    case AccountRoleEnum.guest:
      return <HomeGuest />
    case AccountRoleEnum.patient:
      return <HomePatient />
    case AccountRoleEnum.telemed_user:
      return <HomeTelemedUser />
    case AccountRoleEnum.telemed_doctor:
      return <HomeTelemedDoctor />
    case AccountRoleEnum.telemed_radiographer:
      return <HomeTelemedDoctor />
    default:
      return <h3>Role undefined</h3>
  }
}

export default function Home() {
  const classes = useStyles()
  const account = useSelector(({ auth }) => auth.account)

  if (!account) {
    return <></>
  }

  return (
    <Container className={classes.container} maxWidth={false}>
      {getHomeComponent(account.role)}
    </Container>
  )
}
