import { TooltipButton } from '@components/buttons'
import { Icon } from '@material-ui/core'
import { openViewer, openViewerV2 } from '@services/viewer'
import { AccountDto, MedicalOrderDto } from '@services/api'
import { useDispatch } from 'react-redux'
import {
  emitImagesViewedEvent,
  emitMedicalOrderViewedEvent,
} from '../../state/thunks/studyThunk'

type ImageTooltipProps = {
  study: MedicalOrderDto
  account?: AccountDto
  viewerToken: string | null
  setSelectedRow: (row: number) => void
}

export function ImageTooltip({
  study,
  viewerToken,
  setSelectedRow,
}: ImageTooltipProps) {
  const dispatch = useDispatch()

  const _onOpenViewerClick = () => {
    setSelectedRow(study.id)
    dispatch(emitImagesViewedEvent(study.id))
    dispatch(emitMedicalOrderViewedEvent(study.id))
    openViewerV2(study)
  }
  return (
    <TooltipButton
      title="images"
      onClick={_onOpenViewerClick}
      // disable={!study.radiologyStudy}
      color={study.radiologyStudy ? 'primary' : undefined}
      icon={<Icon fontSize="small" className="fas fa-eye" />}
    />
  )
}
