import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  AnswerDto,
  Exam,
  ExamAquisitionProtocolDto,
  QuestionDto,
} from '../../services/api'
import {
  findAnswers,
  getExamAquisitionProtocol,
  getExamQuestions,
  getExamsBySiteId,
} from '../thunks/examThunk'

export interface ExamState {
  exams: Exam[]
  aquisitionProtocols: ExamAquisitionProtocolDto[]
  questions: QuestionDto[]
  answers: AnswerDto[]
}

const initialState: ExamState = {
  exams: [],
  aquisitionProtocols: [],
  questions: [],
  answers: [],
}

const examSlice = createSlice({
  name: 'exam',
  initialState,
  reducers: {
    setAquisitionProtocol: (
      state,
      { payload }: PayloadAction<ExamAquisitionProtocolDto[]>,
    ) => {
      state.aquisitionProtocols = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getExamAquisitionProtocol.fulfilled,
      (state, { payload }: PayloadAction<ExamAquisitionProtocolDto[]>) => {
        state.aquisitionProtocols = payload
      },
    )
    builder.addCase(
      getExamsBySiteId.fulfilled,
      (
        state,
        { payload }: PayloadAction<{ totalCount: Number; datas: Exam[] }>,
      ) => {
        state.exams = payload.datas
      },
    )
    builder.addCase(
      getExamQuestions.fulfilled,
      (state, { payload }: PayloadAction<QuestionDto[]>) => {
        state.questions = payload
      },
    )
    builder.addCase(
      findAnswers.fulfilled,
      (state, { payload }: PayloadAction<AnswerDto[]>) => {
        state.answers = payload
      },
    )
  },
})

export default examSlice.reducer
