/* eslint-disable no-case-declarations */
import { useIntl } from 'react-intl'
import {
  NewResultAvailableNotification,
  NewShareNotificationDto,
  NotificationDto,
  NotificationTypeEnum,
} from '../services/api'
import axios from 'axios'
import StorageService from '../services/storage'

export const getNotificationText = (n: NotificationDto) => {
  const intl = useIntl()
  let text: string
  switch (n.type) {
    case NotificationTypeEnum.MEDICAL_ORDER_SHARED:
      const newShareNotificationDto = n as NewShareNotificationDto
      text = `Patient ${
        newShareNotificationDto.share.medicalOrder.patient.firstName
      } ${
        newShareNotificationDto.share.medicalOrder.patient.lastName
      } ${intl.formatMessage({ id: 'newStudy' })}`
      break

    case NotificationTypeEnum.EXAM_RESULT_AVAILABLE:
      const newResultAvailableNotification = n as NewResultAvailableNotification
      text = `${intl.formatMessage({ id: 'newResult' })} ${
        newResultAvailableNotification.medicalOrder.exam.label
      }`
      break

    default:
      text = ''
  }

  return text
}

export const isAllowed = (filename: string, allowedFileTypes: string[]) => {
  const fileType = filename.split('.').pop()
  if (!fileType) {
    return false
  }
  return allowedFileTypes.includes(fileType)
}

export const documentTypes = [
  'pdf',
  'jpg',
  'jpeg',
  'TIFF',
  'SVG',
  'Webp',
  'xlsx',
  'docx',
  'png',
  'gif',
  'bmp',
  'ico',
]

export const toDataUrl = async (url: string, includeAuth = true) => {
  const token = StorageService.getAuthToken()

  const response = await axios.get(url, {
    responseType: 'blob',
    withCredentials: true,
    ...(includeAuth && {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }),
  })
  return new Promise<string>((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(response.data)
    reader.onloadend = function () {
      const base64data = reader.result as string
      resolve(base64data)
    }
  })
}
export const fileToDataUrl = async (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = function () {
      const base64data = reader.result
      resolve(base64data as string)
    }
  })
}

export async function getImageFromClipboard() {
  try {
    const clipboardData = await (navigator.clipboard as any).read()
    for (const item of clipboardData) {
      if (
        item.types.includes('image/png') ||
        item.types.includes('image/jpeg')
      ) {
        const blob = await item.getType('image/png' || 'image/jpeg')
        const filename = 'clipboard-image.png'
        const file = new File([blob], filename, { type: blob.type })
        return file
      }
    }
    return null
  } catch (error) {
    console.error('Error reading clipboard data:', error)
    return null
  }
}

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj))
}

export function isBase64(str) {
  if (typeof str !== 'string') {
    return false
  }

  if (str.length % 4 !== 0) {
    return false
  }

  // eslint-disable-next-line no-useless-escape
  const validBase64Chars = /^[A-Za-z0-9+\/=]+$/
  return validBase64Chars.test(str)
}
