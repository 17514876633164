import { DroppableFileCard } from '@components/cards/DroppableFileCard'
import { useAppDispatch } from '@hooks/reduxHooks'
import { Button, CircularProgress, Modal } from '@mui/material'
import { Client } from '@services/api'
import StorageService from '@services/storage'
import { uploadFileV2 } from '@state/thunks/studyThunk'
import { dataURLToBlob, urlToFile } from '@utils/utils'
import { useEffect, useState } from 'react'
import {
  ImagesPageLayoutNameEnum,
  ImagesPageProps,
} from 'src/common/interfaces'
import * as markerJs from 'markerjs2'
import { getImageFromClipboard } from '@utils/helpers'

interface Props {
  pageIndex: number
  client: Client
  medicalOrderId: number
  onDelete: (pageIndex: number) => void
  pageProps: ImagesPageProps
  onPagePropsChange: (pageIndex: number, pageProps: ImagesPageProps) => void
  onEditLayout: (pageIndex: number) => void
  isSaving: boolean
}

const ReportPageV2: React.FC<Props> = ({
  pageProps,
  medicalOrderId,
  pageIndex,
  onDelete,
  client,
  onPagePropsChange,
  onEditLayout,
  isSaving,
}) => {
  const dispatch = useAppDispatch()

  const [isPageLayoutSelectVisible, setisPageLayoutSelectVisible] =
    useState(false)
  const [selectedGrid, setSelectedGrid] = useState<number>(-1)
  const [token, settoken] = useState('')

  useEffect(() => {
    const token = StorageService.getAuthToken()
    if (token) {
      settoken(token)
    }
  }, [])

  const uploadImage = async (file: File): Promise<string> => {
    const path = `clients/${client.name}/medical_orders/${medicalOrderId}/report_page_images`
    const res = await dispatch(uploadFileV2({ file, path })).unwrap()
    return `${process.env.REACT_APP_API_URL}/static/${res[0]}`
  }

  const onUrlChange = (url: string, index: number) => {
    const newImageUrls = [...pageProps.imageUrls]
    newImageUrls[index] = url
    onPagePropsChange(pageIndex, { ...pageProps, imageUrls: newImageUrls })
  }
  const renderLayout = (layoutName: ImagesPageLayoutNameEnum) => {
    switch (layoutName) {
      case ImagesPageLayoutNameEnum.Layout1:
        return (
          <Layout1
            imageUrls={pageProps.imageUrls}
            selectedGrid={selectedGrid}
            onUploadFile={uploadImage}
            setSelectedGrid={setSelectedGrid}
            onUrlChange={onUrlChange}
            token={token}
            isSaving={isSaving}
          />
        )
      case ImagesPageLayoutNameEnum.Layout2:
        return (
          <Layout2
            imageUrls={pageProps.imageUrls}
            selectedGrid={selectedGrid}
            onUploadFile={uploadImage}
            setSelectedGrid={setSelectedGrid}
            onUrlChange={onUrlChange}
            token={token}
            isSaving={isSaving}
          />
        )
      case ImagesPageLayoutNameEnum.Layout3:
        return (
          <Layout3
            imageUrls={pageProps.imageUrls}
            selectedGrid={selectedGrid}
            onUploadFile={uploadImage}
            setSelectedGrid={setSelectedGrid}
            token={token}
            onUrlChange={onUrlChange}
            isSaving={isSaving}
          />
        )
      default:
        return (
          <Layout1
            imageUrls={pageProps.imageUrls}
            selectedGrid={selectedGrid}
            onUploadFile={uploadImage}
            setSelectedGrid={setSelectedGrid}
            onUrlChange={onUrlChange}
            token={token}
            isSaving={isSaving}
          />
        )
    }
  }

  return (
    <div
      id={`image-page-${pageIndex}`}
      className="report-images-page"
      style={{
        position: 'relative',
        height: '297mm',
        width: '210mm',
        margin: 'auto',
      }}
    >
      {!isSaving && (
        <div style={{ position: 'absolute', top: 0, right: 0, zIndex: 100 }}>
          <Button onClick={() => onEditLayout(pageIndex)} style={{}}>
            <i
              style={{ fontSize: '22px', color: 'black' }}
              className="fas fa-cog"
            ></i>
          </Button>
          <Button onClick={() => onDelete(pageIndex)} style={{}}>
            <i
              style={{ fontSize: '28px', color: 'red' }}
              className="fas fa-times"
            ></i>
          </Button>
        </div>
      )}
      {renderLayout(pageProps.layoutName)}
      <Modal
        open={isPageLayoutSelectVisible}
        onClose={() => setisPageLayoutSelectVisible(false)}
      >
        <div>Select layout</div>
      </Modal>
    </div>
  )
}

export default ReportPageV2

interface LayoutProps {
  imageUrls: string[]
  selectedGrid: number
  onUploadFile: (file: File) => Promise<string>
  setSelectedGrid: (grid: number) => void
  onUrlChange: (url: string, index: number) => void
  token: string
  isSaving: boolean
}

export const Layout1: React.FC<LayoutProps> = ({
  selectedGrid,
  imageUrls,
  setSelectedGrid,
  onUrlChange,
  onUploadFile,
  token,
  isSaving,
}) => {
  const [loadingIndex, setloadingIndex] = useState(-1)

  const [selectedImage, setSelectedImage] = useState<{
    target: HTMLImageElement
    index: number
  } | null>(null)

  const onFileDrop = async (files: File[], index: number) => {
    if (files.length) {
      setloadingIndex(index)
      const url = await onUploadFile(files[0])
      onUrlChange(url, index)

      setloadingIndex(-1)
    }
  }

  const onImageUrlDrop = async (src: string, index: number) => {
    try {
      const file = await urlToFile(src)
      onFileDrop([file], index)
    } catch (error) {
      console.error('Error fetching and converting image:', error)
      throw error
    }
  }

  useEffect(() => {
    if (!selectedImage) {
      return
    }

    const markerArea = new markerJs.MarkerArea(selectedImage.target)

    markerArea.addEventListener('render', (event) => {
      const dataUrl = event.dataUrl
      const blob = dataURLToBlob(dataUrl)
      const file = new File([blob], 'image.png', { type: 'image/png' })

      onFileDrop([file], selectedImage.index)
      setSelectedImage(null)
    })

    markerArea.settings.displayMode = 'popup'
    markerArea.renderAtNaturalSize = true

    markerArea.show()
  }, [selectedImage])

  const handlePasteClick = async (index: number) => {
    const f = await getImageFromClipboard()
    if (f) {
      console.log({ index, f })
      onFileDrop([f], index)
    }
  }

  useEffect(() => {
    const onPaste = () => {
      if (selectedGrid === -1) {
        return
      }
      handlePasteClick(selectedGrid)
    }
    window.addEventListener('paste', onPaste)

    return () => {
      window.removeEventListener('paste', onPaste)
    }
  }, [selectedGrid])

  const [imageUrl1, imageUrl2] = imageUrls

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '296mm',
        width: '209mm',
        backgroundColor: 'white',
        flexGrow: 1,
      }}
      id="report-images-page"
    >
      <div style={{ display: 'flex', flex: 1, position: 'relative' }}>
        <DroppableFileCard
          isSelected={selectedGrid === 0 && !isSaving}
          onDrop={({ files, src }) => {
            if (files) {
              onFileDrop(files, 0)
            } else if (src) {
              onImageUrlDrop(src, 0)
            }
          }}
          onClick={() => {
            setSelectedGrid(0)
          }}
          style={{
            textAlign: 'center',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            position: 'relative',
          }}
        >
          {!isSaving && !!imageUrl1 && (
            <>
              <Button
                style={{
                  position: 'absolute',
                  top: '2px',
                  right: '120px',
                  fontSize: '22px',
                  color: 'black',
                  borderColor: 'black',
                }}
                variant="text"
                onClick={() => {
                  onUrlChange('', 0)
                }}
              >
                <i className="fas fa-eraser"></i>
              </Button>
            </>
          )}
          {loadingIndex === 0 ? (
            <CircularProgress />
          ) : (
            imageUrl1 && (
              <img
                src={imageUrl1 + '?token=' + token}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
                onDoubleClick={(ev) => {
                  ev.preventDefault()
                  setSelectedImage({
                    target: ev.target as HTMLImageElement,
                    index: 0,
                  })
                }}
                crossOrigin={isSaving ? undefined : 'anonymous'}
              />
            )
          )}
        </DroppableFileCard>
      </div>
      <div style={{ display: 'flex', flex: 1 }}>
        <DroppableFileCard
          isSelected={selectedGrid === 1 && !isSaving}
          onDrop={({ files, src }) => {
            if (files) {
              onFileDrop(files, 1)
            } else if (src) {
              onImageUrlDrop(src, 1)
            }
          }}
          onClick={() => {
            setSelectedGrid(1)
          }}
          style={{
            textAlign: 'center',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            position: 'relative',
          }}
        >
          {!isSaving && !!imageUrl2 && (
            <>
              <Button
                style={{
                  position: 'absolute',
                  top: '2px',
                  right: '120px',
                  fontSize: '22px',
                  color: 'black',
                  borderColor: 'black',
                }}
                variant="text"
                onClick={() => {
                  onUrlChange('', 1)
                }}
              >
                <i className="fas fa-eraser"></i>
              </Button>
            </>
          )}
          {loadingIndex === 1 ? (
            <CircularProgress />
          ) : (
            imageUrl2 && (
              <img
                src={imageUrl2 + '?token=' + token}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
                onDoubleClick={(ev) => {
                  ev.preventDefault()
                  setSelectedImage({
                    target: ev.target as HTMLImageElement,
                    index: 1,
                  })
                }}
                crossOrigin={isSaving ? undefined : 'anonymous'}
              />
            )
          )}
        </DroppableFileCard>
      </div>
    </div>
  )
}

export const Layout2: React.FC<LayoutProps> = ({
  onUploadFile,
  onUrlChange,
  imageUrls,
  selectedGrid,
  setSelectedGrid,
  token,
  isSaving,
}) => {
  const [loadingIndex, setloadingIndex] = useState(-1)
  const [selectedImage, setSelectedImage] = useState<{
    target: HTMLImageElement
    index: number
  } | null>(null)

  const onFileDrop = async (files: File[], index: number) => {
    if (files.length) {
      setloadingIndex(index)
      const url = await onUploadFile(files[0])
      onUrlChange(url, index)

      setloadingIndex(-1)
    }
  }

  const onImageUrlDrop = async (src: string, index: number) => {
    try {
      const file = await urlToFile(src)
      console.log({ file })
      onFileDrop([file], index)
    } catch (error) {
      console.error('Error fetching and converting image:', error)
      throw error
    }
  }

  const handlePasteClick = async (index: number) => {
    const f = await getImageFromClipboard()
    if (f) {
      onFileDrop([f], index)
    }
  }

  useEffect(() => {
    if (selectedGrid === -1) {
      return
    }
    const onPaste = () => {
      handlePasteClick(selectedGrid)
    }
    window.addEventListener('paste', onPaste)

    return () => {
      window.removeEventListener('paste', onPaste)
    }
  }, [selectedGrid])

  useEffect(() => {
    if (!selectedImage) {
      return
    }

    const markerArea = new markerJs.MarkerArea(selectedImage.target)

    markerArea.addEventListener('render', (event) => {
      const dataUrl = event.dataUrl
      const blob = dataURLToBlob(dataUrl)
      const file = new File([blob], 'image.png', { type: 'image/png' })

      onFileDrop([file], selectedImage.index)
      setSelectedImage(null)
    })

    markerArea.settings.displayMode = 'popup'
    markerArea.renderAtNaturalSize = true

    markerArea.show()
  }, [selectedImage])
  const [imageUrl1, imageUrl2, imageUrl3, imageUrl4] = imageUrls

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '296mm',
        width: '209mm',
        backgroundColor: 'white',
        flexGrow: 1,
      }}
    >
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
          }}
        >
          <DroppableFileCard
            isSelected={selectedGrid === 0 && !isSaving}
            onDrop={({ files, src }) => {
              if (files) {
                onFileDrop(files, 0)
              } else if (src) {
                onImageUrlDrop(src, 0)
              }
            }}
            onClick={() => {
              setSelectedGrid(0)
            }}
            style={{
              textAlign: 'center',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
              position: 'relative',
            }}
          >
            {!isSaving && !!imageUrl1 && (
              <>
                <Button
                  style={{
                    position: 'absolute',
                    top: '2px',
                    right: '120px',
                    fontSize: '22px',
                    color: 'black',
                    borderColor: 'black',
                  }}
                  variant="text"
                  onClick={() => {
                    onUrlChange('', 0)
                  }}
                >
                  <i className="fas fa-eraser"></i>
                </Button>
              </>
            )}
            {loadingIndex === 0 ? (
              <CircularProgress />
            ) : (
              imageUrl1 && (
                <img
                  src={imageUrl1 + `?token=${token}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                  crossOrigin={isSaving ? undefined : 'anonymous'}
                  onDoubleClick={(ev) => {
                    ev.preventDefault()
                    setSelectedImage({
                      target: ev.target as HTMLImageElement,
                      index: 0,
                    })
                  }}
                />
              )
            )}
          </DroppableFileCard>
          <DroppableFileCard
            isSelected={selectedGrid === 1 && !isSaving}
            onDrop={({ files, src }) => {
              if (files) {
                onFileDrop(files, 1)
              } else if (src) {
                onImageUrlDrop(src, 1)
              }
            }}
            onClick={() => {
              setSelectedGrid(1)
            }}
            style={{
              textAlign: 'center',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
              position: 'relative',
            }}
          >
            {!isSaving && !!imageUrl2 && (
              <>
                <Button
                  style={{
                    position: 'absolute',
                    top: '2px',
                    right: '120px',
                    fontSize: '22px',
                    color: 'black',
                    borderColor: 'black',
                  }}
                  variant="text"
                  onClick={() => {
                    onUrlChange('', 1)
                  }}
                >
                  <i className="fas fa-eraser"></i>
                </Button>
              </>
            )}
            {loadingIndex === 1 ? (
              <CircularProgress />
            ) : (
              imageUrl2 && (
                <img
                  src={imageUrl2 + `?token=${token}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                  crossOrigin={isSaving ? undefined : 'anonymous'}
                  onDoubleClick={(ev) => {
                    ev.preventDefault()
                    setSelectedImage({
                      target: ev.target as HTMLImageElement,
                      index: 1,
                    })
                  }}
                />
              )
            )}
          </DroppableFileCard>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
          }}
        >
          <DroppableFileCard
            isSelected={selectedGrid === 2 && !isSaving}
            onDrop={({ files, src }) => {
              if (files) {
                onFileDrop(files, 2)
              } else if (src) {
                onImageUrlDrop(src, 2)
              }
            }}
            onClick={() => {
              setSelectedGrid(2)
            }}
            style={{
              textAlign: 'center',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
              position: 'relative',
            }}
          >
            {!isSaving && !!imageUrl3 && (
              <>
                <Button
                  style={{
                    position: 'absolute',
                    top: '2px',
                    right: '120px',
                    fontSize: '22px',
                    color: 'black',
                    borderColor: 'black',
                  }}
                  variant="text"
                  onClick={() => {
                    onUrlChange('', 2)
                  }}
                >
                  <i className="fas fa-eraser"></i>
                </Button>
              </>
            )}
            {loadingIndex === 2 ? (
              <CircularProgress />
            ) : (
              imageUrl3 && (
                <img
                  src={imageUrl3 + `?token=${token}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                  crossOrigin={isSaving ? undefined : 'anonymous'}
                  onDoubleClick={(ev) => {
                    ev.preventDefault()
                    setSelectedImage({
                      target: ev.target as HTMLImageElement,
                      index: 2,
                    })
                  }}
                />
              )
            )}
          </DroppableFileCard>
          <DroppableFileCard
            isSelected={selectedGrid === 3 && !isSaving}
            onDrop={({ files, src }) => {
              if (files) {
                onFileDrop(files, 3)
              } else if (src) {
                onImageUrlDrop(src, 3)
              }
            }}
            onClick={() => {
              setSelectedGrid(3)
            }}
            style={{
              textAlign: 'center',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
              position: 'relative',
            }}
          >
            {!isSaving && !!imageUrl4 && (
              <>
                <Button
                  style={{
                    position: 'absolute',
                    top: '2px',
                    right: '120px',
                    fontSize: '22px',
                    color: 'black',
                    borderColor: 'black',
                  }}
                  variant="text"
                  onClick={() => {
                    onUrlChange('', 3)
                  }}
                >
                  <i className="fas fa-eraser"></i>
                </Button>
              </>
            )}
            {loadingIndex === 3 ? (
              <CircularProgress />
            ) : (
              imageUrl4 && (
                <img
                  src={imageUrl4 + `?token=${token}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                  crossOrigin={isSaving ? undefined : 'anonymous'}
                  onDoubleClick={(ev) => {
                    ev.preventDefault()
                    setSelectedImage({
                      target: ev.target as HTMLImageElement,
                      index: 3,
                    })
                  }}
                />
              )
            )}
          </DroppableFileCard>
        </div>
      </div>
    </div>
  )
}

export const Layout3: React.FC<LayoutProps> = ({
  onUploadFile,
  onUrlChange,
  imageUrls,
  selectedGrid,
  setSelectedGrid,
  token,
  isSaving,
}) => {
  const [loadingIndex, setloadingIndex] = useState(-1)
  const [selectedImage, setSelectedImage] = useState<{
    target: HTMLImageElement
    index: number
  } | null>(null)

  const onFileDrop = async (files: File[], index: number) => {
    if (files.length) {
      setloadingIndex(index)
      const url = await onUploadFile(files[0])
      onUrlChange(url, index)

      setloadingIndex(-1)
    }
  }

  const onImageUrlDrop = async (src: string, index: number) => {
    try {
      const file = await urlToFile(src)
      console.log({ file })
      onFileDrop([file], index)
    } catch (error) {
      console.error('Error fetching and converting image:', error)
      throw error
    }
  }

  const handlePasteClick = async (index: number) => {
    const f = await getImageFromClipboard()
    if (f) {
      onFileDrop([f], index)
    }
  }

  useEffect(() => {
    if (selectedGrid === -1) {
      return
    }
    const onPaste = () => {
      handlePasteClick(selectedGrid)
    }
    window.addEventListener('paste', onPaste)

    return () => {
      window.removeEventListener('paste', onPaste)
    }
  }, [selectedGrid])

  useEffect(() => {
    if (!selectedImage) {
      return
    }

    const markerArea = new markerJs.MarkerArea(selectedImage.target)

    markerArea.addEventListener('render', (event) => {
      const dataUrl = event.dataUrl
      const blob = dataURLToBlob(dataUrl)
      const file = new File([blob], 'image.png', { type: 'image/png' })

      onFileDrop([file], selectedImage.index)
      setSelectedImage(null)
    })

    markerArea.settings.displayMode = 'popup'
    markerArea.renderAtNaturalSize = true

    markerArea.show()
  }, [selectedImage])

  const [imageUrl1, imageUrl2, imageUrl3, imageUrl4, imageUrl5, imageUrl6] =
    imageUrls
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '296mm',
        width: '209mm',
        backgroundColor: 'white',
        flexGrow: 1,
      }}
    >
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
          }}
        >
          <DroppableFileCard
            isSelected={selectedGrid === 0 && !isSaving}
            onDrop={({ files, src }) => {
              if (files) {
                onFileDrop(files, 0)
              } else if (src) {
                onImageUrlDrop(src, 0)
              }
            }}
            onClick={() => {
              setSelectedGrid(0)
            }}
            style={{
              textAlign: 'center',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
              position: 'relative',
            }}
          >
            {!isSaving && !!imageUrl1 && (
              <>
                <Button
                  style={{
                    position: 'absolute',
                    top: '2px',
                    right: '120px',
                    fontSize: '22px',
                    color: 'black',
                    borderColor: 'black',
                  }}
                  variant="text"
                  onClick={() => {
                    onUrlChange('', 0)
                  }}
                >
                  <i className="fas fa-eraser"></i>
                </Button>
              </>
            )}
            {loadingIndex === 0 ? (
              <CircularProgress />
            ) : (
              imageUrl1 && (
                <img
                  src={imageUrl1 + `?token=${token}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                  crossOrigin={isSaving ? undefined : 'anonymous'}
                  onDoubleClick={(ev) => {
                    ev.preventDefault()
                    setSelectedImage({
                      target: ev.target as HTMLImageElement,
                      index: 0,
                    })
                  }}
                />
              )
            )}
          </DroppableFileCard>
          <DroppableFileCard
            isSelected={selectedGrid === 1 && !isSaving}
            onDrop={({ files, src }) => {
              if (files) {
                onFileDrop(files, 1)
              } else if (src) {
                onImageUrlDrop(src, 1)
              }
            }}
            onClick={() => {
              setSelectedGrid(1)
            }}
            style={{
              textAlign: 'center',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
              position: 'relative',
            }}
          >
            {!isSaving && !!imageUrl2 && (
              <>
                <Button
                  style={{
                    position: 'absolute',
                    top: '2px',
                    right: '120px',
                    fontSize: '22px',
                    color: 'black',
                    borderColor: 'black',
                  }}
                  variant="text"
                  onClick={() => {
                    onUrlChange('', 1)
                  }}
                >
                  <i className="fas fa-eraser"></i>
                </Button>
              </>
            )}
            {loadingIndex === 1 ? (
              <CircularProgress />
            ) : (
              imageUrl2 && (
                <img
                  src={imageUrl2 + `?token=${token}`}
                  crossOrigin={isSaving ? undefined : 'anonymous'}
                  onDoubleClick={(ev) => {
                    ev.preventDefault()
                    setSelectedImage({
                      target: ev.target as HTMLImageElement,
                      index: 1,
                    })
                  }}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                />
              )
            )}
          </DroppableFileCard>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
          }}
        >
          <DroppableFileCard
            isSelected={selectedGrid === 2 && !isSaving}
            onDrop={({ files, src }) => {
              if (files) {
                onFileDrop(files, 2)
              } else if (src) {
                onImageUrlDrop(src, 2)
              }
            }}
            onClick={() => {
              setSelectedGrid(2)
            }}
            style={{
              textAlign: 'center',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
              position: 'relative',
            }}
          >
            {!isSaving && !!imageUrl3 && (
              <>
                <Button
                  style={{
                    position: 'absolute',
                    top: '2px',
                    right: '120px',
                    fontSize: '22px',
                    color: 'black',
                    borderColor: 'black',
                  }}
                  variant="text"
                  onClick={() => {
                    onUrlChange('', 2)
                  }}
                >
                  <i className="fas fa-eraser"></i>
                </Button>
              </>
            )}
            {loadingIndex === 2 ? (
              <CircularProgress />
            ) : (
              imageUrl3 && (
                <img
                  src={imageUrl3 + `?token=${token}`}
                  crossOrigin={isSaving ? undefined : 'anonymous'}
                  onDoubleClick={(ev) => {
                    ev.preventDefault()
                    setSelectedImage({
                      target: ev.target as HTMLImageElement,
                      index: 2,
                    })
                  }}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                />
              )
            )}
          </DroppableFileCard>
          <DroppableFileCard
            isSelected={selectedGrid === 3 && !isSaving}
            onDrop={({ files, src }) => {
              if (files) {
                onFileDrop(files, 3)
              } else if (src) {
                onImageUrlDrop(src, 3)
              }
            }}
            onClick={() => {
              setSelectedGrid(3)
            }}
            style={{
              textAlign: 'center',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
              position: 'relative',
            }}
          >
            {!isSaving && !!imageUrl4 && (
              <>
                <Button
                  style={{
                    position: 'absolute',
                    top: '2px',
                    right: '120px',
                    fontSize: '22px',
                    color: 'black',
                    borderColor: 'black',
                  }}
                  variant="text"
                  onClick={() => {
                    onUrlChange('', 3)
                  }}
                >
                  <i className="fas fa-eraser"></i>
                </Button>
              </>
            )}
            {loadingIndex === 3 ? (
              <CircularProgress />
            ) : (
              imageUrl4 && (
                <img
                  src={imageUrl4 + `?token=${token}`}
                  crossOrigin={isSaving ? undefined : 'anonymous'}
                  onDoubleClick={(ev) => {
                    ev.preventDefault()
                    setSelectedImage({
                      target: ev.target as HTMLImageElement,
                      index: 3,
                    })
                  }}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                />
              )
            )}
          </DroppableFileCard>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
          }}
        >
          <DroppableFileCard
            isSelected={selectedGrid === 4 && !isSaving}
            onDrop={({ files, src }) => {
              if (files) {
                onFileDrop(files, 4)
              } else if (src) {
                onImageUrlDrop(src, 4)
              }
            }}
            onClick={() => {
              setSelectedGrid(4)
            }}
            style={{
              textAlign: 'center',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
              position: 'relative',
            }}
          >
            {!isSaving && !!imageUrl5 && (
              <>
                <Button
                  style={{
                    position: 'absolute',
                    top: '2px',
                    right: '120px',
                    fontSize: '22px',
                    color: 'black',
                    borderColor: 'black',
                  }}
                  variant="text"
                  onClick={() => {
                    onUrlChange('', 4)
                  }}
                >
                  <i className="fas fa-eraser"></i>
                </Button>
              </>
            )}
            {loadingIndex === 4 ? (
              <CircularProgress />
            ) : (
              imageUrl5 && (
                <img
                  src={imageUrl5 + `?token=${token}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                  crossOrigin={isSaving ? undefined : 'anonymous'}
                  onDoubleClick={(ev) => {
                    ev.preventDefault()
                    setSelectedImage({
                      target: ev.target as HTMLImageElement,
                      index: 4,
                    })
                  }}
                />
              )
            )}
          </DroppableFileCard>
          <DroppableFileCard
            isSelected={selectedGrid === 5 && !isSaving}
            onDrop={({ files, src }) => {
              if (files) {
                onFileDrop(files, 5)
              } else if (src) {
                onImageUrlDrop(src, 5)
              }
            }}
            onClick={() => {
              setSelectedGrid(5)
            }}
            style={{
              textAlign: 'center',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
              position: 'relative',
            }}
          >
            {!isSaving && !!imageUrl6 && (
              <>
                <Button
                  style={{
                    position: 'absolute',
                    top: '2px',
                    right: '120px',
                    fontSize: '22px',
                    color: 'black',
                    borderColor: 'black',
                  }}
                  variant="text"
                  onClick={() => {
                    onUrlChange('', 5)
                  }}
                >
                  <i className="fas fa-eraser"></i>
                </Button>
              </>
            )}
            {loadingIndex === 5 ? (
              <CircularProgress />
            ) : (
              imageUrl6 && (
                <img
                  src={imageUrl6 + `?token=${token}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                  crossOrigin={isSaving ? undefined : 'anonymous'}
                  onDoubleClick={(ev) => {
                    ev.preventDefault()
                    setSelectedImage({
                      target: ev.target as HTMLImageElement,
                      index: 5,
                    })
                  }}
                />
              )
            )}
          </DroppableFileCard>
        </div>
      </div>
    </div>
  )
}
