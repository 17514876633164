import React from 'react'
import { MedicalOrderDto, ReportTemplateDto } from '../../services/api'
import { Box, Button, Divider, Typography } from '@mui/material'
import moment from 'moment'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { makeStyles } from '@mui/styles'
import { useIntl } from 'react-intl'

interface Props {
  template: ReportTemplateDto
  onUseTemplateClick: (template: ReportTemplateDto) => void
  study?: MedicalOrderDto
  hideActions?: boolean
}

const useStyle = makeStyles(() => ({
  item: {
    padding: '0 8px',
    borderRadius: 8,
    margin: '4px 0',
    border: '1px solid #CCC',
    fontSize: '12px',
  },
  itemRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))

const ReportTemplatePreview: React.FC<Props> = ({
  template,
  hideActions,
  onUseTemplateClick,
}) => {
  const classes = useStyle()
  const intl = useIntl()

  return (
    <Box
      sx={{
        padding: '8px',
        margin: '4px 0',
        border: '1px solid #CCC',
        backgroundColor: 'white',
        borderRadius: '10px',
        marginBottom: '8px',
      }}
    >
      <div
        style={{
          width: '100%',
          padding: '0 4px',
          borderRadius: 8,
          margin: '4px 0',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            width: '100%',
          }}
        >
          <div className={classes.header}>
            <Typography fontWeight="bold" variant="h6">
              {template.name}
            </Typography>
            {hideActions !== true && (
              <div>
                <Button
                  style={{
                    fontSize: '12px',
                    backgroundColor: '#ECFBF3',
                    color: 'black',
                    border: '1px solid #1EBC86',
                    textTransform: 'capitalize',
                    padding: '2px 8px',
                    borderRadius: 18,
                    fontWeight: 'bold',
                  }}
                  onClick={() => {
                    onUseTemplateClick(template)
                  }}
                >
                  {intl.formatMessage({ id: 'use' })}
                </Button>
              </div>
            )}
          </div>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <CalendarTodayIcon
                style={{
                  color: '#6A6A6A',
                  fontSize: '14px',
                  marginRight: '2px',
                }}
              />
              <Typography fontSize="12px" variant="body2">
                {moment(template.createdAt).format('DD/MM/YYYY')}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: '4px',
              }}
            >
              <PersonOutlineIcon
                style={{
                  color: '#6A6A6A',
                  fontSize: '18px',
                  marginRight: '2px',
                }}
              />
              {template.isGlobal ? (
                <Typography fontSize="12px" variant="body2">
                  {intl.formatMessage({ id: 'global' })}
                </Typography>
              ) : (
                template.createdBy?.member && (
                  <Typography fontSize="12px" variant="body2">
                    {template.createdBy?.member?.firstName}{' '}
                    {template.createdBy?.member?.lastName}
                  </Typography>
                )
              )}
            </div>
          </Box>
        </Box>
      </div>
      <Divider />
      <div className={classes.itemRow}>
        <div className={classes.item}>
          {intl.formatMessage({ id: 'enums.modality.' + template.modality })}
        </div>
      </div>
    </Box>
  )
}

export default ReportTemplatePreview
