import {
  StateInfos,
  UpdateUserState,
  UserBriefInfosDto,
} from '@services/message.service'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { findMembers } from '@state/thunks/telemedThunk'
import { UserState } from '@services/telemed.service'
import { AccountRoleEnum, MessageNamesEnum } from '@services/api'
import { socketClient } from '@hooks/useWebsocket'

export interface TelemedState {
  members: { [key: string]: UserBriefInfosDto }
  currentState: StateInfos
}

const initialState: TelemedState = {
  members: {},
  currentState: {
    state: UserState.Idle,
    medicalOrderId: undefined,
  },
}

const slice = createSlice({
  name: 'telemed',
  initialState,
  reducers: {
    setConnectedUsers(state, { payload }: PayloadAction<UserBriefInfosDto[]>) {
      payload.forEach((u) => {
        const user = state.members[u.id]
        if (user) {
          user.isConnected = true
          user.stateInfos = u.stateInfos
        }
      })
    },
    addUser(state, action: PayloadAction<UserBriefInfosDto>) {
      state.members[action.payload.id] = action.payload
    },
    removeUser(state, action: PayloadAction<string>) {
      delete state.members[action.payload]
    },
    updateUser(state, action: PayloadAction<UserBriefInfosDto>) {
      state.members[action.payload.id] = action.payload
    },
    disconnectUser(state, action: PayloadAction<number>) {
      const user = state.members[action.payload]
      if (user) {
        user.isConnected = false
      }
    },
    setCurrentState(state, action: PayloadAction<StateInfos>) {
      state.currentState = action.payload
      const message: UpdateUserState = {
        stateInfos: action.payload,
      }
      socketClient.sendMessageToCurrentRoom(
        MessageNamesEnum.UpdateUserState,
        message,
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findMembers.fulfilled, (state, action) => {
      action.payload.forEach((user) => {
        if (state.members[user.id.toString()]) {
          return
        }

        const _user: UserBriefInfosDto = {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          role: user.role as AccountRoleEnum,
          stateInfos: {
            state: UserState.Idle,
            medicalOrderId: undefined,
          },
          isConnected: false,
          color: '',
          accountId: user.accountId,
        }
        state.members[user.id.toString()] = _user
      })
    })
  },
})

export default slice.reducer
export const {
  addUser,
  removeUser,
  updateUser,
  setCurrentState,
  disconnectUser,
} = slice.actions
