import React from 'react'
import { MedicalOrderDto } from '../../services/api'
import { Dialog } from '@mui/material'
import { DialogTitle } from '../titles'
import theme from '../../utils/theme'
import ExamQuestionnaire from '../telemed/ExamQuestionnaire'
import { useAppDispatch } from '@hooks/reduxHooks'
import { setCurrentState } from '@state/reducers/telemedReducer'
import { UserState } from '@services/telemed.service'

type QuestionnaireDialog = {
  open: boolean
  setOpen: (open: boolean) => void
  study: MedicalOrderDto
}
const QuestionnaireDialog: React.FC<QuestionnaireDialog> = ({
  open,
  setOpen,
  study,
}) => {
  const dispatch = useAppDispatch()

  const onClose = () => {
    setOpen(false)
    dispatch(
      setCurrentState({
        state: UserState.Idle,
      }),
    )
  }

  return (
    <Dialog
      fullScreen
      aria-labelledby="simple-dialog-title"
      open={open}
      PaperProps={{
        style: {
          width: '100%',
        },
      }}
    >
      <DialogTitle
        onClose={onClose}
        title={`${study?.exam.label} - ${
          study.plannedDate
            ? study.plannedDate
              .toString()
              .replace(
                /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{3})Z/,
                '$3/$2/$1',
              )
            : ''
        }`}
        style={{
          background: '#E2E6F240',
          color: theme.palette.primary.main,
        }}
      />
      <ExamQuestionnaire medicalOrder={study} onClose={() => setOpen(false)} />
    </Dialog>
  )
}

export default QuestionnaireDialog
