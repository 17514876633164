import Quill from 'quill'
import { destroyQuill } from './ReportingEditor'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import './ReportEditorComponent.css'
import { Client, MedicalOrderDto } from '@services/api'
import { debounce } from 'lodash'
import ReportHeaderComponent from './ReportHeaderComponent'
import moment from 'moment'
import React from 'react'
import ReactDOM from 'react-dom'
import { parseReport } from '@utils/reporting-utils'
import { ReportFooter } from './ReportFooter'
import { pxToMm } from '@utils/utils'

function getElementHeightInMM(element: HTMLElement): number {
  const h = window.getComputedStyle(element).height
  return pxToMm(parseFloat(h))
}

const toolbarOptions = [
  ['pagination'],
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote', 'code-block'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  // [{ script: 'sub' }, { script: 'super' }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ direction: 'rtl' }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],
]

export interface ReportEditorRef {
  setPages: (pages: string[]) => void
  getQuill: () => Quill | undefined
}
export interface ReportEditorComponentProps {
  client: Client
  medicalOrder?: MedicalOrderDto
  onPagesChange: (pages: string[]) => void
}

const ReportEditorComponent = forwardRef<
  ReportEditorRef,
  ReportEditorComponentProps
>(({ onPagesChange, medicalOrder, client }, ref) => {
  useImperativeHandle(ref, () => ({
    setPages: (pages: string[]) => {
      setPages(pages)
      setshouldSync(true)
    },
    getQuill: () => _quill.current,
  }))

  const [pages, setPages] = useState<string[]>([])
  const [shouldSync, setshouldSync] = useState(true)
  const _quill = useRef<Quill>()

  useEffect(() => {
    if (_quill.current) {
      destroyQuill(_quill.current, _quill.current.root)
      _quill.current.disable()
      _quill.current = undefined
    }

    const placeholderRegex = /{{(.*?)}}/g

    _quill.current = new Quill(' #editor ', {
      theme: 'snow',
      modules: {
        toolbar: toolbarOptions,
      },
    })

    _quill.current.on('text-change', (delta) => {
      const insert =
        delta.ops.length === 2 ? delta.ops[1].insert : delta.ops[0].insert

      if (
        delta.ops.length !== 0 &&
        insert &&
        (insert as string).match(placeholderRegex)
      ) {
        setTimeout(() => {
          _quill.current?.setSelection(_quill.current.getText().length - 1, 0)
        }, 20)
      }
    })

    _quill.current?.setSelection(_quill.current.getText().length - 1, 0)
    _quill.current.on('text-change', debounce(handleContentChange, 50))
  }, [])

  useEffect(() => {
    if (_quill.current && shouldSync) {
      _quill.current.root.innerHTML = pages.join('')
      setshouldSync(false)
    }
  }, [_quill.current, pages, shouldSync])

  const handleContentChange = async () => {
    if (!_quill.current) {
      return
    }

    const pageLimit = 296
    const allPs = document.querySelectorAll('#editor p')
    const filteredPs = Array.from(allPs).filter(
      (p) => !p.closest('.report-header') && !p.closest('.report-footer'),
    ) as HTMLElement[]

    const _pages: string[] = []
    const headerSize = pxToMm(234)
    const footerSize = pxToMm(100)
    let currentSize = headerSize + footerSize
    if (!filteredPs) {
      return
    }

    let str = ''
    const elements = document.getElementsByClassName('custom-page-break')

    for (let i = 0; i < elements.length; i++) {
      elements[i].remove()
    }

    for (let i = 0; i < filteredPs.length; i++) {
      const item = filteredPs[i]
      item.style.marginBottom = '0px'

      const elementHeight = getElementHeightInMM(item)
      currentSize += elementHeight

      if (currentSize >= pageLimit) {
        _pages.push(str)
        str = item.outerHTML
        currentSize = elementHeight + headerSize + footerSize

        filteredPs[i - 1].style.marginBottom = '440px'

        const previous = filteredPs[i - 1]
        if (previous) {
          const parent = document.getElementById('editor')
          if (!parent) {
            return
          }

          const div = document.createElement('div')
          div.id = 'page-break-' + i
          div.className = 'custom-page-break'

          const el = (
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'space-between',
              }}
            >
              {medicalOrder && (
                <ReportFooter
                  sitePhone={medicalOrder.site.phoneNumber}
                  siteName={medicalOrder.site.label}
                  siteEmail={medicalOrder.site.email}
                  siteAddress={medicalOrder.site.streetAddress}
                />
              )}
              <ReportHeaderComponent
                logo={client.logo}
                siteName={medicalOrder?.site?.label || ''}
                sitePhone={medicalOrder?.site?.phoneNumber || ''}
                siteAddress={medicalOrder?.site?.streetAddress || ''}
                siteWebsite="www.google.com"
                siteEmail={medicalOrder?.site?.email || ''}
                reportDate={moment().format('DD/MM/YYYY')}
                referringDoctorName={
                  medicalOrder?.referringDoctor?.firstName +
                    ' ' +
                    medicalOrder?.referringDoctor?.lastName || ''
                }
                telemedDoctorName={
                  medicalOrder?.attendingDoctor?.firstName +
                    ' ' +
                    medicalOrder?.attendingDoctor?.lastName || ''
                }
              />
            </div>
          )

          ReactDOM.render(el, div)

          const b = item.getBoundingClientRect()
          const parentB = parent?.getBoundingClientRect()

          div.style.top = b.top - parentB.top - 210 + 'px'
          div.style.left = '0px'

          parent.appendChild(div)
        }
      } else {
        str += item.outerHTML
      }
    }

    if (str) {
      _pages.push(str)
    }
    // setPages(_pages)
    onPagesChange(_pages)
  }

  return (
    <div id="editor-container">
      <ReportHeaderComponent
        className="first-report-header"
        logo={client.logo}
        siteName={medicalOrder?.site?.label || ''}
        sitePhone={medicalOrder?.site?.phoneNumber || ''}
        siteAddress={medicalOrder?.site?.streetAddress || ''}
        siteWebsite="www.google.com"
        siteEmail={medicalOrder?.site?.email || ''}
        reportDate={moment().format('DD/MM/YYYY')}
        referringDoctorName={
          medicalOrder?.referringDoctor?.firstName +
            ' ' +
            medicalOrder?.referringDoctor?.lastName || ''
        }
        telemedDoctorName={
          medicalOrder?.attendingDoctor?.firstName +
            ' ' +
            medicalOrder?.attendingDoctor?.lastName || ''
        }
      />
      <div
        id="editor"
        className="editor"
        style={{
          padding: '24px 24px 0 24px',
          minHeight: `${pxToMm(842) - 100}mm`,
        }}
      ></div>
      {medicalOrder && (
        <div style={{ transform: 'translateY(-20px)' }}>
          <ReportFooter
            sitePhone={medicalOrder.site.phoneNumber}
            siteName={medicalOrder.site.label}
            siteEmail={medicalOrder.site.email}
            siteAddress={medicalOrder.site.streetAddress}
          />
        </div>
      )}
    </div>
  )
})

export const RenderPreview: React.FC<{
  pages: string[]
  study?: MedicalOrderDto
  client: Client
}> = ({ pages, study, client }) => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        overflow: 'hidden',
      }}
      className="editor ql-container ql-snow ql-editor"
    >
      {pages.map((p, i) => (
        <div key={i}>
          <ReportHeaderComponent
            logo={client.logo}
            siteName={study?.site?.label || ''}
            sitePhone={study?.site?.phoneNumber || ''}
            siteAddress={study?.site?.streetAddress || ''}
            siteWebsite="www.google.com"
            siteEmail={study?.site?.email || ''}
            reportDate={moment().format('DD/MM/YYYY')}
            referringDoctorName={
              study?.referringDoctor?.firstName +
                ' ' +
                study?.referringDoctor?.lastName || ''
            }
            telemedDoctorName={
              study?.attendingDoctor?.firstName +
                ' ' +
                study?.attendingDoctor?.lastName || ''
            }
          />
          {study && (
            <div
              style={{
                padding: '24px',
              }}
              dangerouslySetInnerHTML={{
                __html: parseReport(p, study),
              }}
            />
          )}
          {study && (
            <ReportFooter
              sitePhone={study.site.phoneNumber}
              siteName={study.site.label}
              siteEmail={study.site.email}
              siteAddress={study.site.streetAddress}
            />
          )}
          {/* <p style={{ textAlign: 'center', marginTop: '5px' }}>
            {i + 1}/{pages.length}
          </p> */}
        </div>
      ))}
    </div>
  )
}

export default ReportEditorComponent
