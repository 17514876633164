import {
  AccountRoleEnum,
  axios,
  getConfigs,
  IRequestConfig,
  MessageDto,
} from './api'
import { UserState } from './telemed.service'

export class MessageService {
  private static basePath = process.env.REACT_APP_API_URL + '/messages'

  static findMessages(
    entityId: number,
    entityType: string,
    page = 0,
    limit = 20,
  ): Promise<{ totalCount: number; datas: MessageDto[] }> {
    return new Promise((resolve, reject) => {
      const url = `${this.basePath}?entityId=${entityId}&entityType=${entityType}&page=${page}&limit=${limit}`

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        {},
      )

      axios(configs, resolve, reject)
    })
  }

  static getRoomId(roomId: number): string {
    return `c-${roomId}`
  }

  static getPmId(roomId: number, userId: number): string {
    if (roomId < userId) {
      return `pm-${roomId}-${userId}`
    } else {
      return `pm-${userId}-${roomId}`
    }
  }
}

// TODO  rename this to TelemedUser
export interface UserBriefInfosDto {
  id: number
  firstName: string
  lastName: string
  role: AccountRoleEnum
  isConnected: boolean
  stateInfos: StateInfos
  color: string
  accountId: number
}

export interface StateInfos {
  state: UserState
  medicalOrderId?: number
}

export interface RoomMessageDto {
  roomId: string
  accountId: number
}

export interface SetViewingMoMessage extends RoomMessageDto {
  roomId: string
  medicalOrderId: number
  isViewing: boolean
}

export interface UserAction {
  siteId: number
  actionName: ActionNameEnum
  user: UserBriefInfosDto
}

export enum ActionNameEnum {
  SetProtocol = 'SetProtocol',
  SetDose = 'SetDose',
  ViewReport = 'ViewReport',
}

export interface GetUsersInRoomMessageDto extends RoomMessageDto {
  users: UserBriefInfosDto[]
}

export interface UpdateUserState {
  stateInfos: StateInfos
}

export interface GetUsersInRoomMessageDto extends RoomMessageDto {
  users: UserBriefInfosDto[]
}
