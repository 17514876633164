import {
  AccountRoleEnum,
  MedicalOrderDto,
  SharingExpirationEnum,
} from '@services/api'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

export const isSharingExpired = (
  startDate: Date,
  expiration: SharingExpirationEnum,
) => {
  let expirationDate = new Date()
  switch (expiration) {
    case SharingExpirationEnum.ONE_YEAR:
      expirationDate = moment(startDate).add(12, 'months').toDate()
      break
    case SharingExpirationEnum.NO_EXPIRATION:
    default:
      return false
  }
  if (expirationDate.getTime() < Date.now()) return true
  else return false
}

export function formatNumber(phone: string): string {
  if (phone.startsWith('6') || phone.startsWith('7')) {
    phone = '0' + phone
  }
  const frenchFormat = 'xx xx xx xx xx'
  let output = ''
  let pos = 0
  const inputLength = phone.length
  for (let i = 0; i < frenchFormat.length; i++) {
    const f = frenchFormat[i]
    if (f === 'x') {
      output += phone[pos++]
      if (pos >= inputLength) {
        break
      }
    } else {
      output += ' '
    }
  }
  return output
}

export function validateMobileNumber(phone: string): boolean {
  if (phone.startsWith('0') && phone.length === 1) {
    return true
  }
  return (
    phone.startsWith('06') ||
    phone.startsWith('07') ||
    phone.startsWith('6') ||
    phone.startsWith('7')
  )
}

export const getMimeType = (fileName: string): string => {
  const ext = fileName.split('.').pop()
  switch (ext) {
    case 'pdf':
      return 'application/pdf'
    case 'png':
      return 'image/png'
    case 'jpg':
      return 'image/jpeg'
    case 'jpeg':
      return 'image/jpeg'
    case 'gif':
      return 'image/gif'
    case 'doc':
      return 'application/msword'
    case 'docx':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    case 'xls':
      return 'application/vnd.ms-excel'
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    case 'ppt':
      return 'application/vnd.ms-powerpoint'
    case 'pptx':
      return 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    default:
      return 'application/octet-stream'
  }
}

export function formatTime(seconds: number): string {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const sec = Math.floor(seconds % 60)

  const paddedHours = hours.toString().padStart(2, '0')
  const paddedMinutes = minutes.toString().padStart(2, '0')
  const paddedSeconds = sec.toString().padStart(2, '0')

  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`
}

export function getUserTitle(role: AccountRoleEnum): string {
  switch (role) {
    case AccountRoleEnum.telemed_doctor:
      return 'Dr'
    case AccountRoleEnum.telemed_user:
      return 'S'
    case AccountRoleEnum.telemed_radiographer:
      return 'Manip'
    default:
      return ''
  }
}

export function getMedicalOrdersDiff(
  m1: MedicalOrderDto,
  m2: MedicalOrderDto,
): string {
  if (m1.confirmedNmDose !== m2.confirmedNmDose) {
    return `modifié la dose ${m1.confirmedNmDose}→${m2.confirmedNmDose}`
  }
  if (m1.acquisitionProtocols?.length !== m2.acquisitionProtocols?.length) {
    return 'modifié le protocole d\'acquisition'
  }
  return ''
}

export function dataURLToBlob(dataURL: string) {
  const parts = dataURL.split(',')
  const contentType = parts[0].split(':')[1].split(';')[0]
  const base64Data = parts[1]

  const binaryString = atob(base64Data)

  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }

  return new Blob([bytes], { type: contentType })
}

export const urlToFile = async (src: string): Promise<File> => {
  try {
    const response = await fetch(src)
    if (!response.ok) {
      throw new Error(`Network response was not ok for URL: ${src}`)
    }
    const blob = await response.blob()

    const name = uuidv4().toString()
    const file = new File([blob], `${name}.jpg`, { type: 'image/jpeg' })

    return file
  } catch (error) {
    console.error('Error fetching and converting image:', error)
    throw error
  }
}

export const getUrlFromImgHtmlString = (htmlString: string) => {
  const regex = /src="([^"]+)"/
  const match = htmlString.match(regex)

  return match ? match[1] : ''
}

export function pxToMm(px: number) {
  return px * 0.264583
}
