import { Box, Grid } from '@mui/material'
import ParagraphEditor from './ParagraphEditor'
import ReportingDataPanel from './ReportingDataPanel'

const ReportParagraphs = () => {
  return (
    <Grid
      container
      sx={{
        marginTop: '80px',
        backgroundColor: 'white',
        flex: 1,
      }}
    >
      <Grid item xs={12} md={3}>
        <Box
          sx={{
            borderRight: '1px solid #e0e0e0',
            padding: '4px',
          }}
        >
          <ReportingDataPanel />
        </Box>
      </Grid>
      <Grid item xs={12} md={9}>
        <ParagraphEditor />
      </Grid>
    </Grid>
  )
}

export default ReportParagraphs
