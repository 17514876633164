import { Button } from '@mui/material'
import React from 'react'
import { ImagesPageLayoutNameEnum } from 'src/common/interfaces'
import Layout1 from '../svg/1X1.svg'
import Layout2 from '../svg/2X2.svg'
import Layout3 from '../svg/2X3.svg'

interface Props {
  onSelect: (layoutName: ImagesPageLayoutNameEnum) => void
}

const ReportPageLayoutSelector: React.FC<Props> = ({ onSelect }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Button
        variant="text"
        onClick={() => onSelect(ImagesPageLayoutNameEnum.Layout1)}
      >
        <img src={Layout1} alt="Layout1" width={120} height={120} />
      </Button>
      <Button
        variant="text"
        onClick={() => onSelect(ImagesPageLayoutNameEnum.Layout2)}
      >
        <img src={Layout2} alt="Layout2" width={120} height={120} />
      </Button>
      <Button
        variant="text"
        onClick={() => onSelect(ImagesPageLayoutNameEnum.Layout3)}
      >
        <img src={Layout3} alt="Layout3" width={120} height={120} />
      </Button>
    </div>
  )
}

export default ReportPageLayoutSelector
