import { useEffect } from 'react'
import io from 'socket.io-client'
import { useSelector } from '../state/store'
import { MessageNamesEnum } from '../services/api'
import StorageService from '../services/storage'

export const socket = io(`${process.env.REACT_APP_WEBSOCKET_URL}/messages`, {
  auth: {
    token: StorageService.getAuthToken(),
  },
  transports: ['websocket'],
  upgrade: false,
})

socket.connect()

socket.on('connect', () => {
  console.log('connected')
})

socket.on('connect_error', (error) => {
  console.error('WebSocket connection error:', error)
})

socket.on('connect_timeout', (timeout) => {
  console.error('WebSocket connection timeout:', timeout)
})

export type MessageHandlerFunc = (data: any) => void

export interface MessageHandler {
  handlers: { messageName: MessageNamesEnum; fn: MessageHandlerFunc }[]
}

export class SocketClient {
  private _accountId: number
  public roomId!: string

  constructor() {
    this._accountId = 0
  }

  public set accountId(accountId: number) {
    this._accountId = accountId
  }

  get accountId() {
    return this._accountId
  }

  sendMessage<T>(messageName: MessageNamesEnum, data: T) {
    const message: MessagePayload<T> = {
      accountId: this.accountId,
      roomId: this.roomId.toString(),
      payload: data,
    }
    socket.emit(messageName, message)
  }

  sendMessageToCurrentRoom<T>(messageName: MessageNamesEnum, data: T) {
    const message: MessageToRoomPayload<T> = {
      accountId: this.accountId,
      roomId: this.roomId,
      payload: data,
    }
    socket.emit(messageName, message)
  }
}

export const socketClient = new SocketClient()

export function useWebsocket(props: MessageHandler) {
  const account = useSelector((state) => state.auth.account)

  useEffect(() => {
    if (socket.connected) {
      props.handlers.forEach((handler) => {
        socket.on(handler.messageName, handler.fn)
      })
    }
    return () => {
      props.handlers.forEach((handler) => {
        socket.off(handler.messageName, handler.fn)
      })
    }
  }, [socket.connected])

  const sendMessage = <T>(messageName: MessageNamesEnum, data: T) => {
    const message: MessagePayload<T> = {
      accountId: account?.id || 0,
      payload: data,
      roomId: '',
    }

    socket.emit(messageName, message)
  }

  return { sendMessage, isConnected: socket.connected }
}

export interface MessagePayload<T> {
  accountId: number
  payload: T
  roomId: string
}

export interface MessageToRoomPayload<T> extends MessagePayload<T> {
  roomId: string
}
