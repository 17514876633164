import { Button } from '@components/buttons'
import { DialogTitle } from '@components/titles'
import { Dialog, DialogContent, Grid } from '@material-ui/core'
import { IconButton, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch } from '../../hooks/reduxHooks'
import { MedicalOrderDto } from '../../services/api'
import { enqueueSnackbar } from '../../state/reducers/alertReducer'
import { update } from '@state/thunks/studyThunk'
import { cleanStudy } from '@state/reducers/studyReducer'
import theme from '@utils/theme'

type StudyDataDialogProps = {
  open: boolean
  study: MedicalOrderDto
  onClose: () => void
}

export const StudyDataDialog = ({
  open,
  study,
  onClose,
}: StudyDataDialogProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const [expectedNumSeries, setNumSeries] = useState<number | ''>(
    study.expectedNumSeries || '',
  )
  const [expectedNumImages, setNumImages] = useState<number | ''>(
    study.expectedNumImages || '',
  )
  const [patientGlycemicLevel, setGlycemicLevel] = useState<number | ''>(
    study.patientGlycemicLevel || '',
  )

  const onSave = () => {
    dispatch(
      update({
        id: study.id,
        body: {
          expectedNumSeries:
            expectedNumSeries === '' ? undefined : expectedNumSeries,
          expectedNumImages:
            expectedNumImages === '' ? undefined : expectedNumImages,
          patientGlycemicLevel:
            patientGlycemicLevel === '' ? undefined : patientGlycemicLevel,
        },
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(
          enqueueSnackbar({
            message: intl.formatMessage({ id: 'studyUpdated' }),
            options: {
              variant: 'success',
            },
          }),
        )
        onClose()
      })
      .catch((error) => {
        dispatch(
          enqueueSnackbar({
            message: error,
            options: {
              variant: 'error',
            },
          }),
        )
      })
  }

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={open}
      PaperProps={{
        style: { borderRadius: 20, width: '100%', background: '#E2E6F2' },
      }}
      onClose={() => {
        onClose()
        dispatch(cleanStudy())
      }}
    >
      <IconButton
        style={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: '#E91E63',
        }}
        onClick={() => {
          dispatch(cleanStudy())
          onClose()
        }}
      >
        <i className="fas fa-times" />
      </IconButton>
      <DialogTitle
        title={`Données - ${study?.exam?.label} - ${study?.patient?.firstName[0]}.${study?.patient?.lastName}`}
        style={{
          background: '#fff',
          color: theme.palette.primary.main,
          fontSize: '1.5rem',
        }}
      />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Nombre de séries attendues"
              value={expectedNumSeries}
              onChange={(e) =>
                setNumSeries(
                  e.target.value === '' ? '' : Number(e.target.value),
                )
              }
              fullWidth
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Nombre d'images envoyées"
              value={expectedNumImages}
              onChange={(e) =>
                setNumImages(
                  e.target.value === '' ? '' : Number(e.target.value),
                )
              }
              fullWidth
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Glycémie du patient"
              value={patientGlycemicLevel}
              onChange={(e) =>
                setGlycemicLevel(
                  e.target.value === '' ? '' : Number(e.target.value),
                )
              }
              fullWidth
              type="number"
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              variant="contained"
              text={intl.formatMessage({ id: 'cancel' })}
              color="secondary"
              fullWidth
              onClick={onClose}
              style={{
                marginRight: 20,
              }}
            />
            <Button
              variant="contained"
              text="Sauvegarder"
              color="primary"
              fullWidth
              onClick={onSave}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
