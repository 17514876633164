import React, { useEffect, useMemo, useState } from 'react'
import MedicalOrderTable from '../MedicalOrderTable'
import { useFetchMedicalOrders } from '../../../../hooks/useFetchMedicalOrders'
import { telemedTableColumns } from '../columns'
import { useIntl } from 'react-intl'
import {
  GridRenderCellParams,
  GridRowParams,
  GridTreeNodeWithRender,
} from '@mui/x-data-grid-pro'
import {
  AccessEnum,
  AppFlagNameEnum,
  MedicalOrderDto,
  MedicalOrderStatusEnum,
  Member,
  MessageNamesEnum,
  Site,
  TelemedQueryStatusEnum,
} from '../../../../services/api'
import { makeStyles } from '@mui/styles'
import {
  cleanStudy,
  removeActivityFromMedicalOrder,
  setIsCreateReportDialogOpen,
  setMedicalOrderActivity,
  setSearchfilter,
  setSelected,
} from '../../../../state/reducers/studyReducer'
import {
  DownloadTooltip,
  FileUploadTooltip,
  ImageTooltip,
  ReportTooltipMemo,
  StudyTooltip,
} from '../../../tooltips'
import { useDispatch } from 'react-redux'
import {
  FileUploadDialog,
  ShareStudyDialog,
  StudyDialog,
} from '../../../dialogs'
import {
  Icon,
  IconButton,
  Popover,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material'
import SettingsOverscanIcon from '@mui/icons-material/SettingsOverscan'
import AssignmentIcon from '@mui/icons-material/Assignment'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswerRounded'
import ManageAquisitionProtocolDialog from '../../../dialogs/ManageAquisitionProtocolDialog'
import ManageReportDialog from '../../../dialogs/ManageReportDialog'
import { DoseDialog } from '../../../telemed/DoseDialog'
import CreateMedicalOrderDialog from '../../../dialogs/CreateMedicalOrderDialog'
import { useAppSelector } from '../../../../hooks/reduxHooks'
import VeccinesIcon from '@mui/icons-material/Vaccines'
import {
  findAll,
  findAppFlags,
  findMedicalOrderIssues,
} from '../../../../state/thunks/studyThunk'
import { FlagDialog } from '../../../tooltips/FlagDialog'
import { StudyDataDialog } from '@components/telemed/StudyDataDialiog'
import { usePermission } from '../../../../hooks/usePermission'
import QuestionnaireDialog from '../../../dialogs/QuestionnaireDialog'
import MedicalOrderTypeDialog from '../../../dialogs/MedicalOrderTypeDialog'
import EditIcon from '@mui/icons-material/Edit'
import { DischargeToggleComponent } from '@components/telemed/DischargeToggleComponent'
import { useWebsocket } from '@hooks/useWebsocket'
import {
  GetUsersInRoomMessageDto,
  MessageService,
  StateInfos,
  UserBriefInfosDto,
} from '@services/message.service'
import { findMembers } from '@state/thunks/telemedThunk'
import {
  disconnectUser,
  setCurrentState,
  updateUser,
} from '@state/reducers/telemedReducer'
import { UserState } from '@services/telemed.service'
import { ActivityIndicator } from './AcrivityIndicator'
import { ActionButtonNameEnum, ActionToStateMap } from '@utils/Constants'
import { getMedicalOrdersDiff, getUserTitle } from '@utils/utils'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { store } from '@state/store'
import hotkeys from 'hotkeys-js'

hotkeys.filter = function () {
  return true
}

const useStyles = makeStyles({
  div: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  iconBtn: {
    padding: 0,
  },
  root: {
    '& .MuiDataGrid-row': {
      '&:nth-of-type(odd)': {
        backgroundColor: '#e8eeff',
      },
      '&:nth-of-type(even)': {
        backgroundColor: '#fff',
      },
    },
    boxShadow: '0px 1px 2px  rgba(0,0,0,0.3)',
  },
})

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TelemedTableProps {}

const TelemedTable: React.FC<TelemedTableProps> = () => {
  const intl = useIntl()
  const classes = useStyles()

  const {
    account,
    user,
    viewerToken,
    study: selectedMedicalOrder,
    userConfiguration,
    permissions,
  } = useAppSelector(({ study, loading, auth }) => ({
    account: auth?.account,
    user: auth.user,
    study: study.selected,
    loading: loading['study/findAll'],
    viewerToken: study.viewer.token,
    userConfiguration: auth.userConfiguration,
    permissions: auth.permissions,
  }))

  const dispatch = useDispatch()

  const [studyOpen, setStudyOpen] = useState(false)
  const [shareOpen, setShareOpen] = useState(false)
  const [fileUploadOpen, setFileUploadOpen] = useState(false)
  const [manageAquisitionProtocolOpen, setManageAquisitionProtocolOpen] =
    useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedRow, setSelectedRow] = useState<string>('')
  const [reportOpen, setReportOpen] = useState(false)
  const [doseOpen, setDoseOpen] = useState(false)
  const [studyDataOpen, setStudyDataOpen] = useState(false)
  const [questionnaireOpen, setQuestionnaireOpen] = useState(false)
  const [dischargeConfirmAnchor, setDischargeConfirmAnchor] =
    useState<HTMLButtonElement | null>(null)

  const [activeTab, setActiveTab] = useState(
    TelemedQueryStatusEnum.PendingProtocol,
  )

  const isCreateStudyOpen = useAppSelector(
    (state) => state.study.isCreateStudyOpen,
  )
  const searchFilter = useAppSelector((state) => state.study.searchFilter)
  const issues = useAppSelector((state) => state.study.issues)
  const isSetMedicalOrderTypeOpen = useAppSelector(
    (state) => state.study.isMedicalOrderTypeOpen,
  )
  const flags = useAppSelector((state) => state.study.flags)

  const { members } = useAppSelector((state) => ({
    members: state.telemed.members,
  }))

  const { onSortingChange, studies } = useFetchMedicalOrders(
    searchFilter.isTelemedicine,
  )

  const { hasPermission } = usePermission()

  const onUpdateUserState = (data: UserBriefInfosDto) => {
    dispatch(setMedicalOrderActivity(data))
    dispatch(updateUser(data))
  }

  const onUserConnected = (payload: UserBriefInfosDto) => {
    dispatch(
      updateUser({
        ...payload,
        isConnected: true,
      }),
    )
  }

  const onUserDisconnected = (payload: number) => {
    dispatch(disconnectUser(payload))
    dispatch(removeActivityFromMedicalOrder(payload))
  }

  const onGetUsersInRoomReceived = (payload: GetUsersInRoomMessageDto) => {
    payload.users.forEach((u) => {
      dispatch(
        updateUser({
          ...u,
          isConnected: true,
        }),
      )
      dispatch(setMedicalOrderActivity(u))
    })
  }

  const onMedicalOrderUpdated = (payload: {
    medicalOrder: MedicalOrderDto
    updatedBy: UserBriefInfosDto
  }) => {
    const s = store.getState().study.studies
    const el = document.getElementById('refresh-btn')
    const m = s.datas.find((s) => s.id === payload.medicalOrder.id)
    console.log({ m, payload })
    if (m) {
      const diff = getMedicalOrdersDiff(m, payload.medicalOrder)
      if (diff) {
        dispatch(
          enqueueSnackbar({
            message: `${getUserTitle(payload.updatedBy.role)}. ${
              payload.updatedBy.firstName
            } ${payload.updatedBy.lastName} a ${diff}\nPatient: ${
              payload.medicalOrder.patient?.firstName
            } ${payload.medicalOrder.patient?.lastName}, Exam: ${
              payload.medicalOrder.exam?.label
            }`,
          }),
        )
      }
    }
    if (el) {
      el.click()
    }
  }

  const getActionClassName = (
    actionName: ActionButtonNameEnum,
    stateInfos: StateInfos,
  ): string => {
    let classValue = ''

    const states = ActionToStateMap.get(actionName)
    if (!states) {
      return classValue
    }

    if (states.includes(stateInfos?.state)) {
      classValue += 'blink-background '
    }
    return classValue
  }

  const { sendMessage, isConnected } = useWebsocket({
    handlers: [
      {
        messageName: MessageNamesEnum.UpdateUserState,
        fn: onUpdateUserState,
      },
      {
        messageName: MessageNamesEnum.UserConnected,
        fn: onUserConnected,
      },
      {
        messageName: MessageNamesEnum.GetUsersInRoom,
        fn: onGetUsersInRoomReceived,
      },
      {
        messageName: MessageNamesEnum.UserDisconnected,
        fn: onUserDisconnected,
      },
      {
        messageName: MessageNamesEnum.MedicalOrderUpdated,
        fn: onMedicalOrderUpdated,
      },
    ],
  })

  const _members = useMemo(() => {
    return Object.values(members)
  }, [members])

  const sites = (user as any as Member & { sites: Site[] })?.sites || []

  useEffect(() => {
    dispatch(
      setSearchfilter({
        ...searchFilter,
        isTelemedicine: true,
        filterStatus: MedicalOrderStatusEnum.scheduled,
        telemedStatus: TelemedQueryStatusEnum.PendingProtocol,
      }),
    )
    dispatch(findMedicalOrderIssues())
    dispatch(findMembers())
  }, [])

  useEffect(() => {
    if (!isConnected || !user) {
      return
    }
    const clientId = (user as any as Member).clientId
    const roomId = MessageService.getRoomId(clientId)

    sendMessage(MessageNamesEnum.JoinRoom, {
      roomId,
    })

    return () => {
      sendMessage(MessageNamesEnum.LeaveRoom, {
        roomId,
      })
    }
  }, [sites, isConnected, user])

  const hasConfirmedDose = (study: MedicalOrderDto) => {
    return study.exam.nmDose && study.confirmedNmDose
  }

  const hasAcquisitionProtocolDeclared = (study: MedicalOrderDto) => {
    return (
      study.acquisitionProtocols?.length !== undefined &&
      study.acquisitionProtocols?.length > 0
    )
  }

  const hasIssue = (m: MedicalOrderDto): boolean => {
    return (
      issues.pendingDose.findIndex((i) => i.id === m.id) !== -1 ||
      issues.pendingProtocol.findIndex((i) => i.id === m.id) !== -1
    )
  }

  const getRowClassName = (m: MedicalOrderDto) => {
    let classValue = 'relative-row '

    if (hasIssue(m)) {
      classValue += 'mo-issue '
    }

    if (
      m.flags?.some((f) => f.flagName === AppFlagNameEnum.TO_DISPATCH) &&
      !m.dischargeConfirmed
    ) {
      classValue += 'mo-discharge '
    }

    if (m.flags?.some((f) => f.flagName === AppFlagNameEnum.MO_EMERGENCY)) {
      classValue += 'mo-emergency '
    }
    if (m.activityUser) {
      classValue += m.activityUser.color + ''
    }

    return classValue.trim()
  }

  const onOpenReportDialog = (medicalOrder: MedicalOrderDto) => {
    setReportOpen(true)
    dispatch(setSelected(medicalOrder))
    setSelectedRow(medicalOrder.id.toString())

    setCurrentState({
      medicalOrderId: medicalOrder.id,
      state: UserState.ViewingReport,
    })
  }

  const renderActions = (
    el: GridRenderCellParams<MedicalOrderDto, any, any, GridTreeNodeWithRender>,
  ): JSX.Element => {
    const isReportEditorEnabled = hasPermission(AccessEnum.EDIT_REPORT)

    const isDischargeRequested = el.row.flags?.some(
      (f) => f.flagName === AppFlagNameEnum.TO_DISPATCH,
    )
    // TODO refactor to make this many activityusers
    // const _hasActivity =
    //   !!el.row.activityUser &&
    //   el.row.activityUser.stateInfos?.medicalOrderId === el.row.id

    return (
      <div
        className={classes.div}
        onClick={() => setSelectedRow(el.row.id.toString())}
      >
        {el.row.activityUser && (
          <ActivityIndicator user={el.row.activityUser} />
        )}
        <StudyTooltip
          onClick={() => {
            openStudyDialog(el.row)
            setSelectedRow(el.row.id.toString())
            setCurrentState({
              medicalOrderId: el.row.id,
              state: UserState.ViewingMo,
            })
          }}
          className={
            !!el.row.activityUser &&
            el.row.activityUser.stateInfos?.medicalOrderId
              ? getActionClassName(
                ActionButtonNameEnum.StudyDialog,
                el.row.activityUser.stateInfos,
              )
              : ''
          }
          study={el.row}
        />
        <FileUploadTooltip
          onClick={() => {
            openFileUploadDialog(el.row)
            setSelectedRow(el.row.id.toString())
            dispatch(
              setCurrentState({
                state: UserState.ViewingFiles,
                medicalOrderId: el.row.id,
              }),
            )
          }}
          className={
            !!el.row.activityUser &&
            el.row.activityUser.stateInfos?.medicalOrderId
              ? getActionClassName(
                ActionButtonNameEnum.FileUploadDialog,
                el.row.activityUser.stateInfos,
              )
              : ''
          }
          study={el.row}
        />
        <Tooltip title="Créer / Editer le compte-rendu">
          <span
            className={
              !!el.row.activityUser &&
              el.row.activityUser.stateInfos?.medicalOrderId
                ? getActionClassName(
                  ActionButtonNameEnum.ManageReportDialog,
                  el.row.activityUser.stateInfos,
                )
                : ''
            }
          >
            <IconButton
              disabled={!isReportEditorEnabled}
              onClick={() => onOpenReportDialog(el.row)}
              style={{
                padding: 0,
                color: isReportEditorEnabled ? '#3f3f3f' : 'gray',
              }}
            >
              <AssignmentIcon fontSize="medium" />
            </IconButton>
          </span>
        </Tooltip>
        <div onClick={() => setSelectedRow(el.row.id.toString())}>
          <span className="icon-wrapper">
            <ReportTooltipMemo study={el.row} />
          </span>
        </div>
        <span className="icon-wrapper">
          <ImageTooltip
            viewerToken={viewerToken}
            study={el.row}
            account={account}
            setSelectedRow={(id) => setSelectedRow(id.toString())}
          />
        </span>
        {hasPermission(AccessEnum.EDIT_FLAG) && (
          <span className="icon-wrapper">
            <FlagDialog medicalOrder={el.row} />
          </span>
        )}
        <span className="icon-wrapper">
          <DownloadTooltip study={el.row} account={account} />
        </span>
        {hasPermission(AccessEnum.VIEW_DOSE) && (
          <Tooltip
            title={el.row.exam.nmDose ? 'Activité' : 'Examen sans activité'}
          >
            <span className="icon-wrapper">
              <IconButton
                style={{
                  padding: 0,
                  color: !el.row.exam.nmDose ? '#ccc' : '#3f3f3f',
                }}
                onClick={() => {
                  setDoseOpen(true)
                  dispatch(setSelected(el.row))
                  dispatch(
                    setCurrentState({
                      state: UserState.ViewingDose,
                      medicalOrderId: el.row.id,
                    }),
                  )
                }}
                disabled={!el.row.exam.nmDose}
              >
                <VeccinesIcon
                  className={
                    !!el.row.activityUser &&
                    el.row.activityUser?.stateInfos?.medicalOrderId
                      ? getActionClassName(
                        ActionButtonNameEnum.DoseDialog,
                        el.row.activityUser.stateInfos,
                      )
                      : ''
                  }
                  fontSize="medium"
                />
              </IconButton>
              <Icon
                className={
                  el.row.exam.nmDose && el.row.confirmedNmDose
                    ? 'fas fa-check'
                    : el.row.exam.nmDose && !el.row.confirmedNmDose
                      ? 'fas fa-times'
                      : ''
                }
                style={{
                  color: hasConfirmedDose(el.row) ? '#2dd88e' : '#E91E63',
                  fontSize: '1rem',
                  transform: 'translate(-5px, 0px)',
                }}
              />
            </span>
          </Tooltip>
        )}
        <Tooltip title="Données de l'examen">
          <span className="icon-wrapper">
            <IconButton
              onClick={() => {
                setStudyDataOpen(true)
                dispatch(setSelected(el.row))
                setSelectedRow(el.row.id.toString())
              }}
              style={{
                padding: 0,
                color: !el.row.radiologyStudy ? '#ccc' : '#3f3f3f',
              }}
              disabled={!el.row.radiologyStudy}
            >
              <Icon
                className={
                  'fas fa-file-signature ' +
                  (!!el.row.activityUser &&
                  el.row.activityUser?.stateInfos?.medicalOrderId
                    ? getActionClassName(
                      ActionButtonNameEnum.StudyDataDialog,
                      el.row.activityUser.stateInfos,
                    )
                    : '')
                }
                fontSize="medium"
              />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          title={
            el.row.exam.hasAcquisitionProtocol
              ? 'Protocole'
              : 'Examen sans protocole'
          }
        >
          {hasPermission(AccessEnum.VIEW_AQUISITION_PROTOCOL) ? (
            <span className="icon-wrapper">
              <IconButton
                onClick={() => {
                  setManageAquisitionProtocolOpen(true)
                  dispatch(setSelected(el.row))
                  setSelectedRow(el.row.id.toString())
                  dispatch(
                    setCurrentState({
                      state: UserState.ViewingProtocol,
                      medicalOrderId: el.row.id,
                    }),
                  )
                }}
                style={{
                  padding: 0,
                  color: !el.row.exam.hasAcquisitionProtocol
                    ? '#ccc'
                    : '#3f3f3f',
                }}
                disabled={!el.row.exam.hasAcquisitionProtocol}
              >
                <SettingsOverscanIcon
                  className={
                    !!el.row.activityUser &&
                    el.row.activityUser?.stateInfos?.medicalOrderId
                      ? getActionClassName(
                        ActionButtonNameEnum.ManageAquisitionProtocolDialog,
                        el.row.activityUser.stateInfos,
                      )
                      : ''
                  }
                  fontSize="medium"
                />
              </IconButton>
              <Icon
                className={
                  el.row.exam.hasAcquisitionProtocol &&
                  hasAcquisitionProtocolDeclared(el.row)
                    ? 'fas fa-check'
                    : el.row.exam.hasAcquisitionProtocol &&
                      !hasAcquisitionProtocolDeclared(el.row)
                      ? 'fas fa-times'
                      : ''
                }
                style={{
                  color: hasAcquisitionProtocolDeclared(el.row)
                    ? '#2dd88e'
                    : '#E91E63',
                  fontSize: '1rem',
                  transform: 'translate(-5px, 0px)',
                }}
              />
            </span>
          ) : (
            <></>
          )}
        </Tooltip>
        {/* <Tooltip title="Chat"> */}
        {/*   <span className="icon-wrapper"> */}
        {/*     <IconButton */}
        {/*       style={{ */}
        {/*         padding: 0, */}
        {/*         color: '#3f3f3f', */}
        {/*       }} */}
        {/*     > */}
        {/*       <QuestionAnswerIcon fontSize="medium" /> */}
        {/*     </IconButton> */}
        {/*   </span> */}
        {/* </Tooltip> */}
        {hasPermission(AccessEnum.CONFIRM_DISCHARGE) && isDischargeRequested && (
          <Tooltip title="Confirm discharge">
            <span className="icon-wrapper">
              <IconButton
                style={{
                  padding: 0,
                  color: '#3f3f3f',
                }}
                onClick={(e) => {
                  dispatch(setSelected(el.row))
                  setDischargeConfirmAnchor(e.currentTarget)
                }}
              >
                <Icon className="fas fa-check" fontSize="medium" />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {hasPermission(AccessEnum.VIEW_REPORT) && (
          <Tooltip title="Questionnaire">
            <span className="icon-wrapper" style={{ position: 'relative' }}>
              <IconButton
                onClick={() => {
                  setQuestionnaireOpen(true)
                  dispatch(setSelected(el.row))
                  dispatch(
                    setCurrentState({
                      medicalOrderId: el.row.id,
                      state: UserState.ViewingQuestionnaire,
                    }),
                  )
                }}
                style={{
                  padding: 0,
                  color: '#3f3f3f',
                }}
              >
                <i className="far fa-list-alt"></i>
              </IconButton>
              {el.row.patientCannotAnswerQuestions && (
                <span
                  style={{
                    position: 'absolute',
                    top: '-10px',
                    right: '-10px',
                  }}
                >
                  <i
                    className="fas fa-exclamation"
                    style={{
                      backgroundColor: 'pink',
                      display: 'inline-block',
                      width: '16px',
                      height: '16px',
                      borderRadius: '50%',
                      textAlign: 'center',
                      fontSize: '12px',
                      color: 'red',
                      lineHeight: '16px',
                    }}
                  ></i>
                </span>
              )}
            </span>
          </Tooltip>
        )}
        <Tooltip
          title={
            el.row.isCreatedFromPortal
              ? 'Modifier'
              : 'Seule les examens créés via l\'application sont modifiable'
          }
        >
          <span className="icon-wrapper">
            <IconButton
              style={{
                padding: 0,
                color: el.row.isCreatedFromPortal ? '#3f3f3f' : '#ccc',
              }}
              onClick={() => {
                dispatch(setSelected(el.row))
                dispatch(setIsCreateReportDialogOpen(true))
              }}
              disabled={!el.row.isCreatedFromPortal}
            >
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    )
  }

  const handleClose = () => {
    setDischargeConfirmAnchor(null)
    dispatch(cleanStudy())
  }

  const openStudyDialog = (study: MedicalOrderDto) => {
    dispatch(setSelected(study))
    setStudyOpen(true)
    dispatch(
      setCurrentState({
        medicalOrderId: study.id,
        state: UserState.ViewingMo,
      }),
    )
  }

  const openFileUploadDialog = (study: MedicalOrderDto) => {
    dispatch(setSelected(study))
    setFileUploadOpen(true)
  }

  const columns = useMemo(() => {
    const order = userConfiguration.tableState?.columns?.orderedFields
    const widths: { [key: string]: number } = {}

    if (userConfiguration.tableState?.columns?.dimensions) {
      Object.entries(userConfiguration.tableState.columns.dimensions).map(
        ([f, w]) => {
          widths[f] = w.width || 150
        },
      )
    }
    return telemedTableColumns(intl, renderActions, order, widths)
  }, [viewerToken, userConfiguration.tableState, permissions])

  const onFilterChange = (status: TelemedQueryStatusEnum) => {
    dispatch(
      setSearchfilter({
        ...searchFilter,
        todayOnly: false,
        filterStatus: MedicalOrderStatusEnum.scheduled,
        telemedStatus: status,
      }),
    )
  }

  const handleRowClick = (params: GridRowParams<any>) => {
    setSelectedRow(params.id.toString())
  }

  useEffect(() => {
    if (!doseOpen || !manageAquisitionProtocolOpen || !reportOpen) {
      const limit = studies.size
      const page = studies.page
      dispatch(
        findAll({
          limit,
          page,
          sortBy: {
            columnName: 'date',
            direction: 'desc',
          },
        }),
      )
    }
  }, [doseOpen, manageAquisitionProtocolOpen, reportOpen])

  useEffect(() => {
    if (flags.length === 0) {
      dispatch(findAppFlags())
    }
  }, [flags.length])

  useEffect(() => {
    if (studies.datas.length > 0) {
      setSelectedRow(studies.datas[0].id.toString())
    }
  }, [studies.datas])

  useEffect(() => {
    hotkeys('j', () => {
      console.log('SELECTED ROW : ', selectedRow)
      if (selectedRow) {
        const index = studies.datas.findIndex(
          (s) => s.id.toString() === selectedRow,
        )
        if (index !== -1 && index < studies.datas.length - 1) {
          setSelectedRow(studies.datas[index + 1].id.toString())
        }
      } else {
        console.log('HERE?', studies.datas[0].id)
        setSelectedRow(studies.datas[0].id.toString())
      }
    })

    hotkeys('k', () => {
      if (selectedRow) {
        const index = studies.datas.findIndex(
          (s) => s.id.toString() === selectedRow,
        )
        if (index !== -1 && index > 0) {
          setSelectedRow(studies.datas[index - 1].id.toString())
        }
      } else {
        setSelectedRow(studies.datas[0].id.toString())
      }
    })

    hotkeys('ctrl+b,ctrl+r', () => {
      const mo = studies.datas.find((s) => s.id.toString() === selectedRow)
      if (!mo) {
        return
      }

      onOpenReportDialog(mo)
    })

    hotkeys('ctrl+u,ctrl+r', (e) => {
      e.preventDefault()
      e.stopPropagation()
      onReportClose()
    })

    return () => {
      hotkeys.unbind('j')
      hotkeys.unbind('k')
      hotkeys.unbind('ctrl+b,ctrl+r')
      hotkeys.unbind('ctrl+u,ctrl+r')
    }
    // hotkeys('k', () => {})
  }, [studies.datas, selectedRow])

  const open = Boolean(dischargeConfirmAnchor)
  const id = open ? 'simple-popover' : undefined

  const onReportClose = () => {
    setReportOpen(false)
    dispatch(
      setCurrentState({
        state: UserState.Idle,
      }),
    )
  }

  return (
    <>
      <MedicalOrderTable
        density="standard"
        columns={columns}
        data={studies.datas}
        pagination={{
          page: studies.page,
          size: studies.size,
          totalCount: studies.totalCount,
        }}
        getRowClassName={getRowClassName}
        onSortingChange={onSortingChange}
        tabs={
          <Tabs
            value={activeTab}
            onChange={(_e, value) => {
              onFilterChange(value)
              setActiveTab(value)
            }}
            sx={{
              display: 'inline-flex',
              backgroundColor: '#efefef',
              borderRadius: '8px 8px 0px 0px',
              '& .Mui-selected': {
                color: '#3f3f3f !important',

                fontStyle: 'normal',
                fontSize: '0.9rem',
                backgroundColor: '#fff',
              },
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: '#3f3f3f',
                height: '3px',
              },
            }}
          >
            <Tab
              value={TelemedQueryStatusEnum.PendingDose}
              label={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {intl.formatMessage({ id: 'doseTab' })}
                  <span
                    style={{
                      backgroundColor: '#b8274c',
                      color: 'white',
                      borderRadius: '50%',
                      width: '18px',
                      height: '18px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '12px',
                      marginLeft: '5px',
                      padding: '2px',
                    }}
                  >
                    {issues.pendingDose.length}
                  </span>
                </div>
              }
              sx={{
                boxShadow: '5px 0 2px -3px #3e3e3e50',
                fontSize: '0.8rem',
                color: '#3f3f3885',
                fontWeight: 'bold',
              }}
            />
            <Tab
              value={TelemedQueryStatusEnum.PendingProtocol}
              label={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {intl.formatMessage({ id: 'protocolTab' })}
                  <span
                    style={{
                      backgroundColor: '#b8274c',
                      color: 'white',
                      borderRadius: '50%',
                      width: '18px',
                      height: '18px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '12px',
                      marginLeft: '5px',
                      padding: '2px',
                    }}
                  >
                    {issues.pendingProtocol.length}
                  </span>
                </div>
              }
              sx={{
                boxShadow: '5px 0 2px -3px #3e3e3e50',
                fontSize: '0.8rem',
                color: '#3f3f3885',
                fontWeight: 'bold',
              }}
            />
            <Tab
              value={TelemedQueryStatusEnum.TODO}
              label={intl.formatMessage({ id: 'todoTab' })}
              sx={{
                boxShadow: '5px 0 2px -3px #3e3e3e50',
                fontSize: '0.8rem',
                color: '#3f3f3885',
                fontWeight: 'bold',
              }}
            />
            <Tab
              value={TelemedQueryStatusEnum.DONE}
              label={intl.formatMessage({ id: 'doneTab' })}
              sx={{
                boxShadow: '5px 0 2px -3px #3e3e3e50',
                fontSize: '0.8rem',
                color: '#3f3f3885',
                fontWeight: 'bold',
              }}
            />
            <Tab
              value={TelemedQueryStatusEnum.UPCOMING}
              label={intl.formatMessage({ id: 'upcomingTab' })}
              sx={{
                boxShadow: '5px 0 2px -3px #3e3e3e50',
                fontSize: '0.8rem',
                color: '#3f3f3885',
                fontWeight: 'bold',
              }}
            />
          </Tabs>
        }
        isTodayOnlyHidden
        hideDatePicker
        pinnedColumns={{
          right: ['test', 'Actions'],
        }}
        onRowClick={handleRowClick}
        selectedRow={selectedRow}
      />
      {/* Dialogs */}
      {selectedMedicalOrder && (
        <>
          <ShareStudyDialog
            open={shareOpen}
            setOpen={setShareOpen}
            study={selectedMedicalOrder}
          />
          <FileUploadDialog
            open={fileUploadOpen}
            setOpen={setFileUploadOpen}
            study={selectedMedicalOrder}
            onClose={() => {
              dispatch(
                setCurrentState({
                  state: UserState.Idle,
                }),
              )
            }}
          />
          <StudyDialog
            open={studyOpen}
            setOpen={setStudyOpen}
            study={selectedMedicalOrder}
          />
          <ManageAquisitionProtocolDialog
            open={manageAquisitionProtocolOpen}
            setOpen={setManageAquisitionProtocolOpen}
            study={selectedMedicalOrder}
          />
          <ManageReportDialog
            open={reportOpen}
            setOpen={setReportOpen}
            study={selectedMedicalOrder}
            onClose={onReportClose}
          />
          {doseOpen && (
            <DoseDialog
              open={doseOpen}
              study={selectedMedicalOrder}
              onClose={() => {
                setDoseOpen(false)
              }}
            />
          )}
          {studyDataOpen && (
            <StudyDataDialog
              open={studyDataOpen}
              study={selectedMedicalOrder}
              onClose={() => {
                setStudyDataOpen(false)
              }}
            />
          )}
          {questionnaireOpen && (
            <QuestionnaireDialog
              open={questionnaireOpen}
              setOpen={setQuestionnaireOpen}
              study={selectedMedicalOrder}
            />
          )}
        </>
      )}
      <CreateMedicalOrderDialog open={isCreateStudyOpen} />
      <MedicalOrderTypeDialog open={isSetMedicalOrderTypeOpen} />
      {selectedMedicalOrder && (
        <Popover
          id={id}
          open={open}
          anchorEl={dischargeConfirmAnchor}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Typography sx={{ p: 2 }}>
            {
              <DischargeToggleComponent
                medicalOrderId={selectedMedicalOrder.id}
                dischargeConfirmed={selectedMedicalOrder.dischargeConfirmed}
              />
            }
          </Typography>
        </Popover>
      )}
    </>
  )
}

export default TelemedTable
