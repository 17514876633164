import { UserBriefInfosDto } from '@services/message.service'
import React from 'react'
import { getUserTitle } from '@utils/utils'

interface ActivityIndicatorProps {
  user: UserBriefInfosDto
}

export const ActivityIndicator: React.FC<ActivityIndicatorProps> = ({
  user,
}) => {
  // const icons = useRef({
  //   [UserState.ViewingMo]: <Icon fontSize="small" className="fas fa-info" />,
  //   [UserState.ViewingDose]: (
  //     <Icon fontSize="small" className="fas fa-syringe" />
  //   ),
  //   [UserState.EditingDose]: (
  //     <Icon fontSize="small" className="fas fa-syringe" />
  //   ),
  //   [UserState.ViewingProtocol]: (
  //     <Icon fontSize="small" className="fas fa-file-medical" />
  //   ),
  //   [UserState.ViewingQuestionnaire]: (
  //     <Icon fontSize="small" className="fas fa-question" />
  //   ),
  //   [UserState.ViewingReport]: <AssignmentIcon fontSize="medium" />,
  // }).current

  return (
    <div style={{}}>
      <span
        className={'activity-indicator ' + user.color}
        style={{
          backdropFilter: 'blur(2px)',
          backgroundColor: 'rgba(255,255,255,0)',
          padding: '1px 4px',
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          fontWeight: 'bolder',
          fontSize: 10,
          zIndex: 10,
          top: 0,
          left: 0,
        }}
      >
        {getUserTitle(user.role)}. {user.firstName} {user.lastName}
      </span>
    </div>
  )
}
