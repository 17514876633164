import { useAppDispatch } from '@hooks/reduxHooks'
import { Button } from '@mui/material'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { update } from '@state/thunks/studyThunk'
import { useIntl } from 'react-intl'

interface DischargeToggleProps {
  medicalOrderId: number
  dischargeConfirmed: boolean
}

export const DischargeToggleComponent: React.FC<DischargeToggleProps> = ({
  medicalOrderId,
  dischargeConfirmed,
}) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const onConfirm = () => {
    dispatch(
      update({
        id: medicalOrderId,
        body: { dischargeConfirmed: !dischargeConfirmed },
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(
          enqueueSnackbar({
            message: intl.formatMessage({ id: 'discharge-success' }),
            type: 'success',
          }),
        )
      })
  }

  return (
    <div>
      {dischargeConfirmed
        ? intl.formatMessage({
          id: 'remove-discharge-confirm',
        })
        : intl.formatMessage({ id: 'discharge-confirm' })}
      <div>
        <Button className="btn btn-primary" onClick={onConfirm}>
          {dischargeConfirmed
            ? intl.formatMessage({ id: 'remove-discharge' })
            : intl.formatMessage({ id: 'discharge' })}
        </Button>
      </div>
    </div>
  )
}
