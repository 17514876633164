import {
  Avatar,
  Conversation,
  ConversationHeader,
  ConversationList,
  MainContainer,
  Sidebar,
  Status,
} from '@chatscope/chat-ui-kit-react'
import ReportMessages from '@components/reporting/ReportMessages'
import { EntityName } from '@services/api'
import { MessageService, UserBriefInfosDto } from '@services/message.service'
import { Constants } from '@utils/Constants'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

interface OnlineUsersListProps {
  users: UserBriefInfosDto[]
  accountId: number
}

export const OnlineUsersList: React.FC<OnlineUsersListProps> = ({
  users,
  accountId,
}) => {
  const [selectedUser, setSelectedUser] = useState<UserBriefInfosDto | null>(
    null,
  )

  const intl = useIntl()

  const onlineUsersCount = useMemo(() => {
    let count = 0
    users.forEach((u) => {
      if (u.isConnected) {
        count++
      }
    })
    return count
  }, [users])

  return (
    <MainContainer>
      <Sidebar position="left" scrollable={true}>
        <ConversationList>
          {users.map((user) => (
            <Conversation
              onClick={() => setSelectedUser(user)}
              key={user.id}
              name={`${user.firstName} ${user.lastName}`}
              info={intl.formatMessage({ id: user.role || 'unknown' })}
            >
              <Avatar
                src={Constants.avatarPlaceHolder}
                status={user.isConnected ? 'available' : 'invisible'}
              />
            </Conversation>
          ))}
        </ConversationList>
      </Sidebar>
      {selectedUser && (
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <ConversationHeader>
            <ConversationHeader.Back onClick={() => setSelectedUser(null)} />
            <ConversationHeader.Content
              userName={selectedUser.firstName + ' ' + selectedUser.lastName}
              info={intl.formatMessage({
                id: selectedUser.role || 'unknown',
              })}
            />
          </ConversationHeader>
          <ReportMessages
            entityId={selectedUser.accountId}
            entityName={EntityName.account}
            roomId={MessageService.getPmId(selectedUser.accountId, accountId)}
          />
        </div>
      )}
    </MainContainer>
  )
}
