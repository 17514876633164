import { useAppSelector } from '@hooks/reduxHooks'
import { socketClient } from '@hooks/useWebsocket'
import { Member } from '@services/api'
import { MessageService } from '@services/message.service'
import { useEffect } from 'react'
import TelemedTable from '../../../components/tables/medical-order-table/telemedicine/TelemedTable'

const HomeTelemedDoctor = () => {
  const { accId, clientId } = useAppSelector(({ auth }) => ({
    accId: auth.account?.id,
    clientId: (auth.user as any as Member)?.clientId,
  }))

  useEffect(() => {
    if (accId && clientId) {
      socketClient.accountId = accId
      socketClient.roomId = MessageService.getRoomId(clientId)
    }
  }, [accId, clientId])

  return (
    <div
      style={{
        width: 'calc(100vw - 30px)',
      }}
    >
      <TelemedTable />
    </div>
  )
}

export default HomeTelemedDoctor
