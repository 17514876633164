import { createSlice } from '@reduxjs/toolkit'
import { Document, PatientDto } from '../../services/api'
import { findPatients, getPatientDocument } from '../thunks/patientThunk'

export interface patientState {
  patients: PatientDto[]
  patientDocuments: Document[]
}

const initialState: patientState = {
  patients: [],
  patientDocuments: [],
}

export const patientReducer = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setPatients: (state, action) => {
      state.patients = action.payload
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(findPatients.fulfilled, (state, action) => {
        state.patients = action.payload
      })
      .addCase(getPatientDocument.fulfilled, (state, action) => {
        state.patientDocuments = action.payload
      })
  },
})
export default patientReducer.reducer

export const { setPatients } = patientReducer.actions
