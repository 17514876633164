import {
  DialogTitle as DialogTitleBase,
  DialogTitleProps,
  IconButton,
  Theme,
  makeStyles,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import theme from '@utils/theme'

import { Text } from '../texts'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    height: 30,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}))

type CustomDialogTitleProps = DialogTitleProps & {
  title: string
  onClose?: () => void
  format?: boolean
}

export function DialogTitle({
  title,
  onClose,
  format = false,
  ...other
}: CustomDialogTitleProps) {
  const classes = useStyles()
  return (
    <DialogTitleBase disableTypography className={classes.root} {...other}>
      <Text
        variant="h6"
        text={title}
        format={format}
        style={{ fontSize: '1.2rem' }}
      />
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes?.closeButton}
          onClick={onClose}
          style={{
            color: theme.palette.secondary.main,
            zIndex: 1000,
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitleBase>
  )
}
