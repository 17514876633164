import React, { useEffect, useRef, useState } from 'react'
import { Grid, Tab, Tabs } from '@mui/material'
import ReportingEditor, { ReportingEditorRef } from './ReportingEditor'
import { MedicalOrderDto } from '../../services/api'
import { useSelector } from '../../state/store'
import { useDispatch } from 'react-redux'
import {
  findAllReportTemplates,
  findReports,
} from '../../state/thunks/reportingThunk'
import { getPatientDocument } from '../../state/thunks/patientThunk'
import PatientDocumentViewer from './PatientDocumentViewer'
import PatientSummary from './PatientSummary'
import DicomGallery from './DicomGallery'

export interface ReportPreviewRef {
  updateReportHtml: () => void
}

interface ReportingLayoutProps {
  study: MedicalOrderDto
}
const ReportingLayout: React.FC<ReportingLayoutProps> = ({ study }) => {
  const dispatch = useDispatch()

  const reports = useSelector((state) => state.reporting.reports)
  const [activeInfosTab, setActiveInfosTab] = useState(1)

  useEffect(() => {
    dispatch(
      findAllReportTemplates({
        examIds: [study.examId],
      }),
    )
  }, [])

  const editorRef = useRef<ReportingEditorRef | null>(null)

  useEffect(() => {
    if (study) {
      console.log('Patinet ID ' + study.patientId)
      dispatch(getPatientDocument({ id: study.patientId }))
    }
  }, [study])

  useEffect(() => {
    if (!study) {
      return
    }
    dispatch(findReports(study.id))
  }, [study])

  const handleInfosTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveInfosTab(newValue)
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '50vw',
          height: '98vh',
          padding: '12px',
          boxSizing: 'border-box',
          backgroundColor: 'white',
          borderRadius: '20px',
          margin: '10px',
        }}
      >
        <div
          style={{ height: '18%', overflowY: 'hidden', marginBottom: '10px' }}
        >
          <Tabs
            value={activeInfosTab}
            onChange={handleInfosTabChange}
            variant="fullWidth"
          >
            <Tab
              style={{
                borderRadius: '20px 0 0 0 ',
                backgroundColor: '#e2dff260',
              }}
              label="Infos admin"
              disabled
            />
            <Tab
              style={{
                backgroundColor: '#e2dff290',
                borderLeft: '4px solid #fff',
                borderRight: '4px solid #fff',
              }}
              label="Infos Patient"
            />
            <Tab
              style={{
                borderRadius: '0 20px 0 0',
                backgroundColor: '#e2dff290',
              }}
              label="Indication"
            />
          </Tabs>

          {activeInfosTab === 0 && <div>Infos admin</div>}
          {activeInfosTab === 1 && (
            <div
              style={{
                backgroundColor: '#e2dff220',
                borderRadius: '0 0 20px 20px',
                height: '100%',
              }}
            >
              <PatientSummary
                patient={study.patient}
                exam={study.exam}
                medicalOrder={study}
              />
            </div>
          )}
          {activeInfosTab === 2 && (
            <div
              style={{
                backgroundColor: '#e2dff220',
                borderRadius: '0 0 20px 20px',
                height: '100%',
                padding: '10px',
              }}
            >
              <p>{study.indication}</p>
            </div>
          )}
        </div>
        <div style={{ flexGrow: 1 }}>
          <PatientDocumentViewer />
        </div>
        <div style={{ flexShrink: 0, height: '12%', overflowX: 'auto' }}>
          <DicomGallery study={study} />
        </div>
      </div>

      <div
        style={{
          marginLeft: '46vw',
          width: '50vw',
          height: '100vh',
          overflowY: 'auto',
        }}
      >
        <ReportingEditor
          study={study}
          ref={editorRef}
          hiddenActions={[]}
          reports={reports}
        />
      </div>
    </div>
  )
}

export default ReportingLayout
