import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  Client,
  CreateMedicalOrderDto,
  CreateMedicalorderFlagDto,
  CreateShareDto,
  CreateShareWithGuestDto,
  FileUploadV2Dto,
  GedService,
  MedicalOrderService,
  Sorting,
  UpdateMedicalOrderDto,
  UpdateTelemedDto,
} from '@services/api'
import { UserBriefInfosDto } from '@services/message.service'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { TelemedState } from '@state/reducers/telemedReducer'
import { JoyService } from '../../services/joy.service'
import {
  addDownloadingStudyId,
  removeDownloadingStudyId,
  studyState,
} from '../reducers/studyReducer'

export const findAll = createAsyncThunk(
  'study/findAll',
  async (
    {
      page,
      limit,
      siteIds,
      sortBy,
    }: {
      page: number
      limit: number
      siteIds?: number[]
      sortBy?: Sorting
    },
    { dispatch, rejectWithValue, getState },
  ) => {
    try {
      const state = getState() as { study: studyState; telemed: TelemedState }
      const study = state.study
      const members = state.telemed.members
      const searchFilter = study.searchFilter
      const searchText = study.searchText

      const studies = await MedicalOrderService.findAll({
        siteIds,
        search: searchText,
        page,
        limit,
        sortBy,
        searchFilter,
      })

      console.log('study data', studies.datas.length, members)

      if (studies.datas.length > 0) {
        Object.values(members).forEach((member: UserBriefInfosDto) => {
          if (member.isConnected && member.stateInfos.medicalOrderId) {
            const index = studies.datas.findIndex(
              (s) => s.id === member.stateInfos.medicalOrderId,
            )
            console.log({ index, member, studies })
            if (index !== -1) {
              studies.datas[index].activityUser = member
            }
          }
        })
      }
      return studies
    } catch (e: any) {
      console.log({ e })
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
      return rejectWithValue(e.response.data)
    }
  },
)

export const findOne = createAsyncThunk(
  'study/findOne',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      return await MedicalOrderService.findOne({ id })
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
      return rejectWithValue(e.response.data)
    }
  },
)

export const update = createAsyncThunk(
  'study/update',
  async (
    { id, body }: { id: number; body: UpdateMedicalOrderDto },
    { dispatch },
  ) => {
    try {
      return await MedicalOrderService.update({ id, body })
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
      throw e
    }
  },
)

export const share = createAsyncThunk(
  'study/share',
  async (body: CreateShareDto, { dispatch, rejectWithValue }) => {
    try {
      const share = await MedicalOrderService.share({ body })
      dispatch(
        enqueueSnackbar({ type: 'success', message: 'study/share/fulfilled' }),
      )
      return share
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue('network-error')
    }
  },
)

export const shareWithGuest = createAsyncThunk(
  'study/shareWithGuest',
  async (body: CreateShareWithGuestDto, { dispatch, rejectWithValue }) => {
    try {
      const share = await MedicalOrderService.shareWithGuest({ body })
      dispatch(
        enqueueSnackbar({
          type: 'success',
          message: 'study/shareWithGuest/fulfilled',
        }),
      )
      return share
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue('network-error')
    }
  },
)

export const unshare = createAsyncThunk(
  'study/unshare',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      await MedicalOrderService.unshare({ id })
      dispatch(
        enqueueSnackbar({
          type: 'success',
          message: 'study/unshare/fulfilled',
        }),
      )
      return id
      // dispatch(loginFulfilled({ user, token }))
      // return fulfillWithValue('study/share/fulfilled')
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
      return rejectWithValue(e.response.data)
    }
  },
)

export const findAllShares = createAsyncThunk(
  'share/findAll',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      return await MedicalOrderService.findSharesByMedicalOrderId({ id })
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
      return rejectWithValue(e.response.data)
    }
  },
)

export const findOnePrescription = createAsyncThunk(
  'prescription/findOne',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const { url, filename } = await GedService.downloadFile(
        { id },
        {
          responseType: 'blob',
        },
      )
      return { url, filename, id }
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
      return rejectWithValue(e.response.data)
    }
  },
)

export const uploadFile = createAsyncThunk(
  'prescription/upload',
  async ({ id, files, type }: any, { dispatch, rejectWithValue }) => {
    try {
      const service = new JoyService()
      const response = await service.uploadFile(files, {
        entityId: id,
        fileType: type,
      })
      return response
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
      return rejectWithValue(e.response.data)
    }
  },
)

export const uploadFileV2 = createAsyncThunk(
  'file-upload',
  async (dto: FileUploadV2Dto, { dispatch, rejectWithValue }) => {
    try {
      const service = new JoyService()
      const response = await service.uploadFileV2(dto)
      return response
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
      return rejectWithValue(e.response.data)
    }
  },
)

export const uploadFiles = createAsyncThunk(
  'study/uploadFiles',
  async ({ id, files, type }: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await GedService.uploadFiles({
        relatedEntity: id,
        type,
        files,
      })
      return response
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
      return rejectWithValue(e.response.data)
    }
  },
)

export const deletePrescription = createAsyncThunk(
  'prescription/delete',
  async ({ id, prescriptionId }: any, { dispatch, rejectWithValue }) => {
    try {
      return MedicalOrderService.deletePrescription({
        id,
        prescriptionId,
      })
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
      return rejectWithValue(e.response.data)
    }
  },
)

export const findOneReport = createAsyncThunk(
  'report/findOne',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const { filename, data } = await GedService.downloadFile(
        { id },
        {
          responseType: 'blob',
        },
      )
      return {
        filename,
        url: URL.createObjectURL(new Blob([data], { type: 'application/pdf' })),
      }
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
      return rejectWithValue(e.response.data)
    }
  },
)

export const downloadImage = createAsyncThunk<any, any, any>(
  'study/download',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      dispatch(addDownloadingStudyId(id))
      const { data, filename } = await MedicalOrderService.download(
        { id },
        {
          responseType: 'blob',
        },
      )

      dispatch(removeDownloadingStudyId(id))
      return {
        url: URL.createObjectURL(new Blob([data], { type: 'application/zip' })),
        filename,
      }
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data)
      }
    }
  },
)

export const emitMedicalOrderViewedEvent = createAsyncThunk(
  'study/emitMedicalOrderViewed',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      await MedicalOrderService.createMedicalOrderViewedEvent({
        medicalOrderId: id,
      })
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
      rejectWithValue(e.response.data)
    }
  },
)

export const emitImagesViewedEvent = createAsyncThunk(
  'study/emitImagesViewed',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      await MedicalOrderService.createImagesViewedEvent({ medicalOrderId: id })
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
      rejectWithValue(e.response.data)
    }
  },
)

export const getToken = createAsyncThunk(
  'study/token',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const token = await MedicalOrderService.getToken()

      return token
    } catch (e: any) {
      console.log({ e })
      dispatch(
        enqueueSnackbar({ type: 'error', message: e?.response?.data?.message }),
      )
      rejectWithValue(e.response.data)
    }
  },
)

export const getAcquisitionProtocols = createAsyncThunk(
  'study/getAcquisitionProtocols',
  async ({ siteId }: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await MedicalOrderService.getAcquisitionProtocols({
        siteId,
      })

      return response
    } catch (e: any) {
      console.log({ e })
      dispatch(
        enqueueSnackbar({ type: 'error', message: e?.response?.data?.message }),
      )
      rejectWithValue(e.response.data)
    }
  },
)

export const getClient = createAsyncThunk(
  'study/getClient',
  async ({ id }: any, { rejectWithValue, dispatch }): Promise<Client> => {
    try {
      const response = await MedicalOrderService.getClient({
        id,
      })

      return response
    } catch (e: any) {
      console.log({ e })
      dispatch(
        enqueueSnackbar({ type: 'error', message: e?.response?.data?.message }),
      )
      throw rejectWithValue(e.response.data)
    }
  },
)

export const createStudy = createAsyncThunk(
  'study/create',
  async (data: CreateMedicalOrderDto, { rejectWithValue }) => {
    try {
      const res = await MedicalOrderService.create({ body: data })

      return res
    } catch (err) {
      rejectWithValue(err)
      throw err
    }
  },
)

export const updateStudy = createAsyncThunk(
  'study/updateTelemed',
  async (data: { id: number; dto: UpdateTelemedDto }, { rejectWithValue }) => {
    try {
      const res = await MedicalOrderService.updateTelemed({
        id: data.id,
        body: data.dto,
      })

      return res
    } catch (err) {
      rejectWithValue(err)
      throw err
    }
  },
)

export const setMedicalOrderFlags = createAsyncThunk(
  'study/setMedicalOrderFlags',
  async (
    data: { id: number; dto: CreateMedicalorderFlagDto[] },
    { rejectWithValue },
  ) => {
    try {
      const res = await MedicalOrderService.setMedicalOrderFlags({
        id: data.id,
        body: data.dto,
      })
      return res
    } catch (err) {
      rejectWithValue(err)
      throw err
    }
  },
)

export const findAppFlags = createAsyncThunk(
  'study/findAppFlags',
  async (_, { rejectWithValue }) => {
    try {
      const res = await MedicalOrderService.findAppFlags()
      return res
    } catch (err) {
      rejectWithValue(err)
      throw err
    }
  },
)

export const findMedicalOrderIssues = createAsyncThunk(
  'study/findMedicalOrderIssues',
  async (_, { rejectWithValue }) => {
    try {
      const res = await MedicalOrderService.findMedicalOrderIssues()
      return res
    } catch (err) {
      rejectWithValue(err)
      throw err
    }
  },
)
