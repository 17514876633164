import { formatTime } from '@utils/utils'
import React, { useEffect, useState } from 'react'

export interface TimerProps {
  start: Date
}

export const Timer: React.FC<TimerProps> = ({ start }) => {
  const [timeInSeconds, setTimeInSeconds] = useState(
    (new Date().getTime() - start.getTime()) / 1000,
  )

  useEffect(() => {
    const interval = setInterval(() => {
      const time = (new Date().getTime() - start.getTime()) / 1000
      setTimeInSeconds(time)
    }, 1000)

    return () => clearInterval(interval)
  }, [start])

  return <span style={{ fontSize: '12px' }}>{formatTime(timeInSeconds)}</span>
}
