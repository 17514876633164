import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import { Box, Button, Dialog, DialogContent, Grid } from '@mui/material'
import {
  Doctor,
  EntityName,
  MedicalOrderDto,
  ReportListDto,
  ReportTemplateDto,
} from '../../services/api'
import { DialogTitle } from '../titles'
import SaveTemplateForm from './SaveTemplateForm'
import SaveReportForm from './SaveReportForm'
import { useSelector } from '../../state/store'
import ToggleButton from '../buttons/ToggleButton'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import Draggable from 'react-draggable'
import ReportMessages from './ReportMessages'
import {
  ImagesPageLayoutNameEnum,
  ImagesPageProps,
} from '../../common/interfaces'
import { getClient } from '../../state/thunks/studyThunk'
import { useAppDispatch } from '../../hooks/reduxHooks'
import './ReportingEditor.css'
import { ReportPreviewRef } from './ReportingLayout'
import ReportEditorComponent, {
  RenderPreview,
  ReportEditorRef,
} from './ReportEditorComponent'
import ReportToolbar from './ReportToolbar'
import ReportPageV2 from './ReportPageV2'
import ReportPageLayoutSelector from './ReportPageLayoutSelector'

export interface ReportingEditorRef {
  useTemplate: (template: string[]) => void
  clearEditor: () => void
}

export function destroyQuill(quill: any, el: HTMLElement) {
  quill.theme.modules.toolbar.container.remove()
  quill.theme.modules.clipboard.container.remove()
  quill.theme.tooltip.root.remove()

  el.classList.forEach((cls) => {
    if (cls.startsWith('ql-')) {
      requestAnimationFrame(() => {
        el.classList.remove(cls)
        const parent = el.parentNode
        if (parent) {
          (parent as HTMLElement).classList.remove(
            'ql-container',
            'ql-snow',
            'ql-disabled',
          )
        }
      })
    }
  })
  el.innerHTML = quill.root.innerHTML
}

// export const A4Wrapper = ({ children }) => {
//   const [zoomLevel, setZoomLevel] = useState(1)
//
//   const adjustZoom = () => {
//     const a4WidthPx = 210 * (96 / 25.4)
//     const screenWidth = window.innerWidth * 0.67
//     const newZoomLevel = screenWidth / a4WidthPx
//     setZoomLevel(newZoomLevel)
//   }
//
//   useEffect(() => {
//     adjustZoom()
//     window.addEventListener('resize', adjustZoom)
//     return () => window.removeEventListener('resize', adjustZoom)
//   }, [])
//
//   return (
//     <div id="report-a4">
//       <div
//         id="report-content"
//         style={{
//           width: '210mm',
//           height: '297mm',
//           zoom: zoomLevel,
//           overflow: 'hidden',
//           backgroundColor: 'white',
//         }}
//       >
//         {children}
//       </div>
//     </div>
//   )
// }

interface ReportingEditorProps {
  study?: MedicalOrderDto
  hiddenActions: (
    | 'chat'
    | 'saveModel'
    | 'saveReport'
    | 'addPage'
    | 'edit'
    | 'patientInfos'
    | 'dicomGallery'
  )[]
  editingTemplate?: ReportTemplateDto
  reports: ReportListDto[]
}

const ReportingEditor = forwardRef<ReportingEditorRef, ReportingEditorProps>(
  ({ study, hiddenActions, editingTemplate, reports }, ref) => {
    const [reportHtml, setReportHtml] = useState<string[]>([])
    const reportEditorComponentRef = useRef<ReportEditorRef>(null)

    const dispatch = useAppDispatch()

    const [isSaveTemplateVisible, setIsSaveTemplateVisible] = useState(false)
    const [isSaveReportVisible, setIsSaveReportVisible] = useState(false)
    const [isEditMode, setIsEditMode] = useState(false)
    const [isChatVisible, setIsChatVisible] = useState(false)
    const [pages, setPages] = useState<ImagesPageProps[]>([])
    const [isReportPreview, setisReportPreview] = useState(false)
    const [isSelectPageLayoutVisible, setisSelectPageLayoutVisible] =
      useState(false)
    const [editingPageIndex, seteditingPageIndex] = useState(-1)

    const { selectedReport, client } = useSelector((state) => ({
      selectedReport: state.reporting.selectedReport,
      client: (state.auth.user as Doctor).client,
    }))

    const previewRef = useRef<ReportPreviewRef>(null)

    useEffect(() => {
      toggleToolbar(isEditMode || false)
    }, [isEditMode])

    const toolbarInsertionRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
      const toolbar = document.querySelector('.ql-toolbar.ql-snow')
      if (toolbar && toolbarInsertionRef.current) {
        toolbarInsertionRef.current.appendChild(toolbar)
      }
    }, [])

    useEffect(() => {
      if (selectedReport) {
        if (selectedReport.pages) {
          setPages(JSON.parse(selectedReport.pages))
        }
        const htmlPages = selectedReport.htmlPages
        setReportHtml(htmlPages)
        reportEditorComponentRef.current?.setPages(htmlPages)
      }
    }, [selectedReport])

    useEffect(() => {
      if (!study?.site.clientId) {
        return
      }
      const clientId = study.site.clientId
      dispatch(getClient({ id: clientId }))
    }, [study?.site.clientId])

    useImperativeHandle(ref, () => ({
      useTemplate(_htmlPages: string[]) {
        setReportHtml(_htmlPages)
        reportEditorComponentRef.current?.setPages(_htmlPages)
      },
      clearEditor() {
        clearEditor()
      },
    }))

    const clearEditor = () => {
      reportEditorComponentRef.current?.setPages([])
    }

    const quillEditorRef = reportEditorComponentRef.current?.getQuill()

    const toggleToolbar = (isVisible: boolean) => {
      const el = document.querySelector('.ql-toolbar')
      if (el) {
        if (isVisible) {
          el.classList.remove('ql-toolbar-hidden')
        } else {
          el.classList.add('ql-toolbar-hidden')
        }
      }
    }

    const onDeleteImagePage = (pageIndex: number) => {
      const confirm = window.confirm(
        'Voulez-vous vraiment supprimer cette page ?',
      )
      if (confirm) {
        const newPages = [...pages]
        const index = newPages.findIndex((p) => p.index === pageIndex)
        newPages.splice(index, 1)
        setPages(newPages)
      }
    }

    const onPagePropsChange = (
      pageIndex: number,
      newProps: ImagesPageProps,
    ) => {
      const newPages = [...pages]
      const index = newPages.findIndex((p) => p.index === pageIndex)
      newPages[index] = newProps
      setPages(newPages)
    }

    const onAddPage = (layoutName: ImagesPageLayoutNameEnum) => {
      previewRef.current?.updateReportHtml()
      const index = pages.length + 1
      setPages([
        ...pages,
        {
          index,
          imageUrls: [],
          layoutName,
        },
      ])
      setisSelectPageLayoutVisible(false)
      setTimeout(() => {
        const el = document.getElementById(`image-page-${index}`)
        console.log({ el })
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' })
        }
      }, 300)
    }

    const onUpdatePageLayout = (layoutName: ImagesPageLayoutNameEnum) => {
      const newPages = [...pages]
      const index = newPages.findIndex((p) => p.index === editingPageIndex)
      newPages[index].layoutName = layoutName
      setPages(newPages)
      setisSelectPageLayoutVisible(false)
      seteditingPageIndex(-1)
    }

    return (
      <>
        <Grid container>
          <Grid
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              paddingTop: '18px',
            }}
          >
            {client && (
              <>
                {study && !!quillEditorRef && (
                  <div
                    style={{
                      display: 'block',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'fixed',
                      top: 0,
                      width: '42%',
                      zIndex: 1000,
                      padding: '10px',
                    }}
                  >
                    <ReportToolbar
                      reports={reports}
                      medicalOrder={study}
                      togglePreviewMode={() => {
                        setisReportPreview(!isReportPreview)
                      }}
                      isPreviewMode={isReportPreview}
                      showVersionControl={!editingTemplate}
                      reportEditor={reportEditorComponentRef.current}
                    />
                  </div>
                )}
                {isReportPreview && (
                  <div
                    style={{
                      marginTop: '8% ',
                    }}
                  >
                    <RenderPreview
                      pages={reportHtml}
                      study={study}
                      client={client}
                    />
                  </div>
                )}

                <div
                  style={{
                    display: isReportPreview ? 'none' : 'block',
                    marginTop: '8% ',
                  }}
                >
                  <ReportEditorComponent
                    ref={reportEditorComponentRef}
                    client={client}
                    medicalOrder={study}
                    onPagesChange={(pages) => {
                      setReportHtml(pages)
                    }}
                  />
                </div>
              </>
            )}
          </Grid>
        </Grid>
        {/* #region ------------------------------------- ACTION BUTTONS  ------------------------------ */}
        <Grid
          style={{
            height: '100vh',
            padding: 10,
            position: 'fixed',
            right: 0,
            top: 0,
            borderLeft: '1px solid #CCC',
            backgroundColor: '#3f3f3f',
            boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.4)',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            {!hiddenActions.includes('chat') && (
              <ToggleButton
                isActive={isChatVisible}
                icon={<ChatBubbleOutlineIcon style={{ color: '#1EBC86' }} />}
                text="Messages"
                onClick={() => {
                  setIsChatVisible(!isChatVisible)
                }}
                style={{ marginTop: 12 }}
              />
            )}
            {!hiddenActions.includes('saveModel') && (
              <ToggleButton
                isActive={false}
                icon={
                  <i
                    style={{ color: '#1EBC86', fontSize: 20 }}
                    className="far fa-map"
                  ></i>
                }
                text="Enregistrer Modeles"
                style={{ marginTop: 12 }}
                onClick={() => {
                  previewRef.current?.updateReportHtml()
                  setIsSaveTemplateVisible(true)
                }}
              />
            )}
            {!hiddenActions.includes('saveReport') && (
              <ToggleButton
                isActive={false}
                icon={
                  <i
                    style={{ color: '#1EBC86', fontSize: 20 }}
                    className="far fa-clipboard"
                  ></i>
                }
                text="Enregistrer Compte rendu"
                style={{ marginTop: 12 }}
                onClick={() => {
                  previewRef.current?.updateReportHtml()
                  setIsSaveReportVisible(true)
                  setIsEditMode(false)
                }}
              />
            )}
            {!hiddenActions.includes('addPage') && (
              <ToggleButton
                isActive={false}
                icon={
                  <i
                    style={{ color: '#1EBC86', fontSize: 20 }}
                    className="far fa-plus-square"
                  ></i>
                }
                text="Ajouter page"
                style={{ marginTop: 12 }}
                onClick={() => {
                  setisSelectPageLayoutVisible(true)
                }}
              />
            )}
          </div>
        </Grid>
        {/* #endregion ----------------------------------------------------------------- */}
        {!!study && (
          <div
            style={{
              marginTop: 0,
            }}
          >
            {pages.map((p) => (
              <div
                key={p.index}
                style={{
                  marginBottom: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {client && (
                  <ReportPageV2
                    key={p.index}
                    pageIndex={p.index}
                    client={client}
                    medicalOrderId={study?.id || -1}
                    onDelete={onDeleteImagePage}
                    pageProps={p}
                    onPagePropsChange={onPagePropsChange}
                    onEditLayout={(index) => {
                      setisSelectPageLayoutVisible(true)
                      seteditingPageIndex(index)
                    }}
                    isSaving={isSaveReportVisible}
                  />
                )}
              </div>
            ))}
          </div>
        )}

        <Dialog
          open={isSaveTemplateVisible}
          onClose={() => {
            setIsSaveTemplateVisible(false)
          }}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          maxWidth="md"
          PaperProps={{
            style: {
              borderRadius: 20,
              width: '100%',
              background: '#E2E6F2',
            },
          }}
        >
          <Box
            sx={{
              backgroundColor: '#fff',
              width: '100%',
            }}
          >
            <DialogTitle title="Save template" />
            <DialogContent>
              <SaveTemplateForm
                onClose={() => setIsSaveTemplateVisible(false)}
                htmlPages={reportHtml}
                defaultModality={study?.exam?.modality}
                editingTemplate={editingTemplate}
              />
            </DialogContent>
          </Box>
        </Dialog>
        <Dialog
          open={isSaveReportVisible}
          onClose={() => {
            setIsSaveReportVisible(false)
          }}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          maxWidth="md"
          PaperProps={{
            style: {
              borderRadius: 20,
              width: '100%',
              background: '#E2E6F2',
            },
          }}
        >
          <Box
            sx={{
              backgroundColor: '#fff',
              width: '100%',
            }}
          >
            <DialogTitle title="Save template" />
            <DialogContent>
              {study && (
                <SaveReportForm
                  pages={pages}
                  medicalOrder={study}
                  reportPages={reportHtml}
                  onClose={() => {
                    setIsSaveReportVisible(false)
                  }}
                  selectedReport={selectedReport}
                />
              )}
            </DialogContent>
          </Box>
        </Dialog>
        {isChatVisible && (
          <div>
            <Draggable>
              <div
                style={{
                  width: '50vw',
                  height: '50vh',
                  position: 'fixed',
                  bottom: 40,
                  right: 0,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    backgroundColor: 'white',
                  }}
                >
                  <Button
                    color="error"
                    className="times"
                    onClick={() => setIsChatVisible(false)}
                    style={{
                      backgroundColor: '#FEF1F2',
                    }}
                  >
                    <i
                      style={{
                        fontSize: '1.5rem',
                      }}
                      className="fas fa-times"
                    ></i>
                  </Button>
                </div>
                <ReportMessages
                  entityId={study?.id || -1}
                  entityName={EntityName.medicalOrder}
                  roomId={'mo-' + study?.id}
                />
              </div>
            </Draggable>
          </div>
        )}
        <Dialog
          open={isSelectPageLayoutVisible}
          onClose={() => {
            setisSelectPageLayoutVisible(false)
          }}
        >
          <DialogTitle title="Layout" />
          <DialogContent>
            <div
              style={{
                margin: 'auto',
              }}
            >
              <ReportPageLayoutSelector
                onSelect={(layoutName) => {
                  console.log(layoutName, editingPageIndex)
                  if (editingPageIndex > -1) {
                    onUpdatePageLayout(layoutName)
                  } else {
                    onAddPage(layoutName)
                  }
                }}
              />
            </div>
          </DialogContent>
        </Dialog>
      </>
    )
  },
)

export default ReportingEditor
