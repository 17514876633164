import { useState } from 'react'
import ImageList from '@mui/material/ImageList'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
// import { useSelector } from '../../state/store'
// import StorageService from '../../services/storage'
import { useDrag } from 'react-dnd'

export default function DicomGallery({ study }) {
  const [open, setOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')
  // const [token, setToken] = useState('')

  // const documents = useSelector((state) => state.patients.patientDocuments)

  const handleOpen = (img: string) => {
    setSelectedImage(img)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  // const imageItems = useMemo(
  //   () =>
  //     documents.map((doc) => ({
  //       img: `${process.env.REACT_APP_STATIC_FILES_URL}/${doc.url}?token=${token}`,
  //       title: doc.gedFile.filename,
  //     })),
  //   [documents, token],
  // )

  // useEffect(() => {
  //   const _token = StorageService.getAuthToken() || ''
  //   setToken(_token)
  // }, [])

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flexWrap: 'nowrap',
          overflowX: 'scroll',
          height: '100%',
          backgroundColor: '#3f3f3f30',
        }}
      >
        {itemData.map((item) => (
          // <img
          //   key={item.img}
          //   srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
          //   src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
          //   alt={item.title}
          //   loading="lazy"
          //   onClick={() => handleOpen(item.img)}
          //   style={{ cursor: 'zoom-in', width: '100%', height: 'auto' }}
          //   draggable
          //   onDragStart={(ev) => {
          //     // transfer the image url
          //     ev.dataTransfer.setData(NativeTypes.TEXT, item.img)
          //   }}
          // />
          <DraggableImage
            key={item.img}
            img={item.img}
            title={item.title}
            onClick={() => handleOpen(item.img)}
          />
        ))}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="image-modal-title"
        aria-describedby="image-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <img
            src={`${selectedImage}`}
            alt="Selected"
            style={{ maxWidth: '100%', maxHeight: '90vh' }}
          />
        </Box>
      </Modal>
    </>
  )
}

const itemData = [
  {
    img: 'assets/img/dicom-sample/DicomSecondaryCapture-1.png',
    title: 'Image1',
  },
  {
    img: 'assets/img/dicom-sample/DicomSecondaryCapture-2.png',
    title: 'Image2',
  },
  {
    img: 'assets/img/dicom-sample/DicomSecondaryCapture-3.png',
    title: 'Image3',
  },
  {
    img: 'assets/img/dicom-sample/DicomSecondaryCapture-4.png',
    title: 'Image4',
  },
  {
    img: 'assets/img/dicom-sample/DicomSecondaryCapture-1.png',
    title: 'Image1',
  },
  {
    img: 'assets/img/dicom-sample/DicomSecondaryCapture-2.png',
    title: 'Image2',
  },
  {
    img: 'assets/img/dicom-sample/DicomSecondaryCapture-3.png',
    title: 'Image3',
  },
  {
    img: 'assets/img/dicom-sample/DicomSecondaryCapture-4.png',
    title: 'Image4',
  },
]

const DraggableImage = ({ img, title, onClick }) => {
  const [, drag] = useDrag(() => ({
    type: 'DraggableImage',
    item: { src: img }, // Assuming `img` is your image's data URL
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }))

  return (
    <img
      onClick={onClick}
      ref={drag}
      src={img}
      alt={title}
      loading="lazy"
      style={{
        cursor: 'zoom-in',
        width: '150px',
        height: 'auto',
        marginRight: '4px',
      }}
      draggable
      onDragStart={(event) =>
        event.dataTransfer.setData('text/plain', (event as any).target.src)
      }
    />
  )
}
