/* eslint-disable @typescript-eslint/no-unused-vars */
import { Doctor, MedicalOrderDto, Sorting } from '../../../services/api'
import {
  Pagination,
  setStudyPagination,
} from '../../../state/reducers/studyReducer'
import {
  DataGridPro,
  GridColDef,
  frFR,
  useGridApiRef,
  GridPinnedColumns,
  GridRowParams,
  GridPaginationModel,
} from '@mui/x-data-grid-pro'
import { makeStyles } from '@mui/styles'
import { useDispatch } from 'react-redux'
import { LinearProgress } from '@mui/material'
import { AppToolbar } from './AppToolbar'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { setUserConfiguration } from '../../../state/reducers/authReducer'
import { useSelector } from '../../../state/store'
import './MedicalOrderTable.css'

const LoaderIndicator = () => <LinearProgress color="secondary" />

export interface MedicalOrderTableProps {
  data: MedicalOrderDto[]
  pagination: Pagination
  onSortingChange: (sortings: Sorting[]) => void
  columns: GridColDef<MedicalOrderDto>[]
  density?: 'compact' | 'standard' | 'comfortable'
  tabs: React.ReactElement
  isTodayOnlyHidden?: boolean
  hideDatePicker?: boolean
  getRowClassName?: (medicalOrder: MedicalOrderDto) => string
  pinnedColumns?: GridPinnedColumns
  onRowClick?: (params: GridRowParams) => void
  selectedRow: string
}

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '30px',
          height: '30px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '30px',
          height: '30px',
        },
      },
    },
  },
})

const useStyles = makeStyles({
  div: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  iconBtn: {
    padding: 0,
  },
  root: {
    '& .MuiDataGrid-row': {
      '&:nth-of-type(odd)': {
        backgroundColor: '#e8eeff',
      },
      '&:nth-of-type(even)': {
        backgroundColor: '#fff',
      },
    },
    boxShadow: '0px 1px 2px  rgba(0,0,0,0.3)',
  },
})

export const MedicalOrderTable: React.FC<MedicalOrderTableProps> = ({
  data,
  pagination,
  onSortingChange,
  columns,
  density = 'standard',
  tabs,
  isTodayOnlyHidden,
  hideDatePicker,
  getRowClassName,
  pinnedColumns,
  onRowClick,
  selectedRow,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const apiRef = useGridApiRef()

  const { loading } = useSelector(({ study, loading, auth }) => ({
    account: auth?.account,
    user: auth?.user as Doctor | undefined,
    studies: study.studies,
    study: study.selected,
    searchText: study.searchText,
    searchFilter: study.searchFilter,
    loading: loading['study/findAll'],
    viewerToken: study.viewer.token,
    userConfiguration: auth.userConfiguration,
  }))

  const handleCurrentPageChange = (page: number) => {
    dispatch(
      setStudyPagination({
        page,
      }),
    )
  }

  const setPaginationModel = (model: GridPaginationModel) => {
    if (model.pageSize !== pagination.size) {
      dispatch(
        setStudyPagination({
          size: model.pageSize,
        }),
      )
    }

    if (model.page !== pagination.page) {
      handleCurrentPageChange(model.page)
    }
  }

  const saveTableState = () => {
    const tableState = apiRef.current.exportState()

    dispatch(
      setUserConfiguration({
        tableState,
      }),
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <div style={{ width: '100%' }}>
        {tabs}
        <DataGridPro
          apiRef={apiRef}
          className={classes.root}
          onRowClick={(params) => {
            if (onRowClick) {
              onRowClick(params)
            }
          }}
          getRowId={(row) => row.id}
          getRowClassName={(params) => {
            let className = ''
            if (params.id.toString() === selectedRow) {
              className += 'selectedRow '
            }
            if (getRowClassName) {
              className += getRowClassName(params.row)
            }
            return className
          }}
          sx={{
            borderRadius: '0px 10px 25px 25px',
          }}
          rows={data}
          columns={columns}
          onPaginationModelChange={setPaginationModel}
          rowCount={pagination.totalCount}
          paginationMode="server"
          loading={loading}
          rowsLoadingMode="server"
          style={{
            height: 'calc(100vh - 150px)',
            width: '100%',
            backgroundColor: 'white',
          }}
          pagination
          density={density}
          slots={{
            loadingOverlay: LoaderIndicator,
            toolbar: AppToolbar,
          }}
          slotProps={{
            toolbar: {
              isTodayOnlyHidden,
              hideDatePicker,
            },
          }}
          pinnedColumns={
            pinnedColumns || {
              right: ['Actions'],
            }
          }
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          onSortModelChange={(model) => {
            onSortingChange(
              model.length !== 0
                ? [
                  {
                    columnName: model[0].field,
                    direction: model[0].sort,
                  },
                ]
                : [],
            )
          }}
          onColumnOrderChange={saveTableState}
          onColumnWidthChange={() => {
            saveTableState()
          }}
          onColumnVisibilityModelChange={saveTableState}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </div>
    </ThemeProvider>
  )
}

export default MedicalOrderTable
