import {
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment, { Moment } from 'moment'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { AnswerDto, CreateAnswerDto, QuestionDto } from '../../services/api'

type QuestionFromProps = {
  question: QuestionDto
  onAnswerChange?: (answer: CreateAnswerDto) => void
  defaultAnswer?: AnswerDto
}

export default function QuestionFrom({
  question,
  onAnswerChange,
  defaultAnswer,
}: QuestionFromProps) {
  const [isSecondaryQuestionVisible, setIsSecondaryQuestionVisible] =
    useState(false)
  const [secondaryQuestionAnswers, setSecondaryQuestionAnswers] = useState<
    CreateAnswerDto[]
  >([])

  const { text, type, choices, childQuestions, askSecondaryIf } = question

  const { control, watch } = useForm<{
    answer?: string
    choice?: boolean
    date?: Date
  }>({
    defaultValues: {
      answer: defaultAnswer?.text,
    },
  })

  let answer = watch('answer')

  useEffect(() => {
    if (type === 'DATE') {
      if (!answer) {
        return
      }
      const date = answer as any
      console.log({ date })
      answer = moment(date).format('DD-MM-YYYY')
      console.log({ answerAfter: answer })
    }

    if (childQuestions?.length !== 0 && answer === askSecondaryIf) {
      setIsSecondaryQuestionVisible(true)
    } else {
      setIsSecondaryQuestionVisible(false)
    }
    if (onAnswerChange) {
      const obj: CreateAnswerDto = {
        questionId: question.id,
        text: answer?.toString(),
        secondaryAnswers: secondaryQuestionAnswers.map((answer) => ({
          questionId: answer.questionId,
          text: answer.text || '',
        })),
      }

      onAnswerChange(obj)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer, secondaryQuestionAnswers])

  const handleSecondaryAnswerChange = (obj: CreateAnswerDto) => {
    const answers: CreateAnswerDto[] = [...secondaryQuestionAnswers]
    const index = secondaryQuestionAnswers.findIndex(
      (answ) => answ.questionId === obj.questionId,
    )
    if (index !== -1) {
      answers[index].text = obj.text
    } else {
      answers.push(obj)
    }
    setSecondaryQuestionAnswers(answers)
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      style={{
        marginTop: '10px',
        marginBottom: '10px',
        width: '100%',
      }}
    >
      {type === 'OPEN' ? (
        <>
          <Grid item xs={12}>
            <Typography color="black" fontWeight="bold">
              {text}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="answer"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  id="answer"
                  name="answer"
                  variant="outlined"
                  style={{
                    backgroundColor: 'red',
                  }}
                />
              )}
            />
          </Grid>
        </>
      ) : type === 'CLOSED' ? (
        <>
          <Grid item xs={12}>
            <Typography color="black" fontWeight="bold" variant="body1">
              {text}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="answer"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  aria-labelledby="demo-radio-buttons-group-label"
                >
                  <FormControlLabel
                    value="TRUE"
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    value="FALSE"
                    control={<Radio />}
                    label="Non"
                  />
                </RadioGroup>
              )}
            />
          </Grid>
        </>
      ) : type === 'DATE' ? (
        <>
          <Grid item xs={12}>
            <Typography
              color="black"
              fontWeight="bold"
              variant="body1"
              style={{ marginBottom: '5px' }}
            >
              {text}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="answer"
              control={control}
              defaultValue={answer}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    format="DD-MM-YYYY"
                    value={field.value}
                    onChange={(date) => field.onChange(date)}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
        </>
      ) : type === 'CHOICES' ? (
        <>
          <Grid item xs={12}>
            <Typography color="black" fontWeight="bold" variant="body1">
              {text}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginBottom: '10px',
            }}
          >
            <Controller
              name="answer"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select {...field} variant="outlined" fullWidth>
                  {choices.map((c) => (
                    <MenuItem key={c.id} value={c.text}>
                      {c.text}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
        </>
      ) : type === 'MULTICHOICES' ? (
        <>
          <Grid item xs={12}>
            <Typography color="black" fontWeight="bold" variant="body1">
              {text}
            </Typography>
          </Grid>
          <Typography style={{ color: 'grey', fontSize: '12px' }}>
            Selectionner le ou les champs concernés
          </Typography>
          <Grid
            item
            xs={12}
            style={{
              marginBottom: '10px',
            }}
          >
            <Controller
              name="answer"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  variant="outlined"
                  multiple
                  placeholder=""
                  fullWidth
                >
                  {choices.map((c) => (
                    <MenuItem key={c.id} value={c.text}>
                      {c.text}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
        </>
      ) : (
        ''
      )}
      {isSecondaryQuestionVisible &&
        question.childQuestions?.map((q) => {
          const questionId = q.id
          const childQuestion = question.childQuestions.find(
            (q) => q.id === questionId,
          )

          if (!childQuestion) {
            return <></>
          }
          const type = childQuestion.type
          const childAnswer = defaultAnswer?.secondaryAnswer?.find(
            (ans) => ans.questionId === questionId,
          )
          let childAnswerValue: string | Moment | undefined

          if (childAnswer) {
            if (type === 'DATE') {
              childAnswerValue = moment(childAnswer.text, 'DD-MM-YYYY')
            } else {
              childAnswerValue = childAnswer.text
            }
          }
          return (
            <Grid item xs={12} style={{ marginBottom: '10px' }} key={q.id}>
              <QuestionFrom
                question={q}
                onAnswerChange={handleSecondaryAnswerChange}
                defaultAnswer={{ text: childAnswerValue } as AnswerDto}
              />
            </Grid>
          )
        })}
    </Grid>
  )
}
