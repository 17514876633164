import { MedicalOrderDto } from '@services/api'
import { capitalize } from 'lodash'
import moment from 'moment'

export const reportingVariables = [
  'patientFirstName',
  'patientLastName',
  'patientEmail',
  'patientPhone',
  'patientAddress',
  'patientCity',
  'patientZipCode',
  'patientBirthDate',
  'attendingDoctorFirstName',
  'attendingDoctorLastName',
  'referrerFirstName',
  'referrerLastName',
  'examCode',
  'examDate',
  'examLabel',
]

export const getValue = (key: string, study: MedicalOrderDto) => {
  if (!study) {
    return undefined
  }

  const values = {
    patientFirstName: capitalize(study.patient.firstName),
    patientLastName: capitalize(study.patient.lastName),
    patientBirthDate: moment(study.patient.birthDate).format('DD/MM/YYYY'),
    patientEmail: study.patient.email,
    patientPhone: study.patient.phoneNumber,
    patientAddress: study.patient.address,
    attendingDoctorFirstName: capitalize(study.attendingDoctor?.firstName),
    attendingDoctorLastName: capitalize(study.attendingDoctor?.lastName),
    referrerFirstName: study.referringDoctor?.firstName,
    referrerLastName: study.referringDoctor?.lastName,
    examCode: study.exam.code,
    examDate: moment(study.plannedDate).format('DD/MM/YYYY'),
    examLabel: study.exam.label,
    indication: study.indication,
  }
  return values[key]
}

export function parseReport(report: string, medicalOrder: MedicalOrderDto) {
  const regex = /{{(.*?)}}/g
  let regexpExec: RegExpExecArray | null
  let result = report
  while ((regexpExec = regex.exec(report)) !== null) {
    if (regexpExec.index === regex.lastIndex) {
      regex.lastIndex++
    }
    if (regexpExec[1]) {
      let value = getValue(regexpExec[1].trim(), medicalOrder)
      if (!value) {
        value = `{{ Non Renseigné - ${regexpExec[1]} }}`
      }
      result = result.replace(regexpExec[0], value)
    }
  }
  return result
}
