import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  ReportListDto,
  ReportParagraphDto,
  ReportTemplateDto,
  Report,
} from '../../services/api'
import {
  createParagraph,
  createReportTemplate,
  deleteParagraph,
  deleteReportTemplate,
  findAllReportTemplates,
  findParagraphs,
  findReports,
  forkReport,
  getReportDetails,
  updateParagraph,
  updateReport,
  updateReportTemplate,
} from '../thunks/reportingThunk'

interface ReportingState {
  templates: ReportTemplateDto[]
  paragraphs: ReportParagraphDto[]
  reports: ReportListDto[]
  selectedReport: Report | null
}

const initialState: ReportingState = {
  templates: [],
  paragraphs: [],
  reports: [],
  selectedReport: null,
}

export const reportingSlice = createSlice({
  name: 'reporting',
  initialState,
  reducers: {
    setSelectedReport: (state, action: PayloadAction<Report | null>) => {
      state.selectedReport = action.payload
    },
    setReports: (state, action: PayloadAction<ReportListDto[]>) => {
      state.reports = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createReportTemplate.fulfilled, (state, action) => {
      state.templates.push(action.payload)
    })
    builder.addCase(findAllReportTemplates.fulfilled, (state, action) => {
      state.templates = action.payload
    })
    builder.addCase(updateReportTemplate.fulfilled, (state, action) => {
      const index = state.templates.findIndex(
        (template) => template.id === action.payload.id,
      )
      state.templates[index] = action.payload
    })
    builder.addCase(deleteReportTemplate.fulfilled, (state, action) => {
      state.templates = state.templates.filter(
        (template) => template.id !== action.payload.id,
      )
    })
    builder.addCase(
      createParagraph.fulfilled,
      (state, action: PayloadAction<ReportParagraphDto>) => {
        state.paragraphs.push(action.payload)
      },
    )
    builder.addCase(
      updateParagraph.fulfilled,
      (state, action: PayloadAction<ReportParagraphDto>) => {
        state.paragraphs = state.paragraphs.map((paragraph) => {
          if (paragraph.id === action.payload.id) {
            return action.payload
          }
          return paragraph
        })
      },
    )
    builder.addCase(
      deleteParagraph.fulfilled,
      (state, action: PayloadAction<ReportParagraphDto>) => {
        state.paragraphs = state.paragraphs.filter(
          (paragraph) => paragraph.id !== action.payload.id,
        )
      },
    )
    builder.addCase(findParagraphs.fulfilled, (state, action) => {
      state.paragraphs = action.payload
    })
    builder.addCase(findReports.fulfilled, (state, action) => {
      state.reports = action.payload
    })
    builder.addCase(updateReport.fulfilled, (state, action) => {
      state.reports = state.reports.map((report) => {
        if (report.id === action.payload.id) {
          return {
            ...report,
          }
        }
        return report
      })
      state.selectedReport = action.payload
    })
    builder.addCase(forkReport.fulfilled, (state, action) => {
      state.selectedReport = action.payload
    })
    builder.addCase(getReportDetails.fulfilled, (state, action) => {
      state.selectedReport = action.payload
    })
  },
})

export const { setSelectedReport, setReports } = reportingSlice.actions

export default reportingSlice.reducer
