import { Button, PrimaryButton } from '@components/buttons'
import { yupResolver } from '@hookform/resolvers/yup'
import { Divider, Grid, Icon, makeStyles, Tab, Tabs } from '@material-ui/core'
import { useSelector } from '@state/store'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import BadgeCps from '../../assets/img/logos/Badge_Sombre_0.png'

import { Text } from '@components/texts'
import {
  CaptchaInput,
  PasswordInput,
  TextInput,
} from '@ether-healthcare/inputs'
import { useQuery } from '@hooks/useQuery'
import { LoginDto } from '@services/api'
import { OpenIdService } from '@services/openId'
import {
  getCaptcha,
  login,
  loginCPS,
  logout,
  sendEmailVerification,
} from '@state/thunks/authThunk'
import { loginSchema } from '@utils/schemas'
import theme from '@utils/theme'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthCard } from '../../components/cards/AuthCard'
import PhoneVerification from './PhoneVerification'

const isTelemed = !!process.env.REACT_APP_IS_TELEMED

const useStyles = makeStyles({
  grid: {
    padding: '0px 20px',
  },
  link: {
    cursor: 'pointer',
    color: '#fff',
    textDecoration: 'underline',
    marginLeft: '5px',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  tab: {
    borderRadius: '5px',
    backgroundColor: '#D0C7ED85',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  selectedTab: {
    borderRadius: '5px',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  buttonHover: {
    backgroundColor: '#fff',
    '&:hover': {
      //  darken on hover
      backgroundColor: '#D0C7ED',
    },
  },
})

export default function Login() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const query = useQuery()
  const { captcha, loading, account, isAuth } = useSelector(
    ({ auth, loading }) => ({
      captcha: auth?.captcha,
      loading: loading['auth/login'],
      account: auth?.account,
      isAuth: auth?.isAuth,
    }),
  )

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<LoginDto & { phoneNumber: string }>({
    defaultValues: {
      captcha: '',
      email: '',
      password: '',
    },
    resolver: yupResolver(loginSchema) as any,
  })

  const intl = useIntl()
  const location = useLocation()
  const [selectedTab, setSelectedTab] = useState(isTelemed ? 1 : -1)

  const notifications = useSelector(({ alert }) => alert.notifications)
  const [isPhoneInputShown, setIsPhoneInputShown] = useState(false)
  const [showLogin, setShowLogin] = useState(isTelemed)
  const [loginError, setLoginError] = useState('')
  const tabs = useRef({ PATIENT_TAB: 0, DOCTOR_TAB: 1 }).current

  const handleChange = (_event: any, newValue: number) => {
    setSelectedTab(newValue)
    if (newValue === tabs.PATIENT_TAB) {
      setShowLogin(false)
    } else {
      setShowLogin(true)
    }
  }

  const handleCPS = useCallback(() => {
    try {
      OpenIdService.auth()
    } catch (e) {
      console.error(e)
    }
  }, [])

  const handleBackToLogin = () => {
    dispatch(logout())
    navigate('/auth/login')
    setIsPhoneInputShown(false)
    getAuthCaptcha()
  }

  const onSubmit = (values: LoginDto) => {
    dispatch(
      login({
        body: {
          captcha: values.captcha,
          email: values.email.toLowerCase().trim(),
          password: values.password.trim(),
        },
        isFirstConnection: false,
      }),
    )
    reset({ captcha: '', email: values.email, password: values.password })
  }
  useEffect(() => {
    if (location && location.search) {
      const session_state = query.get('session_state')
      const code = query.get('code')

      if (code && session_state) {
        //TODO route to a page where we ask for doctor's email and password
        dispatch(loginCPS(code))
      } else if (
        query.get('verify-number') &&
        account &&
        isAuth &&
        (!account.phone || !account.isPhoneVerified)
      ) {
        setIsPhoneInputShown(true)
      }
    }
  }, [location])

  const getAuthCaptcha = () => {
    dispatch(getCaptcha())
  }

  useEffect(() => {
    getAuthCaptcha()
  }, [])

  useEffect(() => {
    if (notifications.length !== 0) {
      const lastNotification = notifications[notifications.length - 1]
      if (
        lastNotification.options.variant === 'error' &&
        lastNotification.message === 'Email not verified'
      ) {
        setLoginError('Email not verified')
      } else if (loginError !== '') {
        setLoginError('')
      }
    }
  }, [notifications])

  // const _onCloseError = () => {
  //   setLoginError('')
  // }
  const handleEmailVerification = () => {
    const email = getValues('email')
    dispatch(sendEmailVerification(email))
  }

  const Content = isPhoneInputShown ? (
    <PhoneVerification isUpdateButtonVisible />
  ) : (
    <>
      {!isTelemed && (
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          textColor="secondary"
          style={{
            margin: '0px 20px',
            marginBottom: '10px',
            borderRadius: '5px',
          }}
          variant="fullWidth"
          TabIndicatorProps={{
            style: { display: 'none' },
          }}
        >
          <Tab
            label={intl.formatMessage({ id: 'patientRole' })}
            style={{
              marginRight: '10px',
            }}
            className={
              selectedTab === tabs.PATIENT_TAB
                ? classes.selectedTab
                : classes.tab
            }
            value={tabs.PATIENT_TAB}
          />

          <Tab
            value={tabs.DOCTOR_TAB}
            label={intl.formatMessage({ id: 'doctorRole' })}
            className={
              selectedTab === tabs.DOCTOR_TAB
                ? classes.selectedTab
                : classes.tab
            }
          />
        </Tabs>
      )}

      {selectedTab !== -1 ? (
        <>
          {!showLogin && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.grid}
              style={{ margin: '20px 0' }}
            >
              <Grid item xs={12} style={{ margin: '20px 0' }}>
                <Button
                  text="haveAccount"
                  format
                  fullWidth
                  className={classes.buttonHover}
                  textColor="primary"
                  onClick={() => setShowLogin(true)}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  text="createAccount"
                  format
                  fullWidth
                  color="secondary"
                  onClick={() => navigate('/auth/first-connexion')}
                />
              </Grid>
            </Grid>
          )}
          {showLogin && (
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              className={classes.grid}
            >
              <Grid item xs={12} sm={12}>
                <TextInput
                  type="email"
                  name="email" // {...register('email')}
                  label="Email"
                  variant="filled"
                  errors={errors}
                  control={control}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <PasswordInput
                  name="password" // {...register('password')}
                  label="Password"
                  variant="filled"
                  errors={errors}
                  control={control}
                />
              </Grid>
              <Grid item container justifyContent="center" xs={12} sm={12}>
                <CaptchaInput
                  captcha={captcha}
                  getCaptcha={getAuthCaptcha}
                  errors={errors}
                  control={control}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <PrimaryButton
                  type="submit"
                  text="login"
                  loading={loading}
                  fullWidth
                  color="secondary"
                  format
                  style={{ marginTop: '10px' }}
                />
              </Grid>
              {!!loginError && (
                <Grid item xs={12} sm={12} style={{ display: 'flex' }}>
                  <Grid
                    item
                    xs={6}
                    style={{
                      backgroundColor: 'pink',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      padding: '5px',
                      borderRadius: '5px',
                    }}
                  >
                    <Icon
                      className="fas fa-times-circle"
                      style={{ color: 'red' }}
                    />
                    <Text
                      text="emailNotVerified"
                      style={{ color: 'red' }}
                      format
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                    }}
                  >
                    <Text
                      text="sendEmailVerification"
                      className={classes.link}
                      onClick={handleEmailVerification}
                      format
                    />
                  </Grid>
                </Grid>
              )}
              {selectedTab === tabs.DOCTOR_TAB && (
                <Grid item xs={12} sm={12}>
                  <Button
                    text="login-pro-sante-connect"
                    fullWidth
                    onClick={handleCPS}
                    style={{ background: '#000091', padding: 8 }}
                    startIcon={
                      <img
                        src={BadgeCps}
                        alt="pro-sante-connect"
                        style={{ width: 20 }}
                      />
                    }
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 10,
                }}
              >
                <Divider
                  variant="middle"
                  style={{ width: '95%', backgroundColor: '#8372b2' }}
                />
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  )
  const Actions = isPhoneInputShown ? (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      direction="row"
    >
      <Button
        text="back-to-login"
        format
        onClick={handleBackToLogin}
        color="secondary"
      />
    </Grid>
  ) : (
    <Grid
      container
      justifyContent="space-around"
      alignContent="center"
      alignItems="center"
      direction="row"
    >
      <Button link="/auth/forgotten-password" text="forgot-password" format />
      {selectedTab === tabs.PATIENT_TAB && (
        <Text
          onClick={() => setShowLogin(false)}
          text="back"
          format
          style={{
            cursor: 'pointer',
            color: '#fff',
            textTransform: 'uppercase',
          }}
        />
      )}
    </Grid>
  )
  return (
    <AuthCard>
      <AuthCard.Header title="login" />
      <AuthCard.Content>{Content}</AuthCard.Content>
      {showLogin && <AuthCard.Actions>{Actions}</AuthCard.Actions>}
    </AuthCard>
  )
}
