import type { CSSProperties, FC, MouseEventHandler } from 'react'
import type { DropTargetMonitor } from 'react-dnd'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'

export interface DroppableFileCardProps {
  onDrop: (item: { files: any[]; src: string }) => void
  style?: CSSProperties
  children: any
  isSelected?: boolean
  onClick?: MouseEventHandler<HTMLDivElement>
}

export const DroppableFileCard: FC<DroppableFileCardProps> = (props) => {
  const { onDrop } = props
  const [, drop] = useDrop(
    () => ({
      accept: [
        NativeTypes.FILE,
        NativeTypes.HTML,
        NativeTypes.TEXT,
        NativeTypes.URL,
        'DraggableImage',
      ],
      drop(item: { files: any[]; src: string }) {
        console.log({ item })
        if (onDrop) {
          onDrop(item)
        }
      },
      collect: (monitor: DropTargetMonitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }
      },
    }),
    [props],
  )

  return (
    <div
      ref={drop}
      style={{
        ...props.style,
        border: props.isSelected ? '1px solid black ' : '1px solid #CCC',
      }}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  )
}
