export interface CustomizablePage {
  index: number
  title: string
  rowInfos: RowInfos[]
}

export interface RowInfos {
  children?: ColumnInfos[]
}

export interface ColumnInfos {
  rotation?: number
  fileUrl?: string
  id: string
  title?: string
  isUploading?: boolean
}

export interface ImagesPageProps {
  layoutName: ImagesPageLayoutNameEnum
  index: number
  imageUrls: string[]
}

export enum ImagesPageLayoutNameEnum {
  Layout1 = 'Layout1',
  Layout2 = 'Layout2',
  Layout3 = 'Layout3',
}
