import { createAsyncThunk } from '@reduxjs/toolkit'
import { TelemdService } from '@services/telemed.service'

export const findMembers = createAsyncThunk(
  'telemed/findMembers',
  async (_, thunkApi) => {
    try {
      return await TelemdService.findUsers()
    } catch (err: any) {
      thunkApi.rejectWithValue(err)
      throw err
    }
  },
)
