import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  AcquisitionProtocolDto,
  AppFlagDto,
  Client,
  Document,
  MedicalOrderDto,
  StudyFilter,
} from '@services/api'
import { UserBriefInfosDto } from '@services/message.service'
import {
  createStudy,
  deletePrescription,
  findAll,
  findAppFlags,
  findMedicalOrderIssues,
  findOne,
  findOnePrescription,
  findOneReport,
  getAcquisitionProtocols,
  getClient,
  getToken,
  setMedicalOrderFlags,
  share,
  shareWithGuest,
  unshare,
  update,
  updateStudy,
  uploadFile,
} from '@state/thunks/studyThunk'
import { createReport, updateReport } from '../thunks/reportingThunk'

export interface Sorting {
  columnName: string
  direction: 'asc' | 'desc'
}

type File = {
  filename: string
  url: string
}

export interface Pagination {
  size: number
  page: number
  totalCount: number
}

export type studyState = {
  studies: {
    datas: MedicalOrderDto[]
  } & Pagination
  selected?: MedicalOrderDto
  sorting: Sorting[]
  documents: []
  reports: File[]
  currentPage: number
  pageSize: number
  selection: (string | number)[]
  columnOrder: string[]
  downloadingStudiesIds: number[]
  searchText: string
  searchFilter: StudyFilter
  viewer: {
    token: string | null
  }
  aquisitionProtocols: AcquisitionProtocolDto[]
  client: Client | null
  isCreateStudyOpen: boolean
  isMedicalOrderTypeOpen: boolean
  flags: AppFlagDto[]
  issues: {
    pendingDose: MedicalOrderDto[]
    pendingProtocol: MedicalOrderDto[]
  }
}

const initialState: studyState = {
  studies: {
    totalCount: 0,
    page: 0,
    datas: [],
    size: 100,
  },
  sorting: [{ columnName: 'date', direction: 'desc' }],
  currentPage: 0,
  pageSize: 10,
  documents: [],
  selection: [],
  columnOrder: [
    'plannedDate',
    'modality',
    'title',
    'referringDoctor',
    'open',
    'actions',
  ],
  reports: [],
  downloadingStudiesIds: [],
  searchText: '',
  searchFilter: {
    todayOnly: false,
    filterStatus: 'completed',
  },
  viewer: {
    token: null,
  },
  aquisitionProtocols: [],
  client: null,
  isCreateStudyOpen: false,
  isMedicalOrderTypeOpen: false,
  flags: [],
  issues: {
    pendingDose: [],
    pendingProtocol: [],
  },
}

const studySlice = createSlice({
  name: 'study',
  initialState,
  reducers: {
    removeStudies: (state) => {
      state.studies = initialState.studies
    },
    setSelected: (state, action: PayloadAction<MedicalOrderDto>) => {
      state.selected = action.payload
    },
    cleanStudy: (state) => {
      state.reports.forEach((report) => URL.revokeObjectURL(report.url))
      state.reports = []
      state.selected = undefined
    },
    cleanReport: (state) => {
      state.reports.forEach((report) => URL.revokeObjectURL(report.url))
      state.reports = []
    },
    setStudyPagination: (
      state,
      { payload }: PayloadAction<Partial<Pagination>>,
    ) => {
      state.studies = { ...state.studies, ...payload }
    },
    addDownloadingStudyId: (state, payload: PayloadAction<number>) => {
      state.downloadingStudiesIds.push(payload.payload)
    },
    removeDownloadingStudyId: (state, payload: PayloadAction<number>) => {
      const index = state.downloadingStudiesIds.findIndex(
        (el) => el === payload.payload,
      )
      if (index !== -1) {
        state.downloadingStudiesIds.splice(index, 1)
      }
    },
    setSearchText: (state, { payload }: PayloadAction<string>) => {
      state.searchText = payload
    },
    setSearchfilter: (state, { payload }: PayloadAction<StudyFilter>) => {
      state.searchFilter = { ...state.searchFilter, ...payload }
    },
    setIsCreateReportDialogOpen: (state, payload: PayloadAction<boolean>) => {
      state.isCreateStudyOpen = payload.payload
    },
    setIsMedicalOrderTypeOpen: (state, payload: PayloadAction<boolean>) => {
      state.isMedicalOrderTypeOpen = payload.payload
    },
    setMedicalOrderActivity: (
      state,
      { payload }: PayloadAction<UserBriefInfosDto>,
    ) => {
      const { medicalOrderId } = payload.stateInfos

      const index = state.studies.datas.findIndex(
        (s) => s.id === medicalOrderId,
      )

      if (index !== -1) {
        state.studies.datas[index].activityUser = payload
      } else {
        const mo = state.studies.datas.find(
          (s) => s.activityUser?.id === payload.id,
        )
        if (mo) {
          mo.activityUser = undefined
        }
      }
    },
    removeActivityFromMedicalOrder: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      const index = state.studies.datas.findIndex(
        (s) => s.activityUser?.id === payload,
      )

      if (index !== -1) {
        state.studies.datas[index].activityUser = undefined
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAll.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.studies.datas = payload.datas
        state.studies.totalCount = payload.totalCount

        const selected = state.selected
        if (selected) {
          const index = state.studies.datas.findIndex(
            (d) => d.id === selected.id,
          )
          if (index !== -1) {
            state.selected = state.studies.datas[index]
          }
        }
      })
      .addCase(findOne.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.selected = payload
      })
      .addCase(share.fulfilled, (state, { payload }: PayloadAction<any>) => {
        if (state.selected) {
          state.selected.shares = [...state.selected.shares, payload]
        }
      })
      .addCase(
        shareWithGuest.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if (state.selected) {
            state.selected.shares = [...state.selected.shares, payload]
          }
        },
      )
      .addCase(unshare.fulfilled, (state, { payload }: PayloadAction<any>) => {
        if (state.selected) {
          state.selected.shares = state.selected.shares.filter(
            (s) => s.id !== payload,
          )
        }
      })
      .addCase(
        uploadFile.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          const index = state.studies.datas.findIndex(
            (s) => s.id === payload.medicalOrderId,
          )
          if (index !== -1) {
            state.studies.datas[index].documents.push(payload)
          }
          if (state.selected) {
            state.selected.documents = [...state.selected.documents, payload]
          }
        },
      )
      .addCase(
        findOneReport.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.reports.push(payload)
        },
      )
      .addCase(
        findOnePrescription.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          for (let i = 0; i < state.studies.datas.length; i++) {
            const study = state.studies.datas[i]
            const docIndex = study.documents.findIndex(
              (d) => d.fileId === payload.id,
            )
            if (docIndex !== -1) {
              state.studies.datas[i].documents[docIndex].url = payload.url
              break
            }
          }

          const { selected } = state
          if (selected) {
            const index = selected.documents.findIndex(
              (doc) => doc.fileId === payload.id,
            )
            if (index !== -1) {
              selected.documents[index].url = payload.url
            }
            state.selected = selected
          }
        },
      )
      .addCase(
        deletePrescription.fulfilled,
        (state, { payload }: PayloadAction<Document>) => {
          const index = state.studies.datas.findIndex(
            (s) => s.id === payload.medicalOrderId,
          )
          if (index === -1) {
            return
          }
          const docs = state.studies.datas[index].documents.filter(
            (d) => d.id !== payload.id,
          )

          state.studies.datas[index].documents = docs

          if (state.selected) {
            state.selected.documents = docs
          }
        },
      )
      .addCase(getToken.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.viewer.token = payload
      })
      .addCase(
        getAcquisitionProtocols.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.aquisitionProtocols = payload
        },
      )
      .addCase(
        createReport.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          const index = state.studies.datas.findIndex(
            (s) => s.id === payload.medicalOrderId,
          )

          if (index !== -1) {
            state.studies.datas[index].reports.push(payload)
          }
        },
      )
      .addCase(
        updateReport.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          const index = state.studies.datas.findIndex(
            (s) => s.id === payload.medicalOrderId,
          )

          if (index !== -1) {
            const reportIndex = state.studies.datas[index].reports.findIndex(
              (r) => r.id === payload.id,
            )
            if (reportIndex !== -1) {
              const study = state.studies.datas[index]
              study.reports[reportIndex] = payload
              state.studies.datas[index] = study
            }
          }
        },
      )
      .addCase(createStudy.fulfilled, (state, { payload }) => {
        state.studies.datas.push(payload)
      })
      .addCase(
        getClient.fulfilled,
        (state, { payload }: PayloadAction<Client>) => {
          state.client = payload
        },
      )
      .addCase(update.fulfilled, (state, { payload }) => {
        const index = state.studies.datas.findIndex((s) => s.id === payload.id)
        if (index !== -1) {
          state.studies.datas[index] = payload
          state.selected = payload
        }
      })
      .addCase(setMedicalOrderFlags.fulfilled, (state, { payload }) => {
        const selectedStudy = state.selected
        const index = state.studies.datas.findIndex(
          (s) => s.id === selectedStudy?.id,
        )
        if (index !== -1) {
          state.studies.datas[index].flags = payload
          state.selected = state.studies.datas[index]
        }
      })
      .addCase(findAppFlags.fulfilled, (state, { payload }) => {
        state.flags = payload
      })
      .addCase(findMedicalOrderIssues.fulfilled, (state, { payload }) => {
        state.issues = payload
      })
      .addCase(updateStudy.fulfilled, (state, { payload }) => {
        const index = state.studies.datas.findIndex((s) => s.id === payload.id)
        if (index !== -1) {
          state.studies.datas[index] = payload
          state.selected = payload
        }
      })
  },
})

export const {
  removeStudies,
  setSelected,
  cleanStudy,
  cleanReport,
  setStudyPagination,
  addDownloadingStudyId,
  removeDownloadingStudyId,
  setSearchText,
  setSearchfilter,
  setIsCreateReportDialogOpen,
  setIsMedicalOrderTypeOpen,
  setMedicalOrderActivity,
  removeActivityFromMedicalOrder,
} = studySlice.actions

export default studySlice.reducer
