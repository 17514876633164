import { Typography } from '@mui/material'
import React from 'react'

interface Props {
  style?: React.CSSProperties
  onClick?: () => void
  icon: React.ReactNode
  text: string
  isActive: boolean
}
const ToggleButton: React.FC<Props> = ({
  style,
  onClick,
  icon,
  text,
  isActive,
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 40,
          height: 40,
          borderRadius: 10,
          border: `1px solid ${isActive ? '#ECFCF4' : '#CCC'}`,
          borderColor: isActive ? '#1EBC86' : '#CCC',
          backgroundColor: isActive ? '#ECFCF4' : '#FFF',
          ...style,
        }}
      >
        {icon}
      </div>
      <Typography
        marginTop={'2px'}
        fontSize={10}
        color={isActive ? '#1EBC86' : '#fff'}
        width={80}
        textAlign={'center'}
      >
        {text}
      </Typography>
    </div>
  )
}

export default ToggleButton
