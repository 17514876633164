import { Box, Grid } from '@mui/material'
import { Document, FileTypeEnum } from '../../services/api'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import AssignmentIcon from '@mui/icons-material/Assignment'
import React from 'react'
import StorageService from '../../services/storage'
import { useIntl } from 'react-intl'
import './DocumentList.css'

function RenderDocument({
  document,
  onClick,
}: {
  document: Document
  onClick: () => void
}) {
  const getIcon = (fileType: FileTypeEnum) => {
    switch (fileType) {
      case FileTypeEnum.REPORT:
        return <AssignmentIcon fontSize="medium" />
      default:
        return <AttachFileIcon />
    }
  }

  const intl = useIntl()
  return (
    <Grid
      container
      className="document-item"
      onClick={() => {
        onClick()
      }}
      alignItems={'center'}
    >
      <Grid item xs={2}>
        {getIcon(document.fileType)}
      </Grid>
      <Grid item xs={10}>
        <p
          style={{
            fontSize: '.8rem',
            fontWeight: 'bold',
          }}
        >
          {` ${document.gedFile.filename} -
          ${intl.formatMessage({
      id: 'enums.fileTypeEnum.' + document.fileType,
    })}`}
        </p>
      </Grid>
    </Grid>
  )
}

export interface Props {
  documents: Document[]
}
const DocumentList: React.FC<Props> = ({ documents }) => {
  const token = StorageService.getAuthToken()

  const onDocumentClick = (url: string) => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight

    const popupWidth = width * 0.7
    const popupHeight = height * 0.9

    const left = (width - popupWidth) / 2
    const top = (height - popupHeight) / 2

    window.open(
      url,
      'popup',
      `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`,
    )
  }
  return (
    <>
      <Box>
        {documents.map((document) => {
          return (
            <React.Fragment key={document.id}>
              {RenderDocument({
                document,
                onClick: () =>
                  onDocumentClick(
                    `${process.env.REACT_APP_STATIC_FILES_URL}${document.url}?token=${token}`,
                  ),
              })}
            </React.Fragment>
          )
        })}
      </Box>
    </>
  )
}

export default DocumentList
