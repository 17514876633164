import { MedicalOrderDto } from './api'
import StorageService from './storage'

export const generateToken = async (medicalOrder: MedicalOrderDto) => {
  try {
    const authToken = StorageService.getAuthToken()

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/viewer/token`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          studyInstanceUid: '1.2.250.1.74.20181107141000.1000078262359',
        }),
      },
    )
    const token = await response.text()
    return token
  } catch (e) {
    console.log(e)
  }
}

export const openViewer = async (
  { radiologyStudy }: MedicalOrderDto,
  token: string | null,
) => {
  try {
    if (!token) {
      alert('Unable to open the viewer, please try again another time')
      return
    }
    const url = `${process.env.REACT_APP_RADIO_VIEWER_URL}/launch?username=authtoken&password=${token}&action=view&StudyInstanceUID=${radiologyStudy?.uid}`

    const win = window.open(url, '_blank')
    if (win) {
      win.focus()
    } else {
      throw new Error('OPEN_VIEWER_ERROR')
    }
  } catch (e) {
    console.log(e)
  }
}

export const openViewerV2 = async (medicalOrder: MedicalOrderDto) => {
  const token = await generateToken(medicalOrder)
  if (!token) {
    return
  }

  const url = `${process.env.REACT_APP_OPENRAD_URL}/CMStudyLoader.aspx?tokenenc=${token}`
  window.open(url, '_blank')
}
