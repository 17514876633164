import { IRequestConfig, RoleDto, axios, getConfigs } from './api'

export class RoleService {
  private static basePath = process.env.REACT_APP_API_URL

  static getPermissionsByRole(
    roleName: string,
    siteIds: number[],
  ): Promise<RoleDto[]> {
    return new Promise((resolve, reject) => {
      const url = this.basePath + '/role'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        {},
      )
      configs.params = { roleName, siteIds }
      const data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}
