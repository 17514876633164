import React, { useEffect, useRef, useState } from 'react'
import Quill from 'quill'
import { destroyQuill } from './ReportingEditor'
import {
  createParagraph,
  deleteParagraph,
  findParagraphs,
  updateParagraph,
} from '../../state/thunks/reportingThunk'
import { useForm } from 'react-hook-form'
import {
  CreateParagraphDto,
  ModalityEnum,
  ReportParagraphDto,
} from '../../services/api'
import { createParagraphSchema } from '../../utils/schemas'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch } from '../../hooks/reduxHooks'
import { useSelector } from '../../state/store'
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import moment from 'moment'
import { TextInput } from '../inputs'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote', 'code-block'],

  [{ header: 1 }, { header: 2 }],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ direction: 'rtl' }],

  [{ size: ['small', false, 'large', 'huge'] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],

  ['clean'],
]

const ParagraphEditor: React.FC = () => {
  const intl = useIntl()
  const _quill = useRef<Quill>()
  const [editingParagraph, setEditingParagraph] = useState('')
  const [updatingParagraph, setUpdatingParagraph] =
    useState<ReportParagraphDto | null>(null)

  const paragraphs = useSelector((state) => state.reporting.paragraphs)
  const [isCreateOpen, setIsCreateOpen] = useState(false)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { setValue, control, watch, register } = useForm<CreateParagraphDto>({
    resolver: yupResolver(createParagraphSchema),
  })

  useEffect(() => {
    if (!editingParagraph) {
      return
    }
    if (_quill.current) {
      destroyQuill(_quill.current, _quill.current.root)
    }

    _quill.current = new Quill('#' + editingParagraph, {
      theme: 'snow',
      modules: {
        toolbar: toolbarOptions,
      },
    })

    if (updatingParagraph) {
      _quill.current.root.innerHTML = updatingParagraph.text
    }

    _quill.current.on('text-change', (delta) => {
      const placeholderRegex = /{{(.*?)}}/g

      const insert =
        delta.ops.length === 2 ? delta.ops[1].insert : delta.ops[0].insert
      if (
        delta.ops.length !== 0 &&
        insert &&
        (insert as string).match(placeholderRegex)
      ) {
        setTimeout(() => {
          _quill.current?.setSelection(_quill.current.getText().length - 1, 0)
        }, 20)
      }
    })
  }, [editingParagraph])

  useEffect(() => {
    dispatch(findParagraphs())
  }, [])

  const clearEditor = () => {
    if (_quill.current) {
      _quill.current.root.innerHTML = ''
      destroyQuill(_quill.current, _quill.current.root)
    }
    _quill.current = undefined
  }

  const clear = () => {
    setEditingParagraph('')
    setValue('title', '')
    clearEditor()
    if (isCreateOpen) {
      setIsCreateOpen(false)
    }
    if (updatingParagraph) {
      setUpdatingParagraph(null)
    }
  }

  const onSaveParagraph = (
    title: string,
    content: string,
    modality: string[],
  ) => {
    if (updatingParagraph) {
      dispatch(
        updateParagraph({
          id: updatingParagraph.id,
          data: { title: title, text: content, modality },
        }),
      ).then(() => {
        clear()
      })
    } else {
      dispatch(
        createParagraph({
          text: content,
          title,
          modality,
        }),
      ).then(() => {
        clear()
      })
    }
  }

  const onDeleteParagraph = (id: number) => {
    const confirm = window.confirm(
      'Etes-vous sur de vouloir supprimer ce paragraphe?',
    )
    if (!confirm) {
      return
    }
    dispatch(deleteParagraph(id))
  }

  const { title, modality } = watch()

  return (
    <div style={{ minHeight: '80vh', padding: '8px' }}>
      <IconButton
        onClick={() => {
          navigate(-1)
        }}
        style={{
          color: 'black',
          marginRight: '8px',
        }}
      >
        <i className="fas fa-chevron-circle-left"></i>
      </IconButton>
      <Button
        variant="outlined"
        style={{
          backgroundColor: 'black',
          color: 'white',
        }}
        onClick={() => {
          setIsCreateOpen(true)
          setEditingParagraph('create-editor')
        }}
      >
        + Ajouter
      </Button>
      <Grid container>
        {!isCreateOpen &&
          paragraphs.map((paragraph) => (
            <Grid item md={6} key={paragraph.id}>
              <Box
                sx={{
                  margin: '8px 0',
                  padding: '8px',
                  borderRadius: '8px',
                  boxShadow: 3,
                  border: '2px solid #000',
                  minHeight: '200px',
                  marginRight: '8px',
                }}
              >
                <div style={{}}>
                  {updatingParagraph?.id === paragraph.id ? (
                    <label>
                      Titre
                      <TextInput
                        style={{
                          fontWeight: 'bold',
                          fontSize: '116px',
                        }}
                        type="text"
                        name="title"
                        placeholder="Titre"
                        variant="outlined"
                        control={control}
                      />
                    </label>
                  ) : (
                    <Typography
                      variant="h6"
                      gutterBottom
                      mt={2}
                      fontWeight="bold"
                    >
                      {paragraph.title}
                    </Typography>
                  )}
                  <div style={{ marginTop: '8px' }}>
                    {updatingParagraph?.id === paragraph.id && (
                      <label>
                        Modalité
                        <Select
                          variant="outlined"
                          {...register('modality')}
                          value={modality || []}
                          multiple
                          style={{
                            width: '100%',
                            marginBottom: '8px',
                          }}
                        >
                          {Object.keys(ModalityEnum).map((key) => (
                            <MenuItem key={key} value={key}>
                              {intl.formatMessage({
                                id: 'enums.modality.' + ModalityEnum[key],
                              })}
                            </MenuItem>
                          ))}
                        </Select>
                      </label>
                    )}
                  </div>
                  <div>
                    {updatingParagraph?.id !== paragraph.id && (
                      <>
                        <IconButton
                          onClick={() => {
                            setUpdatingParagraph(paragraph)
                            const el = document.getElementById(
                              `paragraph${paragraph.id}`,
                            )

                            if (!el) {
                              return
                            }

                            setValue('title', paragraph.title)
                            setValue('modality', paragraph.modality)
                            setEditingParagraph(
                              'paragraph' + paragraph.id.toString(),
                            )
                          }}
                          style={{
                            fontSize: 15,
                          }}
                        >
                          <i className="fas fa-edit" color="error"></i>
                        </IconButton>
                        <IconButton
                          style={{
                            fontSize: 15,
                          }}
                          onClick={() => {
                            onDeleteParagraph(paragraph.id)
                          }}
                        >
                          <i className="fas fa-trash" color="error"></i>
                        </IconButton>
                      </>
                    )}
                  </div>
                </div>

                <Typography
                  fontSize={12}
                  color="#34495e"
                  style={{
                    transform: `translateY(${
                      updatingParagraph?.id === paragraph.id ? '0' : '-8px'
                    })`,
                  }}
                >
                  Créé le {moment(paragraph.createdAt).format('DD/MM/YYYY')}
                </Typography>
                {updatingParagraph?.id !== paragraph.id && (
                  <div
                    style={{
                      border: '1px solid #ccc',
                      padding: '6px',
                      borderRadius: '8px',
                      marginTop: '8px',
                    }}
                    className="ql-container ql-editor ql-snow"
                    dangerouslySetInnerHTML={{
                      __html: paragraph.text,
                    }}
                  ></div>
                )}
                <div
                  className="ql-container ql-editor ql-snow"
                  id={`paragraph${paragraph.id}`}
                  style={{
                    display:
                      updatingParagraph?.id === paragraph.id ? 'block' : 'none',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: paragraph.text,
                  }}
                ></div>
                {updatingParagraph?.id === paragraph.id && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '8px',
                    }}
                  >
                    <Button
                      variant="outlined"
                      style={{
                        marginRight: '8px',
                      }}
                      onClick={() => {
                        setUpdatingParagraph(null)
                        clear()
                      }}
                    >
                      Annuler
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        onSaveParagraph(
                          title,
                          _quill.current?.root.innerHTML || '',
                          modality,
                        )
                      }}
                    >
                      Sauvegarder
                    </Button>
                  </div>
                )}
              </Box>
            </Grid>
          ))}
      </Grid>

      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          pt: 2,
          px: 4,
          pb: 3,
          visibility: isCreateOpen ? 'visible' : 'hidden',
        }}
      >
        <h2 id="parent-modal-title">Créer un paragraph</h2>
        <p id="parent-modal-description">
          <label>
            Titre
            <TextInput
              variant="outlined"
              type="text"
              name="title"
              control={control}
            />
          </label>

          <div style={{ marginTop: '8px' }}>
            <label>Modalité</label>
            <div>
              <Select
                style={{
                  width: '100%',
                  marginBottom: '8px',
                }}
                variant="outlined"
                {...register('modality')}
                value={modality || []}
                multiple
              >
                {Object.keys(ModalityEnum).map((key) => (
                  <MenuItem key={key} value={key}>
                    {intl.formatMessage({
                      id: 'enums.modality.' + ModalityEnum[key],
                    })}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>

          <div style={{ marginTop: '8px' }}>
            <label>Contenu</label>
            <Box
              id="create-editor"
              className="editor ql-editor ql-snow"
              sx={{
                border: '2px solid #000',
                minHeight: '200px',
              }}
            ></Box>
          </div>
        </p>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant="outlined"
            style={{
              marginRight: '8px',
              borderColor: 'black',
              color: 'black',
            }}
            onClick={() => {
              setIsCreateOpen(false)
              clear()
            }}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onSaveParagraph(
                title,
                _quill.current?.root.innerHTML || '',
                modality,
              )
            }}
            style={{ backgroundColor: 'black' }}
          >
            Sauvegarder
          </Button>
        </Box>
      </Box>
    </div>
  )
}

export default ParagraphEditor
