import {
  Box,
  Button,
  FormControl,
  LinearProgress,
  MenuItem,
  Select,
} from '@mui/material'
import InputLabel from '@mui/material/InputLabel'

import { MedicalOrderDto, Report, ReportStatus } from '../../services/api'
import { createReport, updateReport } from '../../state/thunks/reportingThunk'
import { useState } from 'react'
import { ImagesPageProps } from '../../common/interfaces'
import { enqueueSnackbar } from '../../state/reducers/alertReducer'
import { useAppDispatch } from '../../hooks/reduxHooks'
import { parseReport } from '@utils/reporting-utils'

interface Props {
  reportPages: string[]
  medicalOrder: MedicalOrderDto
  pages: ImagesPageProps[]
  onClose: () => void
  selectedReport: Report | null
}
const SaveReportForm: React.FC<Props> = ({
  reportPages,
  medicalOrder,
  pages,
  onClose,
  selectedReport,
}) => {
  const [reportStatus, setReportStatus] = useState<string>(
    medicalOrder.reports.length !== 0
      ? medicalOrder.reports[0].status
      : ReportStatus.TODO,
  )
  const [isSaving, setIsSaving] = useState(false)
  const dispatch = useAppDispatch()

  const onSaveClick = () => {
    const pageElements = document.getElementsByClassName('report-page')

    const htmlPages: string[] = reportPages.map((p) =>
      parseReport(p, medicalOrder),
    )

    for (let i = 0; i < pageElements.length; i++) {
      htmlPages.push(pageElements[i].outerHTML)
    }

    setIsSaving(true)

    const cb = () => {
      setIsSaving(false)
      dispatch(
        enqueueSnackbar({
          message: 'Compte rendu enregistré avec succès',
          type: 'success',
        }),
      )
      onClose()
    }

    const imagePages = Array.from(
      document.getElementsByClassName('report-images-page'),
    ).map((p) => p.outerHTML)

    if (selectedReport) {
      dispatch(
        updateReport({
          id: selectedReport.id,
          dto: {
            medicalOrderId: medicalOrder.id,
            status: reportStatus,
            pages: JSON.stringify(pages),
            htmlPages,
            imagePages,
          },
        }),
      )
        .unwrap()
        .then(cb)
    } else {
      dispatch(
        createReport({
          dto: {
            medicalOrderId: medicalOrder.id,
            status: reportStatus,
            htmlPages: htmlPages,
            imagePages,
            pages: JSON.stringify(pages),
          },
        }),
      )
        .unwrap()
        .then(cb)
    }
  }
  return (
    <Box>
      <LinearProgress
        style={{ margin: '8px 0', visibility: isSaving ? 'visible' : 'hidden' }}
      />
      <FormControl fullWidth>
        <InputLabel id="reportStatus">Report Status</InputLabel>
        <Select
          value={reportStatus}
          onChange={(ev) => setReportStatus(ev.target.value)}
          fullWidth
          id="reportStatus"
        >
          <MenuItem value={ReportStatus.TODO}>Todo</MenuItem>
          <MenuItem value={ReportStatus.IN_PROGRESS}>In-progress</MenuItem>
          <MenuItem value={ReportStatus.PENDING_VALIDATION}>
            Pending Validation
          </MenuItem>
          <MenuItem value={ReportStatus.COMPLETED}>Completed</MenuItem>
        </Select>
      </FormControl>
      <Button
        style={{ marginTop: '8px', width: '100px' }}
        variant="contained"
        disabled={isSaving}
        onClick={onSaveClick}
      >
        Save
      </Button>
    </Box>
  )
}
export default SaveReportForm
