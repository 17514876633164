import { createAsyncThunk } from '@reduxjs/toolkit'
import { ExamService } from '../../services/exam.service'
import { CreateAnswerDto } from '../../services/api'

export const getExamAquisitionProtocol = createAsyncThunk(
  'exam/exam-aquisition-protocol',
  async (examId: number, { rejectWithValue }) => {
    try {
      return await ExamService.getExamAquisitionProtocol(examId)
    } catch (e: any) {
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const getExamsBySiteId = createAsyncThunk(
  'exam/exams-by-site-id',
  async (siteIds: number[], { rejectWithValue }) => {
    try {
      return await ExamService.getExamsBySiteId(siteIds)
    } catch (e: any) {
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const getExamQuestions = createAsyncThunk(
  'exam/exam-questions',
  async (examId: number, { rejectWithValue }) => {
    try {
      return await ExamService.getExamQuestions(examId)
    } catch (e: any) {
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const createAnswers = createAsyncThunk(
  'exam/create-answers',
  async (
    data: { medicalOrderId: number; answers: CreateAnswerDto[] },
    { rejectWithValue },
  ) => {
    try {
      return await ExamService.createAnswers(data.medicalOrderId, data.answers)
    } catch (e: any) {
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const findAnswers = createAsyncThunk(
  'exam/find-answers',
  async (medicalOrderId: number, { rejectWithValue }) => {
    try {
      return await ExamService.findAnswers(medicalOrderId)
    } catch (e: any) {
      return rejectWithValue(e.response.data.message)
    }
  },
)
