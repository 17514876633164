import { TooltipButton } from '@components/buttons'
import { Icon } from '@material-ui/core'
import { MedicalOrderDto } from '@services/api'

type FileUploadTooltipProps = {
  study: MedicalOrderDto
  onClick: () => void
  className?: string
}

export function FileUploadTooltip({
  study,
  onClick,
  className,
}: FileUploadTooltipProps) {
  return (
    <TooltipButton
      title="document"
      disable={study.status == 'canceled'}
      onClick={onClick}
      icon={
        <span className={'icon-wrapper ' + className}>
          <Icon
            fontSize="small"
            className="fas fa-paperclip"
            color={study.status == 'canceled' ? 'disabled' : 'primary'}
          />
          <span
            style={{
              position: 'absolute',
              top: '-0.1rem',
              right: '-0.2rem',
              width: '0.5rem',
              height: '0.5rem',
              borderRadius: '50%',
              backgroundColor: study.documents.length
                ? '#E91E63'
                : 'transparent',
            }}
          ></span>
        </span>
      }
    />
  )
}
