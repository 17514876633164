import {
  Select,
  MenuItem,
  Tooltip,
  TextField,
  IconButton,
  SelectChangeEvent,
  Dialog,
  DialogContent,
} from '@mui/material'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import {
  MedicalOrderDto,
  ReportListDto,
  ReportStatus,
  ReportTemplateDto,
} from '@services/api'
import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { forkReport, getReportDetails } from '@state/thunks/reportingThunk'
import { useSelector } from '@state/store'
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined'
import InsertParagraphComponent from './InsertParagraphComponent'
import TemplateListComponent from './TemplateListComponent'
import { ReportEditorRef } from './ReportEditorComponent'
import ArticleIcon from '@mui/icons-material/Article'
import hotkeys from 'hotkeys-js'
import { BASIC_TEMPLATE_NAME } from '@utils/Constants'

export const ReportStatusComponent: React.FC<{
  status: ReportStatus
}> = ({ status }) => {
  const intl = useIntl()
  const getClass = (status: ReportStatus) => {
    switch (status) {
      case ReportStatus.TODO:
        return 'report-todo'
      case ReportStatus.PENDING_VALIDATION:
        return 'report-pending-validation'
      case ReportStatus.IN_PROGRESS:
        return 'report-in-progress'
      case ReportStatus.COMPLETED:
        return 'report-completed'
      default:
        return ''
    }
  }
  return (
    <Tooltip title={intl.formatMessage({ id: status })}>
      <div className={'circle ' + getClass(status)}></div>
    </Tooltip>
  )
}

interface Props {
  medicalOrder: MedicalOrderDto
  reports: ReportListDto[]
  togglePreviewMode: () => void
  isPreviewMode: boolean
  showVersionControl: boolean
  reportEditor: ReportEditorRef | null
}

const ReportToolbar: React.FC<Props> = ({
  reports,
  togglePreviewMode,
  isPreviewMode,
  showVersionControl,
  medicalOrder,
  reportEditor,
}) => {
  const dispatch = useDispatch()

  const selectedReport = useSelector(
    ({ reporting }) => reporting.selectedReport,
  )
  const templates = useSelector(({ reporting }) => reporting.templates)

  const [versionName, setversionName] = useState('')
  const [isInsertParagraphOpen, setisInsertParagraphOpen] = useState(false)
  const [isTemplatesOpen, setisTemplatesOpen] = useState(false)

  const versions = useMemo(() => reports.map((r) => r.version), [reports])

  useEffect(() => {
    if (reports.length > 0 && !selectedReport) {
      const report = reports.find((r) => r.version === 'main')
      if (!report) {
        return
      }
      dispatch(getReportDetails(report.id))
    }
  }, [reports, selectedReport])

  useEffect(() => {
    hotkeys('ctrl+shift+m', (e) => {
      e.preventDefault()
      e.stopPropagation()
      setisInsertParagraphOpen(true)
    })
    hotkeys('ctrl+shift+t', (e) => {
      e.preventDefault()
      e.stopPropagation()
      setisTemplatesOpen(true)
    })

    return () => {
      hotkeys.unbind('ctrl+shift+m')
      hotkeys.unbind('ctrl+shift+t')
    }
  }, [])

  useEffect(() => {
    console.log(versions, templates)
    if (versions.length === 0 && templates.length !== 0) {
      const template = templates.find(
        (t) => t.name.trim() === BASIC_TEMPLATE_NAME,
      )
      if (!template) {
        return
      }
      reportEditor?.setPages(template.htmlPages)
    }
  }, [versions, templates])

  const handleCreateVersion = () => {
    if (!selectedReport) {
      return
    }
    dispatch(
      forkReport({
        id: selectedReport.id,
        dto: { version: versionName },
      }),
    )
  }

  const handleReportChange = (ev: SelectChangeEvent<number>) => {
    const reportId = ev.target.value as number
    dispatch(getReportDetails(reportId))
  }

  const handleUseTemplate = (template: ReportTemplateDto) => {
    const confirmed = window.confirm(
      'Voulez-vous vraiment utiliser ce template ?',
    )
    if (confirmed) {
      reportEditor?.setPages(template.htmlPages)
      setisTemplatesOpen(false)
    }
  }

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          padding: '10px',
          borderRadius: '10px',
          backgroundColor: 'rgba(255, 255, 255, 0.1)', // Semi-transparent background
          backdropFilter: 'blur(10px)',
        }}
      >
        {showVersionControl && (
          <>
            <Select
              size="small"
              style={{ width: '160px' }}
              onChange={handleReportChange}
              value={selectedReport?.id || ''}
            >
              {reports.map((report, index) => (
                <MenuItem
                  key={index}
                  value={report.id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  V.{report.version}
                  <span
                    style={{
                      fontSize: '10px',
                      marginLeft: '5px',
                      lineHeight: '0px',
                      marginTop: '3px',
                    }}
                  >
                    ({report.createdBy?.firstName} {report.createdBy?.lastName})
                  </span>
                </MenuItem>
              ))}
            </Select>
            <TextField
              style={{ marginLeft: '10px' }}
              size="small"
              placeholder="Creer une version"
              value={versionName}
              onChange={(ev) => setversionName(ev.target.value)}
            />
            <IconButton onClick={handleCreateVersion}>
              <ControlPointIcon />
            </IconButton>
          </>
        )}
        {selectedReport && (
          <ReportStatusComponent status={selectedReport.status} />
        )}
        <IconButton
          style={{
            marginLeft: '8px',
            border: '1px solid #2ecc71',
          }}
          className={(isPreviewMode ? 'active-circle-btn' : '') + ' circle-btn'}
          onClick={togglePreviewMode}
        >
          <MenuBookOutlinedIcon
            fontSize="small"
            style={{
              color: '#2ecc71',
            }}
          />
        </IconButton>
        <IconButton
          className="circle-btn"
          style={{ border: '1px solid black', marginLeft: '4px' }}
          onClick={() => setisInsertParagraphOpen(true)}
        >
          <i
            className="fa fa-paragraph "
            style={{
              fontSize: '18px',
              color: 'black',
            }}
            aria-hidden="true"
          ></i>
        </IconButton>
        <IconButton
          className="circle-button"
          style={{
            border: '1px solid black ',
            marginLeft: '4px',
          }}
          onClick={() => setisTemplatesOpen(true)}
        >
          <ArticleIcon style={{ color: 'black', fontSize: '16px' }} />
        </IconButton>
      </div>

      {reportEditor && (
        <InsertParagraphComponent
          isOpen={isInsertParagraphOpen}
          modality={medicalOrder.exam.modality}
          reportEditor={reportEditor}
          onClose={() => setisInsertParagraphOpen(false)}
        />
      )}
      <Dialog open={isTemplatesOpen} onClose={() => setisTemplatesOpen(false)}>
        <DialogContent>
          <TemplateListComponent
            templates={templates}
            onUseTemplate={handleUseTemplate}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export default ReportToolbar
