
import { Text } from '@components/texts'
import { Box, Grid, Icon, Input, makeStyles } from '@material-ui/core'
import { DirectoryService, RppsDto } from '@services/api'
import theme from '@utils/theme'
import { debounce } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { LinearProgress } from '@mui/material'
import { Control, DeepMap, FieldError, UseFormSetValue } from 'react-hook-form'

export interface RppsData {
  firstName: string
  lastName: string
  rppsNumber: string
}

type RppsAutocompleteByNameInputProps = {
  errors?: DeepMap<any, FieldError>
  name: string
  control: Control<any>
  defaultValue?: RppsData
  setValue?: UseFormSetValue<any>
}

const useStyles = makeStyles({
  capitalize: {
    textTransform: 'capitalize',
    fontWeight: 500,
  },
  searchResult: {
    minHeight: 300,
  },
  backgroundOverlay: {
    backgroundColor: 'transparent',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'block',
    zIndex: 2,
  },
})
export function RppsAutocompleteByNameInput({
  setValue,
  name,
  defaultValue,
}: RppsAutocompleteByNameInputProps) {
  const [options, setOptions] = useState<RppsDto[]>([])
  const [selectedRpps, setSelectedRpps] = useState<RppsData | null>(
    defaultValue || null,
  )
  const [inputValue, setInputValue] = useState('')
  const [inputDisplay, setInputDisplay] = useState('')
  const [page, setPage] = useState(0)
  const limit = useRef(20).current
  const scrollPos = useRef(0)
  const listInnerRef = useRef<any>()
  const [isFetching, setIsFetching] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const classes = useStyles()
  const autocompleteByName = async (name: string) => {
    try {
      setIsFetching(true)
      const doctors = await DirectoryService.autocompleteByName({
        name,
        page,
        limit,
      })
      const rppsMap: { [key: string]: RppsDto } = {}
      doctors.forEach((_doctor) => {
        if (rppsMap[_doctor.rppsNumber]) {
          if (!rppsMap[_doctor.rppsNumber].zipCode.includes(_doctor.zipCode)) {
            rppsMap[_doctor.rppsNumber].zipCode += ', ' + _doctor.zipCode
          }
        } else {
          rppsMap[_doctor.rppsNumber] = _doctor
        }
      })
      if (page !== 0) {
        setOptions([...options, ...Object.values(rppsMap)])
      } else {
        setOptions(Object.values(rppsMap))
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsFetching(false)
      setIsLoading(false)
    }
  }

  const fetch = useMemo(
    () =>
      debounce((inputValue: string) => {
        autocompleteByName(inputValue.toLowerCase())
      }, 500),
    [page, limit],
  )
  useEffect(() => {
    if (!listInnerRef.current) {
      return
    }
    setTimeout(() => {
      listInnerRef.current?.scrollTo(0, scrollPos.current)
    }, 10)
  }, [scrollPos.current, options.length])

  useEffect(() => {
    if (inputValue === '') {
      setOptions([])
      return undefined
    }
    if (!isFetching) {
      fetch(inputValue)
    }
  }, [inputValue, fetch])

  const _handleScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight >= scrollHeight - 0.6) {
        setIsLoading(true)
        setPage(page + 1)
        scrollPos.current = scrollTop
      }
    }
  }

  const _handleRppsClick = (rpps: RppsDto) => {
    setInputDisplay(
      rpps.firstName.charAt(0).toUpperCase() +
        rpps.firstName.slice(1) +
        ' ' +
        rpps.lastName.charAt(0).toUpperCase() +
        rpps.lastName.slice(1),
    )

    if (setValue) {
      setValue(name || 'doctor', rpps)
      setSelectedRpps(rpps)
    }
    setOptions([])
    setPage(0)

    // Prevent fetch again because of scroll size bug
    setIsFetching(true)
    setTimeout(() => {
      setIsFetching(false)
    }, 600)
  }

  const _renderRpps = (rpps: RppsDto) => {
    return (
      <Box
        key={rpps.id}
        p={0}
        m={0}
        justifyContent="center"
        onClick={() => _handleRppsClick(rpps)}
      >
        <Text
          text={
            rpps.firstName.charAt(0).toUpperCase() +
            rpps.firstName.slice(1) +
            ' ' +
            rpps.lastName.charAt(0).toUpperCase() +
            rpps.lastName.slice(1) +
            ' '
          }
          variant="h6"
          style={{ color: theme.palette.primary.main }}
        />
        <Text
          style={{ textTransform: 'capitalize', fontWeight: 500 }}
          text={
            rpps.professionLabel !== 'Médecin'
              ? rpps.professionLabel
              : rpps.skillLabel.trim()
                ? rpps.skillLabel
                : rpps.categoryLabel
          }
        />
        <Text
          text={`N°RPPS: ${rpps.rppsNumber}`}
          variant="body1"
          style={{ fontSize: '14px', color: '#8e8e8e' }}
        />
        <Text
          text={
            rpps.zipCode.trim() != ''
              ? `Lieux d'activité: (${rpps.zipCode})`
              : ''
          }
          variant="body1"
          style={{ fontSize: '14px', color: '#8e8e8e' }}
        />
      </Box>
    )
  }
  const _handleInputChange = (value: string) => {
    setIsLoading(true)

    setInputValue(value)
    setInputDisplay(value)
    if (setValue) {
      setValue(name || 'doctor', null)
    }
    setOptions([])
    if (page !== 0) {
      setPage(0)
    }
  }
  const _handleOverlayClick = () => {
    setOptions([])
    if (page !== 0) {
      setPage(0)
    }
  }
  const _isAutocompleteVisible = options.length !== 0
  const _handleRppsUnselect = () => {
    setSelectedRpps(null)
    setInputValue('')
    setInputDisplay('')
    if (setValue) {
      setValue(name || 'doctor', undefined)
    }
  }
  return (
    <Grid container style={{ position: 'relative' }}>
      {_isAutocompleteVisible && (
        <div
          id="backgroundOverlay"
          onClick={_handleOverlayClick}
          className={classes.backgroundOverlay}
        ></div>
      )}

      <Input
        style={{ width: '100%', zIndex: 10 }}
        value={inputDisplay}
        onChange={(ev) => _handleInputChange(ev.target.value)}
        placeholder="Rechercher un médecin (Prénom + Nom + dept.)"
        disabled={!!selectedRpps}
      />
      {selectedRpps && (
        <div
          style={{
            backgroundColor: 'rgba(85, 239, 196, 0.2)',
            borderRadius: 14,
            padding: 8,
            paddingRight: 24,
            fontSize: 12,
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            position: 'relative',
            marginTop: 4,
          }}
        >
          {selectedRpps.firstName?.charAt(0).toUpperCase() +
            selectedRpps.firstName.slice(1) +
            ' ' +
            selectedRpps.lastName.charAt(0).toUpperCase() +
            selectedRpps.lastName.slice(1) +
            ' (RPPS: ' +
            selectedRpps.rppsNumber +
            ')'}

          <Icon
            onClick={_handleRppsUnselect}
            style={{
              fontSize: 14,
              position: 'absolute',
              right: 4,
              top: 9,
              color: theme.palette.secondary.main,
              cursor: 'pointer',
            }}
            className="fas fa-times"
          />
        </div>
      )}
      <div
        onScroll={() => _handleScroll()}
        ref={listInnerRef}
        style={{
          top: 30,
          height: 210,
          display: !isLoading && options.length === 0 ? 'none' : 'block',
          width: '100%',
          overflowY: 'scroll',
          position: 'absolute',
          zIndex: 30,
          backgroundColor: 'white',
          cursor: 'pointer',
          border: '0px 1px 1px 1px solid lightgray',
          padding: 2,
        }}
      >
        {options.map((op) => _renderRpps(op))}
        {isLoading && <LinearProgress color="secondary" />}
      </div>
    </Grid>
  )
}
