import { OnlineUsersList } from '@components/telemed/OnlineUsersList'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { useWebsocket } from '@hooks/useWebsocket'
import { MessageNamesEnum } from '@services/api'
import { UserBriefInfosDto } from '@services/message.service'
import { removeActivityFromMedicalOrder } from '@state/reducers/studyReducer'
import { disconnectUser, updateUser } from '@state/reducers/telemedReducer'
import { useMemo } from 'react'

const Messaging = () => {
  const dispatch = useAppDispatch()

  const { account, members } = useAppSelector(({ auth, telemed }) => ({
    account: auth?.account,
    members: telemed.members,
  }))

  const onUserDisconnected = (payload: number) => {
    dispatch(disconnectUser(payload))
    dispatch(removeActivityFromMedicalOrder(payload))
  }

  const onUserConnected = (payload: UserBriefInfosDto) => {
    dispatch(
      updateUser({
        ...payload,
        isConnected: true,
      }),
    )
  }

  useWebsocket({
    handlers: [
      {
        messageName: MessageNamesEnum.UserConnected,
        fn: onUserConnected,
      },
      {
        messageName: MessageNamesEnum.UserDisconnected,
        fn: onUserDisconnected,
      },
    ],
  })

  const _members = useMemo(() => {
    return Object.values(members)
  }, [members])

  return (
    <div
      style={{ height: '100vh', paddingTop: '50px', backgroundColor: 'white' }}
    >
      {account && <OnlineUsersList users={_members} accountId={account.id} />}
    </div>
  )
}

export default Messaging
