import { UserState } from '@services/telemed.service'

export const Constants = {
  avatarPlaceHolder:
    'https://www.northernmed.com/wp-content/uploads/2020/12/headshot-placeholder-01-2048x2048.jpg',
}

export const reportLayouts = {
  layout1: [
    {
      children: [
        {
          id: '7176a098-b3d6-4b9c-9647-740fa572039b',
        },
      ],
    },
    {
      children: [
        {
          id: '44f1b79b-d393-4452-8f74-d50d5ce24e6a',
        },
        {
          id: '8e8ed0f9-f482-4246-9387-0fef08c35d62',
        },
        {
          id: '3ebc06b7-a3e2-403f-903a-22d90661c874',
        },
      ],
    },
  ],
  layout2: [
    {
      children: [
        {
          id: '7176a098-b3d6-4b9c-9647-740fa572039b',
        },
        {
          id: '6a4c87a3-f001-4786-962e-8aa77eb77a0a',
        },
        {
          id: '8c8d9da7-a565-4b22-80af-6fa78f8d09df',
        },
      ],
    },
  ],
  layout3: [
    {
      children: [
        {
          id: '7176a098-b3d6-4b9c-9647-740fa572039b',
        },
        {
          id: '6a4c87a3-f001-4786-962e-8aa77eb77a0a',
        },
      ],
    },
  ],
  layout4: [
    {
      children: [
        {
          id: '7176a098-b3d6-4b9c-9647-740fa572039b',
        },
        {
          id: '6a4c87a3-f001-4786-962e-8aa77eb77a0a',
        },
      ],
    },
    {
      children: [
        {
          id: '1cf4742a-457f-4c9d-bb8f-a2bda69999f8',
        },
        {
          id: '45edda4d-c8b9-4d56-938c-555d1b321459',
        },
      ],
    },
  ],
  layout5: [
    {
      children: [
        {
          id: '7176a098-b3d6-4b9c-9647-740fa572039b',
        },
        {
          id: '6a4c87a3-f001-4786-962e-8aa77eb77a0a',
        },
        {
          id: '50b9beb4-b294-4f71-9641-606141f82985',
        },
      ],
    },
    {
      children: [
        {
          id: '1cf4742a-457f-4c9d-bb8f-a2bda69999f8',
        },
        {
          id: '45edda4d-c8b9-4d56-938c-555d1b321459',
        },
        {
          id: '988e838b-4557-4021-9ff8-cdf8f695edad',
        },
      ],
    },
  ],
}

export enum AppActionsEnum {
  VIEW_REPORT = 'VIEW_REPORT',
  EDIT_REPORT = 'EDIT_REPORT',
}

export enum ActionButtonNameEnum {
  StudyDialog = 'StudyDialog',
  FileUploadDialog = 'FileUploadDialog',
  ManageAquisitionProtocolDialog = 'ManageAquisitionProtocolDialog',
  ManageReportDialog = 'ManageReportDialog',
  DoseDialog = 'DoseDialog',
  StudyDataDialog = 'StudyDataDialog',
  QuestionnaireDialog = 'QuestionnaireDialog',
}

export const ActionToStateMap: Map<string, UserState[]> = new Map([
  [ActionButtonNameEnum.StudyDialog, [UserState.ViewingMo]],
  [
    ActionButtonNameEnum.DoseDialog,
    [UserState.ViewingDose, UserState.EditingDose],
  ],
  [
    ActionButtonNameEnum.ManageReportDialog,
    [UserState.ViewingReport, UserState.EditingReport],
  ],
  [
    ActionButtonNameEnum.ManageAquisitionProtocolDialog,
    [UserState.EditingProtocol, UserState.ViewingProtocol],
  ],
  [
    ActionButtonNameEnum.StudyDataDialog,
    [UserState.ViewingStudyData, UserState.EditingStudyData],
  ],
  [ActionButtonNameEnum.QuestionnaireDialog, [UserState.ViewingQuestionnaire]],
  [ActionButtonNameEnum.FileUploadDialog, [UserState.ViewingFiles]],
])

export const BASIC_TEMPLATE_NAME = 'Basic'
