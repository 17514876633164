import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { Dialog, DialogContent, Popover } from '@mui/material'
import { ReportParagraphDto } from '@services/api'
import { findParagraphs } from '@state/thunks/reportingThunk'
import React, { useEffect, useState } from 'react'
import { ReportEditorRef } from './ReportEditorComponent'

const renderParagraph = (
  paragraph: ReportParagraphDto,
  onMouseEnter: (el: HTMLElement, paragraph: ReportParagraphDto) => void,
  onMouseLeave: () => void,
  onClick: (paragraph: ReportParagraphDto) => void,
) => {
  return (
    <div
      onMouseEnter={(ev) => onMouseEnter(ev.currentTarget, paragraph)}
      onMouseLeave={onMouseLeave}
      key={paragraph.id}
      style={{
        marginBottom: '16px',
        padding: '8px',
        border: '1px solid #d3d3d3',
        borderRadius: '4px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      onClick={() => onClick(paragraph)}
    >
      <div>
        <p style={{ fontSize: '17px', fontWeight: 'bold' }}>
          {paragraph.title}
        </p>
        <p style={{ fontSize: '11px', display: 'block', marginTop: '-12px' }}>
          Cree le {new Date(paragraph.createdAt).toLocaleDateString()}
        </p>
      </div>
      <span style={{ fontSize: '13px' }}>
        Cree par: {paragraph.createdBy.firstName} {paragraph.createdBy.lastName}
      </span>
    </div>
  )
}

interface Props {
  modality: string
  reportEditor: ReportEditorRef
  onClose: () => void
  isOpen: boolean
}

const InsertParagraphComponent: React.FC<Props> = ({
  modality,
  isOpen,
  onClose,
  reportEditor,
}) => {
  const dispatch = useAppDispatch()

  const [anchorEl, setanchorEl] = useState<HTMLElement | null>(null)
  const [paragraphMouseOver, setparagraphMouseOver] =
    useState<ReportParagraphDto | null>(null)

  const paragraphs = useAppSelector(({ reporting }) => reporting.paragraphs)

  useEffect(() => {
    dispatch(findParagraphs(modality))
  }, [])

  const onParagraphMouseEnter = (
    el: HTMLElement,
    paragraph: ReportParagraphDto,
  ) => {
    setanchorEl(el)
    setparagraphMouseOver(paragraph)
  }

  const onParagraphMouseLeave = () => {
    setanchorEl(null)
    setparagraphMouseOver(null)
  }

  const handleParagraphClick = (paragraph: ReportParagraphDto) => {
    if (!reportEditor) {
      return
    }
    const quill = reportEditor.getQuill()
    if (!quill) {
      return
    }

    const range = quill.getSelection()
    if (!range) {
      return
    }
    const html = `${paragraph.text}`
    quill.clipboard.dangerouslyPasteHTML(range.index, html)
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <div style={{ marginTop: '24px' }}>
          {paragraphs.map((paragraph) =>
            renderParagraph(
              paragraph,
              onParagraphMouseEnter,
              onParagraphMouseLeave,
              handleParagraphClick,
            ),
          )}
        </div>
        <Popover
          sx={{
            pointerEvents: 'none',
          }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disableRestoreFocus
        >
          <div
            className="editor ql-container ql-snow ql-editor"
            dangerouslySetInnerHTML={{
              __html: paragraphMouseOver?.text || '',
            }}
          ></div>
        </Popover>
      </DialogContent>
    </Dialog>
  )
}

export default InsertParagraphComponent
