interface Props {
  siteName: string
  siteAddress: string
  sitePhone: string
  siteEmail: string
}
export const ReportFooter: React.FC<Props> = ({
  siteName,
  siteAddress,
  sitePhone,
  siteEmail,
}) => {
  return (
    <div className="report-footer">
      <div style={{ width: '21cm', marginTop: '20px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
            backgroundColor: 'white',
            fontSize: '14px',
            color: '#333',
            border: '1px solid #CCC',
          }}
        >
          <div style={{ textAlign: 'center', width: '100%' }}>
            <p
              style={{
                margin: 0,
                lineHeight: 1.5,
                color: '#4c8caf',
                fontWeight: 600,
              }}
            >
              {siteName}
            </p>
            <p style={{ margin: 0, lineHeight: 1.5 }}>{siteAddress}</p>
            <p style={{ margin: 0, lineHeight: 1.5 }}>
              Tél: {sitePhone} - @: {siteEmail}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
