import React, { useEffect, useRef, useState } from 'react'
import { Button, Grid, IconButton, Typography } from '@mui/material'
import { Doctor, ReportTemplateDto } from '../../services/api'
import { useSelector } from '../../state/store'
import { useDispatch } from 'react-redux'
import {
  deleteReportTemplate,
  findAllReportTemplates,
} from '../../state/thunks/reportingThunk'
import TemplateCard from '../cards/TemplateCard'
import ReportingEditor, { ReportingEditorRef } from './ReportingEditor'
import ReportingDataPanel from './ReportingDataPanel'
import { Link, useNavigate } from 'react-router-dom'

const ReportTemplates: React.FC = () => {
  const dispatch = useDispatch()
  const templates = useSelector((state) => state.reporting.templates)
  const user = useSelector((state) => state.auth.user)

  const editorRef = useRef<ReportingEditorRef | null>(null)

  const [isCreateOpen, setIsCreateOpen] = useState(false)
  const [editingTemplate, setEditingTemplate] = useState<
    ReportTemplateDto | undefined
  >(undefined)

  const navigate = useNavigate()

  useEffect(() => {
    dispatch(
      findAllReportTemplates({
        createdById: user?.accountId,
      }),
    )
  }, [])

  const useTemplate = (template: ReportTemplateDto) => {
    if (editorRef.current) {
      editorRef.current.useTemplate(template.htmlPages)
      console.log('pages set!')
    } else {
      console.error('Editor ref is not available')
    }
  }

  const onDeleteTemplate = (template: ReportTemplateDto) => {
    const confirm = window.confirm(
      'Voulez-vous vraiment supprimer ce template ?',
    )
    if (confirm) {
      dispatch(deleteReportTemplate(template.id))
    }
  }

  return (
    <div>
      <Grid
        spacing={1}
        style={{
          marginTop: 80,
          padding: 20,
          backgroundColor: 'white',
        }}
      >
        {!isCreateOpen && (
          <>
            <IconButton
              style={{ color: 'black' }}
              onClick={() => {
                navigate('/')
              }}
            >
              <i className="fas fa-chevron-circle-left"></i>
            </IconButton>
            <Button
              variant="outlined"
              style={{
                fontWeight: 'bold',
                color: 'black',
                borderColor: 'black',
              }}
              onClick={() => {
                setIsCreateOpen(true)
              }}
            >
              <i
                style={{ marginRight: '4px', fontWeight: 'bold' }}
                className="fa fa-plus"
              ></i>{' '}
              Ajouter
            </Button>

            <Link
              style={{
                color: '#2980b9',
                fontWeight: 'bold',
                textDecoration: 'underline',
              }}
              to={'/report-templates/paragraphs'}
            >
              Paragraphes
            </Link>
            <Grid container>
              {templates.map((template) => (
                <Grid
                  key={template.id}
                  item
                  md={3}
                  xs={12}
                  style={{
                    marginLeft: 6,
                  }}
                >
                  <TemplateCard
                    onDeleteClick={onDeleteTemplate}
                    onEdit={(t) => {
                      setEditingTemplate(t)
                      useTemplate(t)
                      setIsCreateOpen(true)
                    }}
                    template={template}
                    client={(user as Doctor).client}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
        <Grid
          container
          style={{
            paddingTop: 40,
            display: isCreateOpen ? 'flex' : 'none',
            position: 'relative',
          }}
        >
          <IconButton
            style={{ color: 'black', position: 'absolute', top: 0, left: 0 }}
            onClick={() => {
              setIsCreateOpen(false)
              setEditingTemplate(undefined)
              editorRef.current?.clearEditor()
            }}
          >
            <i className="fas fa-chevron-circle-left"></i>
          </IconButton>

          <Grid
            item
            sm={3}
            style={{
              paddingTop: 40,
              paddingLeft: 20,
            }}
            id="report-left"
          >
            <Typography variant="h6">Variables</Typography>
            <ReportingDataPanel />
          </Grid>
          <Grid item xs={9} style={{ overflowY: 'scroll' }}>
            <ReportingEditor
              editingTemplate={editingTemplate}
              ref={editorRef}
              hiddenActions={['addPage', 'chat', 'saveReport', 'patientInfos']}
              reports={[]}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default ReportTemplates
