import React, { useEffect, useState } from 'react'
import {
  CreateReportTemplateDto,
  ModalityEnum,
  ReportTemplateDto,
  UpdateReportTemplateDto,
} from '../../services/api'
import { useForm } from 'react-hook-form'
import {
  FormControl,
  InputLabel,
  FormHelperText,
  Grid,
  TextField,
  Button,
  Typography,
  Tabs,
  Box,
  Tab,
  Select,
  MenuItem,
  FormLabel,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { createReportTemplateSchema } from '../../utils/schemas'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../state/store'
import {
  createReportTemplate,
  updateReportTemplate,
} from '../../state/thunks/reportingThunk'
import { useIntl } from 'react-intl'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

interface SaveTemplateFormProps {
  htmlPages: string[]
  defaultModality?: ModalityEnum
  onClose: () => void
  editingTemplate?: ReportTemplateDto
}

const SaveTemplateForm: React.FC<SaveTemplateFormProps> = ({
  htmlPages,
  defaultModality,
  onClose,
  editingTemplate,
}) => {
  console.log({ editingTemplate })
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CreateReportTemplateDto>({
    resolver: yupResolver(createReportTemplateSchema),
    defaultValues: {
      modality: editingTemplate?.modality || defaultModality,
    },
  })

  const updateForm = useForm<UpdateReportTemplateDto>({
    defaultValues: {},
  })

  const dispatch = useDispatch()
  const intl = useIntl()

  const { templates } = useSelector((state) => ({
    templates: state.reporting.templates,
  }))

  const [selectedTemplate, setSelectedTemplate] = useState<number>(
    editingTemplate?.id || -1,
  )

  const [currentTab, setCurrentTab] = React.useState(
    editingTemplate !== undefined ? 1 : 0,
  )

  const saveTemplate = (values: CreateReportTemplateDto) => {
    const dto: CreateReportTemplateDto = {
      modality: values.modality,
      name: values.name,
      htmlPages,
    }

    dispatch(createReportTemplate(dto))
    onClose()
  }

  useEffect(() => {
    // dispatch(getExamsBySiteId(study.siteId))
  }, [])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const onSaveToExistingTemplate = () => {}

  const handleUpdateSubmit = (values: UpdateReportTemplateDto) => {
    if (selectedTemplate === -1) {
      return
    }
    const isConfirmed = confirm('Etes-vous sure de vouloir modifier ce modèle?')
    if (isConfirmed) {
      dispatch(
        updateReportTemplate({
          id: selectedTemplate,
          dto: {
            modality: values.modality,
            htmlPages,
          },
        }),
      )
      onClose()
    }
  }

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        aria-label="basic tabs example"
      >
        <Tab
          disabled={!!editingTemplate}
          label="As new template"
          {...a11yProps(0)}
        />
        <Tab label="Existing template" {...a11yProps(1)} />
      </Tabs>
      <CustomTabPanel value={currentTab} index={0}>
        <form onSubmit={handleSubmit(saveTemplate)}>
          <Grid container rowGap={2}>
            <Grid sm={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="name">Nom</InputLabel>
                <TextField
                  {...register('name')}
                  error={!!errors.name}
                  variant="outlined"
                />
                <FormHelperText error={!!errors.name}>
                  {errors.name}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid sm={12}>
              <FormControl fullWidth>
                <Select
                  {...register('modality')}
                  defaultValue={defaultModality}
                >
                  {Object.keys(ModalityEnum).map((key) => (
                    <MenuItem key={key} value={key}>
                      {intl.formatMessage({
                        id: 'enums.modality.' + ModalityEnum[key],
                      })}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!!errors.modality}>
                  {errors.modality?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid>
              <Button variant="contained" type="submit">
                <Typography variant="body1">Enregistrer</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </CustomTabPanel>
      <CustomTabPanel value={currentTab} index={1}>
        <form onSubmit={updateForm.handleSubmit(handleUpdateSubmit)}>
          <Grid container rowGap={2}>
            <Grid item sm={12}>
              <FormControl fullWidth>
                <FormLabel htmlFor="templates">Templates</FormLabel>
                <Select
                  value={selectedTemplate}
                  onChange={(ev) =>
                    setSelectedTemplate(ev.target.value as number)
                  }
                  id="templates"
                >
                  {templates.map((t) => (
                    <MenuItem key={t.id} value={t.id}>
                      {t.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid sm={12}>
              <FormControl fullWidth>
                <Select {...register('modality')}>
                  {Object.keys(ModalityEnum).map((key) => (
                    <MenuItem key={key} value={key}>
                      {intl.formatMessage({
                        id: 'enums.modality.' + ModalityEnum[key],
                      })}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!!errors.modality}>
                  {errors.modality}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid sm={12}>
              <Button
                type="submit"
                variant="contained"
                onClick={onSaveToExistingTemplate}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </CustomTabPanel>
    </>
  )
}

export default SaveTemplateForm
