import { combineReducers } from '@reduxjs/toolkit'

import alertReducer from './alertReducer'
import authReducer from './authReducer'
import loadingReducer from './loadingReducer'
import notificationReducer from './notificationReducer'
import questionReducer from './questionReducer'
import studyReducer from './studyReducer'
import doctorReducer from './doctorReducer'
import examReducer from './examReducer'
import reportingReducer from './reportingReducer'
import messageReducer from './messageReducer'
import patientReducer from './patientReducer'
import telemedReducer from './telemedReducer'

const appReducer = combineReducers({
  auth: authReducer,
  study: studyReducer,
  alert: alertReducer,
  loading: loadingReducer,
  notification: notificationReducer,
  question: questionReducer,
  doctor: doctorReducer,
  exam: examReducer,
  reporting: reportingReducer,
  messages: messageReducer,
  patients: patientReducer,
  telemed: telemedReducer,
})
const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}
export default rootReducer
