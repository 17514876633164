import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useWebsocket } from '../../hooks/useWebsocket'

import {
  Avatar,
  ChatContainer,
  MainContainer,
  Message,
  MessageInput,
  MessageList,
} from '@chatscope/chat-ui-kit-react'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css'
import { Box, Button, DialogActions, Grid, Tooltip } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useAppDispatch } from '../../hooks/reduxHooks'
import {
  CreateMessageDto,
  EntityName,
  GedFileDto,
  MessageNamesEnum,
} from '../../services/api'
import StorageService from '../../services/storage'
import { addMessage } from '../../state/reducers/messageReducer'
import { useSelector } from '../../state/store'
import { findMessages } from '../../state/thunks/messageThunk'
import { uploadFiles } from '../../state/thunks/studyThunk'
import { Constants } from '../../utils/Constants'
import theme from '@utils/theme'
import { DialogTitle } from '@components/titles'
import { dataURLToBlob, getUrlFromImgHtmlString } from '@utils/utils'
import { toDataUrl } from '@utils/helpers'
import { v4 as uuid } from 'uuid'

interface Props {
  entityId: number
  entityName: EntityName
  roomId: string
}

// TODO change this stupid name
const ReportMessages: React.FC<Props> = ({ entityId, entityName, roomId }) => {
  const messages = useSelector((state) => state.messages.messages)
  const currentUser = useSelector((state) => state.auth.user)

  const dispatch = useAppDispatch()

  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [, setMsgInputValue] = useState('')
  const [fileToPreview, setFileToPreview] = useState<GedFileDto | null>(null)

  const onMessage = <MessageDto,>(message: MessageDto) => {
    dispatch(addMessage(message))
  }
  const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files) {
      setSelectedFiles(Array.from(files))
    }
  }

  const { sendMessage } = useWebsocket({
    handlers: [
      {
        messageName: MessageNamesEnum.ReceiveMessage,
        fn: onMessage,
      },
    ],
  })

  const handleSend = async (s: string, message: string) => {
    if (s.includes('<img')) {
      if (s.includes('base64')) {
        const base64 = getUrlFromImgHtmlString(s)
        const blob = dataURLToBlob(base64)

        const file = new File([blob], 'image.png', {
          type: 'image/png',
        })

        setSelectedFiles([file])
        return
      } else {
        const imgUrl = getUrlFromImgHtmlString(s)
        const b64 = await toDataUrl(imgUrl)
        const blob = dataURLToBlob(b64)

        const fileName = uuid().toString()
        const file = new File([blob], fileName, {
          type: 'image/png',
        })

        setSelectedFiles([file])
        return
      }
    }

    const dto: CreateMessageDto = {
      entityId,
      entityName,
      roomId,
      message: message.trim(),
    }

    setMsgInputValue('')
    sendMessage(MessageNamesEnum.ReceiveMessage, dto)
  }

  useEffect(() => {
    sendMessage(MessageNamesEnum.JoinRoom, {
      roomId,
    })

    return () => {
      sendMessage(MessageNamesEnum.LeaveRoom, {
        entityId: roomId,
      })
    }
  }, [])

  useEffect(() => {
    dispatch(
      findMessages({
        entityId,
        entityName,
      }),
    )
  }, [entityId, entityName])

  const handleAttachClick = () => {
    if (!fileInputRef.current) {
      return
    }

    fileInputRef.current.click()
  }

  const fileInputRef = React.useRef<HTMLInputElement>(null)

  const clearFiles = () => {
    setSelectedFiles([])
    if (fileInputRef.current) fileInputRef.current.value = ''
  }

  const sendFiles = () => {
    dispatch(
      uploadFiles({
        id: entityId,
        files: selectedFiles,
        type: 'PM_FILE',
      }),
    )
      .unwrap()
      .then((res) => {
        clearFiles()
        if (fileInputRef.current) fileInputRef.current.value = ''
        const message: CreateMessageDto = {
          entityId,
          entityName,
          message: '',
          gedFileIds: res.map((file) => file.id),
          roomId,
        }
        sendMessage(MessageNamesEnum.ReceiveMessage, message)
      })
  }

  const token = useRef(StorageService.getAuthToken()).current

  const renderFile = (file: GedFileDto) => {
    if (file.mimeType.includes('image')) {
      return (
        <Message.ImageContent
          key={file.id}
          src={`${process.env.REACT_APP_STATIC_FILES_URL}/static/${file.filePath}/${file.filename}?token=${token}`}
          width={100}
        />
      )
    } else if (file.mimeType.includes('video')) {
      return (
        <Grid item xs={6} md={4} lg={3}>
          <Message.CustomContent>
            <video
              src={`${process.env.REACT_APP_STATIC_FILES_URL}/static/${file.filePath}/${file.filename}?token=${token}`}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              controls
            />
          </Message.CustomContent>
        </Grid>
      )
    } else {
      return (
        <>
          <Tooltip title={file.filename} placement="top">
            <a
              href={`${process.env.REACT_APP_STATIC_FILES_URL}/static/${file.filePath}/${file.filename}?token=${token}`}
              target="_blank"
              rel="noreferrer"
              style={{
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                color: '#3f3f3f',
                maxWidth: '100%',
              }}
            >
              <InsertDriveFileIcon style={{ marginRight: '5px' }} />
              <span
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  fontSize: '0.8rem',
                }}
              >
                {file.filename}
              </span>
            </a>
          </Tooltip>
        </>
      )
    }
  }

  const messageList = useMemo(() => {
    if (!messages.datas) {
      return []
    }
    const _messages = [...messages.datas]

    return _messages.sort((a, b) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    })
  }, [messages.datas])

  return (
    <MainContainer
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#e8eeff',
        height: renderFile.length ? 'calc(100% - 103px)' : 'calc(100% - 60px)',
        borderRadius: '0px 0px 5px 5px',
      }}
    >
      <ChatContainer
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <MessageList>
          {messageList.map((message) => (
            <Message
              key={message.id}
              style={{
                width: '100%',
              }}
              model={{
                message: message.message,
                position: 'normal',
                direction:
                  currentUser?.accountId === message.sender.id
                    ? 'outgoing'
                    : 'incoming',
                type: 'text',
              }}
            >
              <Avatar src={Constants.avatarPlaceHolder} />
              {message.files.length && (
                <Message.CustomContent>
                  <Grid
                    container
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    {message.files.map((file) => (
                      <Grid
                        xs={12}
                        key={file.id}
                        item
                        onClick={() => {
                          if (
                            file.mimeType.includes('image') ||
                            file.mimeType.includes('video')
                          ) {
                            setFileToPreview(file)
                          }
                        }}
                      >
                        {renderFile(file)}
                      </Grid>
                    ))}
                  </Grid>
                </Message.CustomContent>
              )}
            </Message>
          ))}
        </MessageList>
        <MessageInput
          onAttachClick={handleAttachClick}
          placeholder="Type message here"
          onSend={handleSend}
        />
      </ChatContainer>
      <input
        ref={fileInputRef}
        id="hiddenFileInput"
        type="file"
        accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
        multiple
        style={{ display: 'none' }}
        onChange={handleFileSelection}
      />
      <Dialog
        open={selectedFiles.length !== 0}
        onClose={clearFiles}
        maxWidth="md"
        PaperProps={{
          style: {
            borderRadius: 20,
            width: '100%',
            background: '#E2E6F2',
          },
        }}
      >
        <DialogTitle
          id="file-preview-dialog"
          onClose={clearFiles}
          title="Aperçu des fichiers"
          style={{
            background: '#fff',
            color: theme.palette.primary.main,
          }}
        />

        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            {' '}
            {/* Added spacing for a slight gap */}
            {selectedFiles.map((file) => (
              <Grid item xs={12} sm={6} md={4} key={file.name}>
                {' '}
                {/* 3 items per row */}
                {file.type.includes('image') ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    style={{
                      width: '100%', // Use the full width of the grid column
                      height: 'auto', // Maintain the aspect ratio of the image
                      maxHeight: '200px', // Set a max height to keep previews small
                      objectFit: 'contain', // Ensure the image fits within the container without distortion
                      overflow: 'hidden',
                      borderRadius: theme.shape.borderRadius, // Optional: use theme's border radius
                    }}
                  />
                ) : (
                  <iframe
                    src={URL.createObjectURL(file)}
                    title={file.name}
                    style={{
                      width: '100%', // Use the full width of the grid column
                      height: '200px', // Set a fixed height for non-image previews
                      borderRadius: theme.shape.borderRadius, // Optional: use theme's border radius
                      border: 'none', // Remove the default border of the iframe
                    }}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
        <DialogActions>
          <Button
            variant="contained"
            style={{
              backgroundColor: theme.palette.secondary.main,
              padding: '5px 10px',
              borderRadius: '5px',
              color: 'white',
            }}
            onClick={clearFiles}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: theme.palette.primary.main,
              padding: '5px 10px',
              borderRadius: '5px',
              color: 'white',
            }}
            onClick={sendFiles}
          >
            Valider
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!fileToPreview}
        onClose={() => setFileToPreview(null)}
        aria-labelledby="file-preview-dialog"
        aria-describedby="file-preview-content"
      >
        <IconButton
          aria-label="close"
          onClick={() => setFileToPreview(null)}
          style={{
            position: 'absolute',
            right: '8px',
            top: '8px',
            color: theme.palette.secondary.main,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {fileToPreview && (
            <Box
              sx={{ minHeight: '50vh', overflow: 'hidden', marginTop: '35px' }}
            >
              {fileToPreview?.mimeType.includes('image') ? (
                <img
                  src={`${process.env.REACT_APP_STATIC_FILES_URL}/static/${fileToPreview.filePath}/${fileToPreview.filename}?token=${token}`}
                  alt={fileToPreview.filename}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    display: 'block',
                    margin: '0 auto',
                  }}
                />
              ) : (
                <video
                  src={`${process.env.REACT_APP_STATIC_FILES_URL}/static/${fileToPreview.filePath}/${fileToPreview.filename}?token=${token}`}
                  title={fileToPreview.filename}
                  controls
                  style={{
                    width: '100%',
                    height: 'auto',
                    display: 'block',
                    margin: '0 auto',
                  }}
                />
              )}
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </MainContainer>
  )
}

export default ReportMessages
