import { TooltipButton } from '@components/buttons'
import { Icon } from '@material-ui/core'
import { MedicalOrderDto } from '@services/api'

type StudyTooltipProps = {
  study: MedicalOrderDto
  onClick: () => void
  className?: string
}

export function StudyTooltip({ study, onClick, className }: StudyTooltipProps) {
  return (
    <TooltipButton
      title="study"
      disable={study.status == 'canceled'}
      onClick={onClick}
      icon={
        <span className={'icon-wrapper ' + className ?? ''}>
          <Icon
            fontSize="small"
            className="fas fa-info"
            color={study.status == 'canceled' ? 'disabled' : 'primary'}
          />
        </span>
      }
    />
  )
}
