import { axios, Member } from './api'
export class TelemdService {
  private static basePath = process.env.REACT_APP_API_URL

  static findUsers(): Promise<Member[]> {
    return new Promise((resolve, reject) => {
      const url = `${this.basePath}/member/in-current-sites`

      const configs = {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
        url,
      }

      axios(configs, resolve, reject)
    })
  }
}

export enum UserState {
  ViewingMo,
  ViewingDose,
  EditingDose,
  ViewingProtocol,
  EditingProtocol,
  ViewingReport,
  EditingReport,
  Idle,
  ViewingQuestionnaire,
  EditingMedicalOrder,
  ViewingStudyData,
  EditingStudyData,
  ViewingFiles,
}
