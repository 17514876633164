import React, { useEffect, useRef, useState } from 'react'
import { CreateAnswerDto, MedicalOrderDto } from '../../services/api'
import QuestionFrom from '../forms/QuestionForm'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import {
  createAnswers,
  findAnswers,
  getExamQuestions,
} from '../../state/thunks/examThunk'
import { Button, Divider, LinearProgress } from '@mui/material'
import { enqueueSnackbar } from '../../state/reducers/alertReducer'
interface Props {
  medicalOrder: MedicalOrderDto
  onClose: () => void
}
const ExamQuestionnaire: React.FC<Props> = ({ medicalOrder, onClose }) => {
  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading] = useState(false)

  const answersMap = useRef(new Map<number, CreateAnswerDto>()).current

  const questions = useAppSelector((state) => state.exam.questions)
  const answers = useAppSelector((state) => state.exam.answers)

  const handleAnswerChange = (answerDto: CreateAnswerDto) => {
    const key = answerDto.questionId
    answersMap.set(key, answerDto)
  }

  useEffect(() => {
    setIsLoading(true)
    dispatch(getExamQuestions(medicalOrder.examId))
      .unwrap()
      .finally(() => {
        dispatch(findAnswers(medicalOrder.id)).finally(() => {
          setIsLoading(false)
        })
      })
  }, [])

  useEffect(() => {
    answers?.forEach((answer) => {
      answersMap.set(answer.questionId, answer)
    })
  }, [answers])

  const onSave = () => {
    setIsLoading(true)
    dispatch(
      createAnswers({
        answers: Array.from(answersMap.values()),
        medicalOrderId: medicalOrder.id,
      }),
    )
      .unwrap()
      .finally(() => {
        setIsLoading(false)
        onClose()
        dispatch(
          enqueueSnackbar({
            message: 'Réponses enregistrées avec succès',
            type: 'success',
          }),
        )
      })
  }
  return (
    <div
      style={{
        padding: 18,
        backgroundColor: 'white',
        position: 'relative',
      }}
    >
      <Divider orientation="vertical" style={{ position: 'absolute' }} />
      {isLoading && <LinearProgress color="secondary" />}
      {!isLoading &&
        questions?.map((_question) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'start',
              transform: 'translateX(-4px)',
            }}
            key={_question.id}
          >
            <div
              style={{
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                background:
                  'radial-gradient(circle, #8e44ad 20%, rgba(155, 89, 182,0.4) 100%)',
                boxShadow: '0px 0px 5px 0px #8e44ad',
                marginRight: '10px',
                marginTop: '17px',
              }}
            />
            <QuestionFrom
              question={_question}
              onAnswerChange={handleAnswerChange}
              defaultAnswer={answers.find(
                (ans) => ans.questionId === _question.id,
              )}
            />
          </div>
        ))}
      <div
        style={{
          backgroundColor: 'white',
          position: 'fixed',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          paddingRight: 18,
          paddingBottom: 8,
          bottom: 0,
          left: 0,
          right: 0,
          borderTop: '1px solid #E2E6F2',
          paddingTop: 8,
        }}
      >
        <Divider variant="fullWidth" />
        <Button variant="contained" onClick={onSave}>
          Sauvegarder
        </Button>
        <Button
          style={{ backgroundColor: 'black', marginLeft: '8px' }}
          variant="contained"
          onClick={onClose}
        >
          Fermer
        </Button>
      </div>
    </div>
  )
}

export default ExamQuestionnaire
