import {
  AnswerDto,
  axios,
  CreateAnswerDto,
  Exam,
  ExamAquisitionProtocolDto,
  getConfigs,
  IRequestConfig,
  QuestionDto,
} from './api'

export class ExamService {
  private static basePath = process.env.REACT_APP_API_URL

  static getExamAquisitionProtocol(
    examTypeId: number,
  ): Promise<ExamAquisitionProtocolDto[]> {
    return new Promise((resolve, reject) => {
      const url = this.basePath + `/exam/${examTypeId}/protocol`

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        {},
      )
      const data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }

  static getExamsBySiteId(
    siteIds: number[],
  ): Promise<{ totalCount: number; datas: Exam[] }> {
    return new Promise((resolve, reject) => {
      let url = this.basePath + '/exam?'
      siteIds.forEach((id) => {
        url += `&siteIds[]=${id}`
      })
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        {},
      )

      axios(configs, resolve, reject)
    })
  }

  static getExamQuestions(examId: number): Promise<QuestionDto[]> {
    return new Promise((resolve, reject) => {
      const url = this.basePath + `/questions?examId=${examId}`

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        {},
      )
      const data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }

  static createAnswers(
    medicalOrderId: number,
    dto: CreateAnswerDto[],
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      const url = this.basePath + `/questions/answers/${medicalOrderId}`

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        {},
      )

      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static findAnswers(medicalOrderId: number): Promise<AnswerDto[]> {
    return new Promise((resolve, reject) => {
      const url = `${this.basePath}/questions/answers/${medicalOrderId}`

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        {},
      )

      axios(configs, resolve, reject)
    })
  }
}
