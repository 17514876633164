import { Button, Dialog, DialogContent, Grid, Typography } from '@mui/material'
import { useEffect } from 'react'
import { MedicalOrderDto } from '../../services/api'
import { useDispatch } from 'react-redux'
import { cleanStudy } from '../../state/reducers/studyReducer'
import { useSelector } from '../../state/store'
import { DialogTitle } from '../titles'
import theme from '@utils/theme'
import ReportingLayout from '../reporting/ReportingLayout'
import hotkeys from 'hotkeys-js'
import { setReports, setSelectedReport } from '@state/reducers/reportingReducer'

type ManageReportDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
  study: MedicalOrderDto
  onClose: () => void
}

export const ManageReportDialog = ({
  open,
  onClose,
}: ManageReportDialogProps) => {
  const dispatch = useDispatch()

  const { study } = useSelector(({ study }) => ({
    study: study.selected as MedicalOrderDto,
  }))

  useEffect(() => {
    if (!open) {
      return
    }
    return () => {
      dispatch(cleanStudy())
      dispatch(setSelectedReport(null))
      dispatch(setReports([]))
    }
  }, [open])

  useEffect(() => {
    hotkeys('ctrl+l', (e) => {
      e.preventDefault()
      e.stopPropagation()
      onClose()
    })

    return () => {
      hotkeys.unbind('esc')
    }
  }, [onClose])

  return (
    <Dialog
      fullScreen
      aria-labelledby="simple-dialog-title"
      open={open}
      id="report-dialog-content"
      PaperProps={{
        style: {
          width: '100%',
        },
      }}
    >
      <DialogContent
        id="editor-container"
        style={{
          backgroundColor: '#EBECF0',
        }}
      >
        <Grid container>
          <ReportingLayout study={study} />
        </Grid>
        <Button
          onClick={onClose}
          variant="text"
          style={{
            position: 'absolute',
            top: '20px',
            right: '17px',
            color: 'red',
          }}
        >
          <i className="fas fa-times" style={{ fontSize: '28px' }}></i>
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default ManageReportDialog
