import { Button } from '@components/buttons'

import {
  Checkbox,
  Grid,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from '../../state/store'
import { getAcquisitionProtocols, update } from '../../state/thunks/studyThunk'
import { AccessEnum, FileTypeEnum, MedicalOrderDto } from '../../services/api'
import { useAppDispatch } from '../../hooks/reduxHooks'
import { enqueueSnackbar } from '../../state/reducers/alertReducer'
import DocumentList from './DocumentList'
import { getPatientDocument } from '../../state/thunks/patientThunk'
import { usePermission } from '../../hooks/usePermission'

interface ExamAquisitionProtocolManagerProps {
  medicalOrder: MedicalOrderDto
}
const ExamAquisitionProtocolManager: React.FC<ExamAquisitionProtocolManagerProps> =
  ({ medicalOrder }) => {
    const dispatch = useAppDispatch()
    const [selectedProtocolIds, setSelectedProtocolIds] = useState<number[]>(
      medicalOrder.acquisitionProtocols?.map((p) => p.id) ?? [],
    )
    const [commentaires, setCommentaires] = useState(
      medicalOrder.commentairesAquisitionProtocol ?? '',
    )
    const [filter, setFilter] = useState('')

    const aquisitionProtocols = useSelector(
      (state) => state.study.aquisitionProtocols,
    )

    const documents = useSelector(({ patients }) => patients.patientDocuments)
    const { hasPermission } = usePermission()

    const canEdit = hasPermission(AccessEnum.EDIT_AQUISITION_PROTOCOL)

    useEffect(() => {
      dispatch(
        getAcquisitionProtocols({
          siteId: medicalOrder.siteId,
        }),
      )
      dispatch(
        getPatientDocument({
          id: medicalOrder.patientId,
        }),
      )
    }, [])

    const onCheckChange = (id: number) => {
      if (selectedProtocolIds.indexOf(id) === -1) {
        setSelectedProtocolIds([...selectedProtocolIds, id])
      } else {
        setSelectedProtocolIds(selectedProtocolIds.filter((p) => p !== id))
      }
    }

    const save = () => {
      dispatch(
        update({
          id: medicalOrder.id,
          body: {
            acquisitionProtocolIds: selectedProtocolIds,
            commentairesAquisitionProtocol: commentaires,
          },
        }),
      )
        .unwrap()
        .then(() => {
          dispatch(
            enqueueSnackbar({
              message: 'Sauvegarde réussie',
              type: 'success',
            }),
          )
        })
    }

    const filteredDocuments = useMemo(() => {
      return documents.filter(
        (d) =>
          d.fileType === FileTypeEnum.PRESCRIPTION ||
          d.fileType === FileTypeEnum.REPORT,
      )
    }, [documents])

    const filteredProtocolsByModality = useMemo(() => {
      if (!medicalOrder.exam || !medicalOrder.exam.modality) {
        return []
      }
      return aquisitionProtocols.filter(
        (p) => p.modality === medicalOrder.exam.modality,
      )
    }, [aquisitionProtocols, medicalOrder.exam])

    const filteredProtocols = useMemo(() => {
      return filteredProtocolsByModality.filter((ap) =>
        ap.title.toLowerCase().includes(filter.toLowerCase()),
      )
    }, [filteredProtocolsByModality, filter])

    return (
      <Grid container spacing={2} flexWrap="nowrap" paddingTop={4}>
        <Grid item xs={8}>
          <Grid item xs={12}>
            <TextField
              label="Protocole(s) d'acquisition"
              placeholder="Rechercher un protocole d'acquisition"
              onChange={(ev) => {
                setFilter(ev.target.value)
              }}
              value={filter}
              fullWidth
              variant="outlined"
              disabled={!canEdit}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <i className="fas fa-search" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <List
              style={{
                width: '100%',
                maxHeight: '250px',
                overflowY: 'auto',
                boxSizing: 'border-box',
                backgroundColor: 'white',
                borderRadius: '5px',
                border: '1px solid rgba(0, 0, 0, 0.23)',
              }}
            >
              {filteredProtocols.map((value, index) => {
                const labelId = `checkbox-list-label-${value}`
                const isEvenRow = (index + 1) % 2 === 0
                return (
                  <ListItem
                    key={value.id}
                    disablePadding
                    style={{
                      backgroundColor: isEvenRow ? '#d9e1f750' : 'white',
                      padding: 0,
                    }}
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={() => onCheckChange(value.id)}
                      dense
                      disabled={!canEdit}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={selectedProtocolIds.indexOf(value.id) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                          disabled={!canEdit}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={value.description} />
                    </ListItemButton>
                  </ListItem>
                )
              })}
            </List>
          </Grid>
          <Grid item xs={12}>
            <InputLabel variant="standard" htmlFor="commentaire">
              Commentaires
            </InputLabel>
            <TextField
              id="commentaire"
              value={commentaires}
              onChange={(ev) => {
                setCommentaires(ev.target.value)
              }}
              fullWidth
              multiline
              disabled={!canEdit}
              rows={4}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              disabled={!canEdit}
              fullWidth
              text="Sauvegarder"
              onClick={save}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={4}
          style={{
            overflow: 'hidden',
          }}
        >
          {medicalOrder.indication && (
            <>
              <h3>Indication</h3>
              <Typography>{medicalOrder.indication}</Typography>
            </>
          )}
          <DocumentList documents={filteredDocuments} />
        </Grid>
      </Grid>
    )
  }

export default ExamAquisitionProtocolManager
