import React from 'react'
interface ReportComponentProps {
  logo: string
  siteName: string
  siteAddress: string
  sitePhone: string
  siteEmail: string
  siteWebsite: string
  reportDate: string
  referringDoctorName: string
  telemedDoctorName: string
  className?: string
}
const ReportHeaderComponent: React.FC<ReportComponentProps> = ({
  logo,
  siteName,
  siteAddress,
  sitePhone,
  siteEmail,
  siteWebsite,
  reportDate,
  referringDoctorName,
  telemedDoctorName,
  className,
}) => {
  return (
    <div
      className={className ? className : 'report-header'}
      style={{
        width: '21cm',
        borderBottom: '2px solid #4c8caf',
        color: '#333',
        borderTop: '1px solid #CCC',
        borderLeft: '1px solid #CCC',
        borderRight: '1px solid #CCC',
      }}
    >
      <div
        className="header"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '24px',
          backgroundColor: 'white',
        }}
      >
        <img
          src={logo}
          alt="site Logo"
          className="logo"
          style={{ maxWidth: '100px', height: 'auto' }}
        />
        <div
          className="report-info"
          style={{ textAlign: 'right', fontSize: '14px' }}
        >
          <h1 style={{ margin: '0', fontSize: '18px', color: '#4c8caf' }}>
            {siteName}
          </h1>
          <p style={{ margin: '0', lineHeight: '1.2' }}>{siteAddress}</p>
          <p style={{ margin: '0', lineHeight: '1.2' }}>{sitePhone}</p>
          <p style={{ margin: '0', lineHeight: '1.2' }}>{siteEmail}</p>
          <p style={{ margin: '0', lineHeight: '1.2' }}>{siteWebsite}</p>
        </div>
      </div>
      <div
        className="report-contacts"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '5px',
          backgroundColor: '#ffffff',
          fontSize: '14px',
        }}
      >
        <div className="date-location" style={{ padding: '5px' }}>
          <p style={{ margin: '0', lineHeight: '1.2' }}>{reportDate}</p>
          <p style={{ margin: '0', lineHeight: '1.2' }}>A lattention de:</p>
          <p style={{ margin: '0', lineHeight: '1.2' }}>
            {referringDoctorName || 'N/A'}
          </p>
        </div>
        <div className="author-info" style={{ padding: '5px' }}>
          <p style={{ margin: '0', lineHeight: '1.2' }}>
            Dr. {telemedDoctorName || 'N/A'}
          </p>
        </div>
      </div>
    </div>
  )
}

export default ReportHeaderComponent
