import { DialogTitle } from '@components/titles'
import { Dialog, DialogContent, Grid } from '@material-ui/core'
import { Document, EnumGedFileType, MedicalOrderDto } from '@services/api'
import { useSelector } from '@state/store'
import { useEffect, useMemo } from 'react'
import { Text } from '../texts'

import { FileInput } from '@components/inputs'
import { cleanStudy } from '@state/reducers/studyReducer'
import { deletePrescription, uploadFile } from '@state/thunks/studyThunk'
import theme from '@utils/theme'
import { useIntl } from 'react-intl'
import StorageService from '../../services/storage'
import { useAppDispatch } from '../../hooks/reduxHooks'

type FileUploadDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
  study: MedicalOrderDto
  onClose?: () => void
}

export function FileUploadDialog({
  open,
  setOpen,
  onClose: _onClose,
}: FileUploadDialogProps) {
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const { study } = useSelector(({ study }) => ({
    study: study.selected as MedicalOrderDto,
  }))

  const documents = useMemo(() => study.documents, [study.documents])

  const handleDownload = (file: Document) => {
    const link = document.createElement('a')
    const token = StorageService.getAuthToken()

    link.href = `${process.env.REACT_APP_STATIC_FILES_URL}${file.url}?token=${token}`
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleUpload = (files: File[], type: EnumGedFileType) => {
    dispatch(uploadFile({ id: study.id, files, type }))
  }

  const handleDelete = (file: Document) => {
    dispatch(
      deletePrescription({
        id: file.medicalOrderId,
        prescriptionId: file.id,
      }),
    )
  }

  const onClose = () => {
    setOpen(false)

    if (_onClose) {
      _onClose()
    }
  }

  useEffect(() => {
    if (!open) {
      return
    }
    return () => {
      dispatch(cleanStudy())
    }
  }, [study.reports, documents.length, open])

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      PaperProps={{
        style: {
          borderRadius: 20,
          width: '100%',
          background: '#E2E6F2',
        },
      }}
    >
      <DialogTitle
        onClose={onClose}
        title={`${study.patient.firstName[0]}.${study.patient.lastName} - ${study.exam.label}`}
        style={{
          background: '#fff',
          color: theme.palette.primary.main,
        }}
      />
      <DialogContent style={{ padding: 30 }}>
        <Grid
          container
          spacing={2}
          style={{ background: '#fff', borderRadius: '20px', padding: 10 }}
        >
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Text variant="h5" text="document" format />
            </Grid>
            <Grid item xs={12} style={{ marginLeft: 25 }}>
              <FileInput
                study={study}
                accept="application/doc, application/pdf, application/rtf, application/tiff, image/*, application/jpeg, application/jpg, application/png, text/plain"
                files={documents}
                onChange={handleUpload}
                onDownloadFile={handleDownload}
                onDeleteFile={(file) => {
                  const confirmBox = window.confirm(
                    intl.formatMessage({ id: 'delete-file' }),
                  )
                  if (confirmBox === true) {
                    handleDelete(file)
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
