import { Box } from '@mui/material'
import { ReportTemplateDto } from '@services/api'
import React from 'react'
import ReportTemplatePreview from './ReportTemplatePreview'
interface Props {
  templates: ReportTemplateDto[]
  onUseTemplate: (template: ReportTemplateDto) => void
}

const TemplateListComponent: React.FC<Props> = ({
  onUseTemplate,
  templates,
}) => {
  return (
    <>
      {templates.map((template) => (
        <Box key={template.id}>
          <ReportTemplatePreview
            template={template}
            onUseTemplateClick={onUseTemplate}
          />
        </Box>
      ))}
    </>
  )
}

export default TemplateListComponent
